import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Avatar from '../../components/Avatar';
import Loading from '../../components/Loading';
import {fetchDistributors} from '../../slices/distributors.slice';
import PageHeader from '../../components/PageHeader';

const Distributors = () => {
    const dispatch = useDispatch();
    const { list: distributors, status, error } = useSelector(state => state.distributors);
    const [searchValue, setSearchInput] = useState('');
    const [filteredValues, setFilteredValues] = useState([]);

    useEffect(() => {
        dispatch(fetchDistributors());
    }, [dispatch]);

    useEffect(() => {
        setFilteredValues(distributors);
    }, [distributors]);

    const handleSearch = event => {
        event.preventDefault();
        setSearchInput(event.target.value);
        setFilteredValues(distributors.filter(({ name }) => name.toLowerCase().includes(event.target.value.toLowerCase())));
    };

    const showValues = filteredValues.length ? filteredValues : distributors;
    const isLoading = ['idle', 'loading'].includes(status);

    return (
        <div className="distributors-view page px-4 py-3 d-flex flex-column vh-100 overflow-y-auto">
            <PageHeader
                title='distributors'
                addButtonAction={{navigateTo: '/distributors/new'}}
                searchBar={{searchValue, handleSearch}}
                />
            <div className='contentContainer' >

                {isLoading && <Loading />}

                {error && (
                    <div className="text-center text-uppercase text-expand mt-5">
                        <span className="fas fa-warning fa-3x opacity-50 text-danger my-3 pulse-effect" /><br/>
                        Failed to fetch distributors <br/>
                        <code>{error}</code>
                    </div>
                )}

                {distributors.length > 0 && showValues.map((distributor) => (
                    <NavLink to={`/distributors/${distributor._id}`} key={distributor._id}
                             className="text-decoration-none text-white d-flex align-items-center gap-3 bg-broken p-3 border-start border-primary mb-3">
                        <Avatar imageUrl={distributor.logo?.imageUrl} name={distributor.name} size={50} />
                        <h4 className="my-0 text-primary">{distributor.name}</h4>
                    </NavLink>
                ))}

                {!error && !isLoading && distributors.length === 0 && (
                    <div className="text-center text-uppercase text-expand mt-5">NO DISTRIBUTORS YET</div>
                )}
            </div>
        </div>
    );
};

export default Distributors;
