import React, { useState } from 'react';
import { Button, Table, Input } from 'reactstrap';
import './DefaultsTable.scss';
import TierSwitch from '../../components/TierSwitch';
import useSavePrompt from '../../utils/useSavePrompt';
import SavePrompt from '../../components/SavePrompt'


const emptyDefaults = [
    { tier: ['mid', 'premium'], daysBeforeRelease: '', daysAfterRelease: '', credits: '' },
];

const DefaultsTable = ({ country, onSave, defaults = emptyDefaults }) => {
    const [rows, setRows] = useState(defaults);
    const [isModified, setIsModified] = useState(false);
    const [originalData] = useState(defaults)

    const blocker = useSavePrompt(originalData, rows)


    const handleAddRow = () => {
        setRows([ ...rows, ...emptyDefaults ]);
    }

    const handleRemoveRow = (index) => setRows(rows.filter((_, i) => i !== index));

    const handleChange = (index, field, value) => {

        if (!isModified) setIsModified(true);

        if (value.length)value = value.sort()

        const newRows = rows.map((row, rowIndex) => rowIndex === index ? { ...row, [field]: value } : row);
        setRows(newRows);
    };

    const handleSave = e => {
        e.preventDefault();
        onSave(rows);
        setIsModified(false);
    };

    return (
        <div className="defaults-table-component">
            <Table hover dark>
                <thead>
                <tr>
                    <th className="bg-transparent text-center" style={{ width: '298px' }}>Tier</th>
                    <th className="bg-transparent text-center">From Days</th>
                    <th className="bg-transparent text-center">To Days</th>
                    <th className="bg-transparent text-center">Credits</th>
                    <th className="bg-transparent"></th>
                </tr>
                </thead>
                <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td className="bg-transparent">
                            <TierSwitch
                                value={row.tier}
                                onChange={(e) => handleChange(index, 'tier', e.target.value)}
                                multiple
                            />
                        </td>
                        <td className="bg-transparent">
                            <Input type="number" value={row.daysBeforeRelease} onChange={(e) => handleChange(index, 'daysBeforeRelease', Number(e.target.value))} />
                        </td>
                        <td className="bg-transparent">
                            <Input type="number" value={row.daysAfterRelease} onChange={(e) => handleChange(index, 'daysAfterRelease', Number(e.target.value))} />
                        </td>
                        <td className="bg-transparent">
                            <Input type="number" value={row.credits} onChange={(e) => handleChange(index, 'credits', Number(e.target.value))} />
                        </td>
                        <td className="bg-transparent">
                            <Button color="link" onClick={() => handleRemoveRow(index)}>
                                <i className="fas fa-trash"></i>
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Button color="link" onClick={handleAddRow} className="text-decoration-none">
                <span className="fas fa-plus me-2" />
                Add Row
            </Button>

            <Button color="link" className="text-decoration-none d-none">
                <span className="fas fa-copy me-2"/>
                Apply default settings to every country
            </Button>

            {isModified && (
                <Button color="primary" outline className="float-end" onClick={handleSave}>
                    <span className="fas fa-plus me-2"/>
                    Save changes{country && ` for ${country}`}
                </Button>
            )}
            <SavePrompt blocker={blocker}/>
        </div>
    );
};

export default DefaultsTable;
