import React from 'react';
import './OrderStatusBadge.scss';

export const statusMap = {
    "coming-soon": { label: "Coming Soon", icon: 'fa-hourglass-half' },
    "dcp-on-request": { label: "DCP on Request", icon: 'fa-file-video' },
    "key-on-request": { label: "Key on Request", icon: 'fa-person-chalkboard' },
    "movie-on-request": { label: "Movie on Request", icon: 'fa-film' },
    "request-accepted": { label: "Request Accepted", icon: 'fa-check-circle' },
    "ready-to-play": { label: "Ready to Play", icon: 'fa-play-circle' },
    "request-pending": { label: "Request Pending", icon: 'fa-clock' },
    "request-declined": { label: "Request Declined", icon: 'fa-times-circle' },
    "ready-to-order": { label: "Ready to Order", icon: 'fa-shopping-cart' },
    "content-available": { label: "Content Available", icon: 'fa-file-circle-check' },
    "ready-on-mediablock": { label: "Ready on Mediablock", icon: 'fa-cube' },
    "movie-delivery-pending": { label: "Movie Delivery Pending", icon: 'fa-truck' },
    "key-delivery-pending": { label: "Key Delivery Pending", icon: 'fa-key' },
    "preparing-playback": { label: "Preparing Playback", icon: 'fa-cog' },
    "ready-to-playback": { label: "Ready to Playback", icon: 'fa-play-circle' },
    "playing": { label: "Playing", icon: 'fa-play-circle' },
    "finished-playback": { label: "Finished Playback", icon: 'fa-stop-circle' },
    "transferring-to-mediablock": { label: "Transferring to Mediablock", icon: 'fa-download' },
};

const OrderStatusBadge = ({ status, isCard, className = '', scroll = false }) => {
    let color;

    switch (status) {
        case 'ready-to-play':
        case 'ready-to-playback':
        case 'preparing-playback':
            color = 'success';
            break;

        case 'request-declined':
            color =  'danger'
            break;

        case'request-pending':
        case'movie-delivery-pending':
        case'key-delivery-pending':
            color = 'warning'
            break;
        default:
    }

    return (
        <div className={`status ${color} ${isCard ? '':' h-100 w-100 d-flex justify-content-center align-items-center'} ${className}`}>
            <div className="px-2 w-100 text-truncate d-flex align-items-center">
                {status ? (
                    <span className={`fas ${statusMap[status].icon} me-2`} />
                ) : null}
                <div className={status && statusMap[status] && statusMap[status].label?.length > 17 && scroll ? 'marquee' : ''}>
                    <span>{status ? statusMap[status].label : 'Undefined'}</span>
                </div>
            </div>
        </div>
    )
};

export default OrderStatusBadge;
