
export const fileUploadChecks = async (file) => {
    const passSize = await sizeCheck(file)
    const passMime = await mimeCheck(file)

    return passSize && passMime
}

const sizeCheck = (file)=>{
    const maxSize = 20971520 // 20MB
    return (file.size < maxSize) ? true : false
}

const mimeCheck = async (file) => {
    if (file.type.split('/')[0] !== 'image'){
        return false
    }

    const mimes = [
        {
            mime: 'image/webp',
            pattern: [0x52 ,0x49 ,0x46 ,0x46 ,0x00 ,0x00 ,0x00 ,0x00 ,0x57 ,0x45 ,0x42 ,0x50 ,0x56 ,0x50 ],
            mask: [0xFF, 0xFF, 0xFF, 0xFF, 0x00, 0x00, 0x00, 0x00, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF ],
        },
        {
            mime: ' image/gif ',
            pattern: [0x47, 0x49, 0x46, 0x38, 0x39, 0x61 ],
            mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
            mime: ' image/gif ',
            pattern: [0x47, 0x49, 0x46, 0x38, 0x37, 0x61 ],
            mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
            mime: ' image/bmp ',
            pattern: [0x42, 0x4D ],
            mask: [0xFF, 0xFF],
        },
        {
            mime: ' image/x-icon ',
            pattern: [0x00, 0x00, 0x02, 0x00 ],
            mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
            mime: ' image/x-icon ',
            pattern: [0x00, 0x00, 0x01, 0x00] ,
            mask: [0xFF, 0xFF, 0xFF, 0xFF ],
        },
        {
            mime: 'image/png',
            pattern: [0x89, 0x50, 0x4E, 0x47],
            mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/jpeg',
            pattern: [0xFF, 0xD8, 0xFF],
            mask: [0xFF, 0xFF, 0xFF],
        },{
            mime: 'image/tiff',
            pattern: [0x49, 0x49, 0x2A, 0x00],
            mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/tiff',
            pattern: [0x4D, 0x4D, 0x00, 0x2A],
            mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/jpeg2000',
            pattern: [0x00, 0x00, 0x00, 0x0C, 0x6A, 0x50, 0x20, 0x20, 0x0D, 0x0A],
            mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/vnd.adobe.photoshop',
            pattern: [0x38, 0x42, 0x50, 0x53],
            mask: [0xFF, 0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/heif',
            pattern: [0x66, 0x74, 0x79, 0x70, 0x68, 0x65, 0x69, 0x63],
            mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
        },

    ];


    const bytesAmount = mimes.find(mime=>mime.mime === file.type)?.pattern.length  || 4
    const blob = file.slice(0, bytesAmount);

    const check = (bytes, mime) => {
        return mime.mask.every((maskValue, index) => {
            return (bytes[index] & maskValue) - mime.pattern[index] === 0;
        });
    };

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = (e) => {
            if (e.target.readyState === FileReader.DONE) {
                const bytes = new Uint8Array(e.target.result);
                const mime = mimes.find(mime => check(bytes, mime));

                mime ? resolve(true): resolve(false)

            } else {
                reject(new Error("FileReader failed to read the file."));
            }
        };

        reader.onerror = () => {
            reject(new Error("Error reading file."));
        };

        reader.readAsArrayBuffer(blob);
    });
}
