import React from 'react';
import { Badge } from 'reactstrap';

const BadgeSelect = ({ defaultList, values, onChange, multiple = true, append = '' }) => {
    return (
        <div className="d-flex flex-wrap">
            {defaultList.map(value => (
                <Badge
                    key={value}
                    color={multiple ? (values.includes(value) ? 'primary' : 'transparent') : (values === value ? 'primary' : 'transparent')}
                    onClick={() => onChange(value)}
                    style={{ cursor: 'pointer' }}
                    className={`m-1 text-nowrap overflow-hidden text-uppercase border border-primary p-2 px-4
                                position-relative
                                ${multiple ? (values.includes(value) ? 'text-black' : '') : (values === value ? 'text-black' : '')}`}
                >
                    {value}{append}
                    {(multiple ? values.includes(value) : values === value) && (
                        <span className="fas fa-check-circle text-black position-absolute end-0 me-1"/>
                    )}
                </Badge>
            ))}
        </div>
    );
};

export default BadgeSelect;
