import React, {useState} from 'react';
import {Row, Col, Button, Form, FormGroup, Label, Input} from 'reactstrap';
import {useNavigate, NavLink} from 'react-router-dom';
import {toast} from 'react-toastify';
import {apiRequest} from '../../utils/api-request';
import BadgeSelect from '../../components/BadgeSelect';
import genresList from './genres.json';
import './CreateMovie.scss';


const CreateMovie = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        title: '',
        synopsis: '',
        status: 'draft',
        countries: [],
        genres: [],
        distributor: null,
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const promise = apiRequest('/movies', 'POST', formData);
            toast.promise(promise, {
                pending: 'Creating movie',
                success: 'Movie created successfully',
                error: 'Error creating movie',
            })
            const movie = await promise;
            navigate(`/movies/${movie._id}`);
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    const toggleArray = prop => value => {
        setFormData(prev => ({
            ...prev,
            [prop]: prev[prop].includes(value)
                ? prev[prop].filter(g => g !== value)
                : [...prev[prop], value]
        }));
    };

    return (
        <div className="create-movie-view p-3 min-vh-100">
            <Row>
                <Col>
                    <NavLink to={`/movies`} className="text-decoration-none">
                        <span className="fas fa-chevron-left me-2" /> Movies
                    </NavLink>
                </Col>
            </Row>
            <h1>Create Movie</h1>
            <Form onSubmit={handleSubmit} className="pe-3">
                <div className="p-3 bg-broken border-start border-primary mt-3">
                    <FormGroup>
                        <Label for="title">Title</Label>
                        <Input
                            type="text"
                            name="title"
                            id="title"
                            placeholder="Movie Title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="synopsis">Synopsis</Label>
                        <Input
                            type="textarea"
                            name="synopsis"
                            id="synopsis"
                            className="pt-3"
                            placeholder="Movie Synopsis"
                            value={formData.synopsis}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                </div>

                <div className="p-3 bg-broken border-start border-primary mt-3">
                    <FormGroup>
                        <Label for="genres">Genres</Label>
                        <BadgeSelect
                            defaultList={genresList}
                            onChange={toggleArray('genres')}
                            values={formData.genres}
                        />

                    </FormGroup>
                </div>

                <Button color="primary" outline type="submit" className="float-end mt-3">
                    <span className="fas fa-save me-2"/>
                    Create movie
                </Button>

                <div className="clearfix mb-5"/>
            </Form>
        </div>
    );
};

export default CreateMovie;
