import React, {Fragment, useEffect, useState} from 'react';
import {Button} from 'reactstrap';
import {useSelector} from 'react-redux';
import './PinCode.scss';

const steps = {
    1: 'text-danger',
    2: 'text-danger',
    3: 'text-danger',
    4: 'text-success',
}

const PinKeyboard = ({ onSubmit, onError }) => {
    const [pin, setPin] = useState('');
    const pinError = useSelector((state) => state.auth.pinError);

    const handleInput = (value) => {
        if (value === 'C') {
            setPin('');
        } else if (value === 'OK') {
            onSubmit(pin);
        } else if (pin.length < 4) {
            setPin((prev) => {
                const newPin = prev + value;
                if (newPin.length === 4) {
                    onSubmit(newPin);
                }
                return newPin;
            });
        }
    };

    useEffect(() => {
        if (pinError) {
            setPin('');
        }
    }, [pinError]);

    return (
        <Fragment>
            <div className={`pin-inputs ${pinError ? 'shake' : ''}`}>
                {Array.from({length: 4}).map((_, index) => (
                    <div key={index} className={`pin-square ${pinError ? 'border-danger' : 'border-primary'}`}>
                        {pin[index] && <span className={`fas fa-circle ${steps[pin.length]}`} />}
                    </div>
                ))}
            </div>
            <div className="numeric-keyboard">
                {Array.from({length: 9}).map((_, index) => (
                    <Button
                        key={index}
                        className="key"
                        onClick={() => handleInput((index + 1))}
                        disabled={pin.length === 4}
                    >
                        {index + 1}
                    </Button>
                ))}
                <Button className="key opacity-50" onClick={() => handleInput('C')}>C</Button>
                <Button className="key" onClick={() => handleInput('0')}>0</Button>
                {/*<Button className="key" onClick={() => handleInput('OK')} disabled={pin.length < 4}>*/}
                {/*    <span className="fas fa-chevron-right" />*/}
                {/*</Button>*/}
            </div>
        </Fragment>
    );
};

export default PinKeyboard;
