import React from 'react';
import {Button} from 'reactstrap';

const MovieControlButtons = ({ status, handleJump, playStatus, handlePause, handlePlay, handleStop, playingCursor }) => {
    return (
        <div className="d-flex justify-content-start gap-3 align-items-center py-2">
            <Button color="primary" outline onClick={() => handleJump('backward', 30)}
                    title="Backward 30s" disabled={status === 'loading' || playingCursor < 30}>
                <span className="fas fa-backward me-2"/>
                30s
            </Button>

            {playStatus === 'Play' ? (
                <Button color="primary" outline onClick={handlePause} disabled={status === 'loading'}>
                    <span className={`fas fa-${status === 'loading' ? 'spinner fa-spin' : 'pause'}`} />
                </Button>
            ) : (
                <Button color="primary" outline onClick={handlePlay} disabled={status === 'loading'}>
                    <span className={`fas fa-${status === 'loading' ? 'spinner fa-spin' : 'play'}`} />
                </Button>
            )}

            <Button color="primary" outline onClick={() => handleJump('forward', 30)}
                    title="Forward 30s" disabled={status === 'loading'}>
                30s
                <span className="fas fa-forward ms-2"/>
            </Button>

            <Button color="primary" className="ms-3" outline onClick={handleStop} disabled={status === 'loading'}>
                <span className="fas fa-stop"/>
            </Button>
        </div>
    );
};

export default MovieControlButtons;
