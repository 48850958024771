import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const preparePlayback = createAsyncThunk('playMovie/prepare', async ({ movieId, orderId }) => {
    const response = await api.post(`/movies/${movieId}/order/${orderId}/action/prepare`);
    return response.data;
});

export const loadMovie = createAsyncThunk('playMovie/load', async ({ movieId, orderId }) => {
    const response = await api.post(`/movies/${movieId}/order/${orderId}/action/load`);
    return response.data;
});

export const playMovie = createAsyncThunk('playMovie/play', async ({ movieId, orderId }) => {
    const response = await api.post(`/movies/${movieId}/order/${orderId}/action/play`);
    return response.data;
});

export const pauseMovie = createAsyncThunk('playMovie/pause', async ({ movieId, orderId }) => {
    const response = await api.post(`/movies/${movieId}/order/${orderId}/action/pause`);
    return response.data;
});

export const goToTimecode = createAsyncThunk('playMovie/goToTimecode', async ({ movieId, orderId, timecode }) => {
    const response = await api.post(`/movies/${movieId}/order/${orderId}/go-to-timecode`, { timecode });
    return response.data;
});

export const ejectMovie = createAsyncThunk('playMovie/eject', async ({ movieId, orderId }) => {
    const response = await api.post(`/movies/${movieId}/order/${orderId}/action/eject`);
    return response.data;
});

export const getPlayStatus = createAsyncThunk('playMovie/getPlayStatus', async ({ movieId, orderId }) => {
    const response = await api.get(`/movies/${movieId}/order/${orderId}/play-status`);
    return response.data;
});

export const getPlayingMovie = createAsyncThunk('playMovie/getPlaying', async () => {
    const response = await api.get(`/orders/get-playing-movie`);
    return response.data;
});

const playMovieSlice = createSlice({
    name: 'playMovie',
    initialState: {
        status: 'idle',
        error: null,
        showStatus: null,
        playStatus: null,
        playing: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadMovie.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadMovie.fulfilled, (state) => {
                state.status = 'success';
                state.playStatus = 'Play';
            })
            .addCase(loadMovie.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })

            .addCase(playMovie.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(playMovie.fulfilled, (state) => {
                state.status = 'success';
                state.playStatus = 'Play';
            })
            .addCase(playMovie.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })

            .addCase(pauseMovie.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(pauseMovie.fulfilled, (state) => {
                state.status = 'success';
                state.playStatus = 'Pause';
            })
            .addCase(pauseMovie.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })

            .addCase(goToTimecode.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(goToTimecode.fulfilled, (state) => {
                state.status = 'success';
            })
            .addCase(goToTimecode.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })

            .addCase(ejectMovie.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(ejectMovie.fulfilled, (state, action) => {
                state.status = 'success';
                state.showStatus = action.payload;
                state.playing = null;
                state.showStatus = null;
            })
            .addCase(ejectMovie.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            })

            .addCase(getPlayStatus.fulfilled, (state, action) => {
                state.showStatus = action.payload.showStatus;
                state.playStatus = action.payload.showStatus?.state_info || 'Error';
            })
            .addCase(getPlayStatus.rejected, (state, action) => {
                state.error = action.payload || action.error.message;
            })
            .addCase(getPlayingMovie.pending, (state) => {
                state.status = 'loading';
                state.playing = state.playing ? state.playing : null;
            })
            .addCase(getPlayingMovie.fulfilled, (state, action) => {
                state.status = 'success';
                state.playing = action.payload;
            })
            .addCase(getPlayingMovie.rejected, (state) => {
                state.status = 'failed';
                state.playing = null;
            });
    }
});

export default playMovieSlice.reducer;
