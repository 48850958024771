import React, {Fragment, useEffect, useState} from 'react';
import {Button, ButtonGroup, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import './CrewCard.scss';
import {imgUrl} from '../../utils/api-request';

const CrewCard = ({
    credit,
    type = 'crew',
    canEdit = false,
    onEdit,
    onRemove,
    rolesList,
    showRole,
}) => {
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState({
        roles: [],
        roleName: '',
    });

    const toggle = () => {
        setModal(!modal);
    }

    const handleSubmit = e => {
        e.preventDefault();
        onEdit({
            ...formData,
            _id: credit._id,
            member: credit.member,
        });
        toggle();
    };

    const handleRemoveMember = memberId => {
        onRemove(memberId);
        toggle();
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const toggleRoleSelection = (role) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            roles: prevFormData.roles.includes(role)
                ? prevFormData.roles.filter(r => r !== role)
                : [...prevFormData.roles, role],
        }));
    };

    useEffect(() => {
        if (credit)
        setFormData({
            roles: credit.roles,
            roleName: credit.roleName,
        });
    }, [credit]);

    const renderInfoCard = (roles = credit.roles) => (
        <Fragment>
            <div className="photo flex-shrink-0">
                <img src={imgUrl(credit.member.photo)} alt={credit.member.name}/>
            </div>
            <div className="info text-center flex-grow-1 d-flex flex-column justify-content-center border-0">
                <div className="name fw-bold">{credit.member.name}</div>
                <div className="role-name fw-normal">{credit.roleName}</div>
                {showRole && <div className="role fw-normal">{roles.length ? roles.join(" & ") : '- No roles yet -'}</div>}
            </div>
        </Fragment>
    );

    return (
        <div className={`${type}-card-component position-relative d-flex align-items-center`}>
            {renderInfoCard()}

            {canEdit && (
                <Fragment>
                    <div
                        className="edit-overlay d-flex align-items-center justify-content-center position-absolute text-black"
                        onClick={toggle}
                    >
                        <span className="fas fa-edit me-2"/> Edit
                    </div>
                    <div
                        className="remove-overlay d-flex align-items-center justify-content-center position-absolute text-black"
                        onClick={() => onRemove(credit._id)}
                    >
                        <span className="fas fa-trash"/>
                    </div>

                    <Modal isOpen={modal} toggle={toggle} scrollable>
                        <ModalHeader tag="div" toggle={toggle} className="p-0">
                            <h1>Edit {type} member</h1>
                        </ModalHeader>

                        <Form onSubmit={handleSubmit}>
                            <ModalBody>
                                <div className="preview-container w-100 position-relative d-flex align-items-center">
                                    {renderInfoCard(formData.roles)}
                                </div>

                                {type === 'crew' && (
                                    <FormGroup>
                                        <ButtonGroup className="d-flex">
                                            {rolesList.map((role, index) => type === 'crew' && role !== 'Actor' && (
                                                <Button
                                                    className="position-relative role-selector"
                                                    key={index}
                                                    outline={!formData.roles.includes(role)}
                                                    color="primary"
                                                    onClick={() => toggleRoleSelection(role)}
                                                >
                                                    {formData.roles.includes(role) && (
                                                        <span
                                                            className="fas fa-check-circle position-absolute text-black"/>
                                                    )}
                                                    {role}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </FormGroup>
                                )}

                                {formData.roles.includes('Actor') && (
                                    <FormGroup>
                                        <Label for="name">Role name</Label>
                                        <Input
                                            type="text"
                                            id="roleName"
                                            name="roleName"
                                            value={formData.roleName}
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                )}
                            </ModalBody>

                            <ModalFooter className="position-relative">
                                <Button
                                    color="danger"
                                    className="position-absolute start-0"
                                    onClick={() => handleRemoveMember(credit._id)}
                                >
                                    Remove
                                </Button>
                                <Button type="submit" color="primary">Submit</Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                </Fragment>
            )}
        </div>
    );
};

export default CrewCard;
