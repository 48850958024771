import React from 'react';

import './TierBadge.scss';
import {useSelector} from 'react-redux';

const tierConfig = {
    premium: {
        className: 'premium',
        iconClass: 'fa-gem',
        text: 'premium',
    },
    mid: {
        className: 'mid',
        iconClass: 'fa-medal',
        text: 'mid',
    },
};

const TierBadge = ({tier}) => {
    const config = tierConfig[tier];
    const { role } = useSelector(state => state.auth.user);

    return config && !(role && role.startsWith('site-')) ? (
        <span className={`tier-badge ${config.className} pt-2 p-1 text-uppercase`}>
            <span className={`fas fa-fw me-2 ${config.iconClass}`}/>
            {config.text}
        </span>
    ) : null;
};

export default TierBadge;
