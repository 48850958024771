import React from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../slices/auth.slice';
import {useNavigate, useSearchParams, NavLink} from 'react-router-dom';
import {toast} from 'react-toastify';
import UserForm from './UserForm';

const CreateUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    const [searchParams] = useSearchParams();
    const parentOrganization = searchParams.get('parentOrganization') || '';

    const handleSubmit = async (formData) => {
        const promise = dispatch(registerUser({
            token: auth.token,
            userBody: formData,
        })).unwrap();
        toast.promise(promise, {
            pending: 'Creating user',
            success: 'User created successfully',
            error: 'Error creating user',
        });
        const result = await promise;
        if (parentOrganization) {
            navigate(`/organizations/${parentOrganization}?newUserCreated=${formData.username}`);
        } else {
            navigate(`/users/${result._id}`);
        }
    };

    return (
        <div className="create-user-view p-3 min-vh-100">
            <Row>
                <Col>
                    <NavLink onClick={() => navigate(-1)} className="text-decoration-none">
                        <span className="fas fa-chevron-left me-2" /> Users
                    </NavLink>
                </Col>
            </Row>
            <h1>Create User</h1>

            <UserForm
                user={auth.user}
                parentOrganization={parentOrganization}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

export default CreateUser;
