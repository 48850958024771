import React, {useEffect, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import { Button } from 'reactstrap';
import {fetchOrganization} from '../../slices/organizations.slice';
import Loading from '../../components/Loading';
import {formatCredits} from '../../utils/credits';
import {apiRequest} from '../../utils/api-request';
import SearchBar from '../../components/SearchBar';
import AddTransaction from './AddTransaction';
import './Transactions.scss';

const Transactions = () => {
    const { organizationId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const token = useSelector(state => state.auth.token);
    const organization = useSelector((state) => state.organizations.organization);
    const [transactions, setTransactions] = useState([]);
    const [transactionsLoading, setTransactionsLoading] = useState(true);
    const [transactionsLoaded, setTransactionsLoaded] = useState(false);
    const [searchValue, setSearchInput] = useState('');
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [creditModal, setCreditModal] = useState({ open: false, action: null });

    useEffect(() => {
        if (!user) return;
        const orgId = organizationId === 'own' ? user.parentOrganization._id : organizationId;
        dispatch(fetchOrganization({ token, orgId }));
        (async () => {
            try {
                const result = await apiRequest(`/organizations/${orgId}/transactions`);
                setTransactions(result);
                setFilteredTransactions(result);
                setTransactionsLoaded(true);
            } catch (err) {
                toast.error(`Failed to fetch distributors: ${err}`)
            } finally {
                setTransactionsLoading(false);
            }
        })();
    }, [token, dispatch, organizationId, user]);

    if (!organization) return <Loading fullHeight />;

    const handleFilter = event => {
        event.preventDefault();
        setSearchInput(event.target.value);
        setFilteredTransactions(transactions.filter(({description}) => description.toLowerCase().includes(event.target.value.toLowerCase())));
    };

    const toggleCreditModal = () => setCreditModal(prev => ({ open: !prev.open, action: null }));

    const handleAddTransaction = async (transaction) => {
        const body = {
            ...transaction,
            amount: Number(transaction.amount),
            transactionType: creditModal.action,
        };

        const promise = apiRequest(`/organizations/${organizationId}/transactions`, 'POST', body);
        toast.promise(promise, {
            pending: 'Creating transaction',
            success: 'Transaction created successfully',
            error: 'Error creating transaction'
        });
        const newTransaction = await promise;
        setTransactions([newTransaction, ...transactions]);
        setFilteredTransactions([newTransaction, ...transactions]);
        toggleCreditModal();
        dispatch(fetchOrganization({ token, orgId: organizationId }));
    };

    return (
        <div className="transactions-view p-3 min-vh-100">
            <NavLink to={`/organizations/${organizationId}`} className="text-decoration-none">
                <span className="fas fa-chevron-left me-2" /> Back to {organization.name}
            </NavLink>

            <h1 className="mt-1 mb-3">Transactions center</h1>


            <div className="bg-broken border-start border-primary p-3 mb-4 d-flex align-items-center justify-content-between">
                <div className="lead">
                    <span className="text-primary text-uppercase me-3">
                        <span className="fas fa-coins me-2" />Balance:
                    </span>
                    {formatCredits(organization.creditAmount)} credits
                </div>

                {['root', 'bac-admin', 'bac-user'].includes(user.role) && (
                    <div className="credit-actions">
                        <Button color="link" className="text-decoration-none text-white"
                                onClick={() => setCreditModal({open: true, action: 'credit'})}>
                            <span className="fas fa-plus-circle me-2 text-success"/>
                            Add Credits
                        </Button>

                        <Button color="link" className="text-decoration-none text-white"
                                onClick={() => setCreditModal({open: true, action: 'debit'})}>
                            <span className="fas fa-plus-circle me-2 text-danger"/>
                            Remove credits
                        </Button>
                    </div>
                )}
            </div>

            {transactionsLoading && <Loading/>}

            {transactionsLoaded && (
                <div className="transactions-list">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <h4 className="text-primary text-uppercase">
                            <span className="fas fa-list-check me-2"/>
                            Transactions
                        </h4>

                        <div className="top-0">
                            <Button color="link" onClick={console.log}><span className="fas fa-file-csv"/></Button>
                            <Button color="link" onClick={() => window.print()}><span className="fas fa-print"/></Button>
                        </div>

                    </div>
                    <SearchBar initialValue={searchValue} onChange={handleFilter}/>
                    <div className="mb-2"></div>

                    {filteredTransactions.map(({_id, transactionType, description, effectiveDate, amount, balanceBeforeTransaction, balanceAfterTransaction}) => (
                        <div
                            key={_id}
                            className={`transaction-item bg-broken px-3 py-2 border-start border-end mb-2 pointer
                            justify-content-between align-items-center ${transactionType}
                            d-flex ${transactionType === 'credit' ? 'border-success' : 'border-danger'}`}
                        >
                            <div className="text-uppercase">
                                <strong>
                                    <span className="fas fa-file-text me-2 text-primary" />
                                    {description}
                                </strong>
                                <br/>
                                <small>{new Date(effectiveDate).toLocaleString()}</small>
                            </div>

                            <div className="ba-transaction small">
                                {formatCredits(balanceBeforeTransaction)}
                                <span className="text-primary mx-2"> | </span>
                                <span className="text-primary fas fa-arrow-right me-2" />
                                {formatCredits(balanceAfterTransaction)}
                            </div>

                            <span className="amount lead fw-bold">
                                <span
                                    className={`text-uppercase p-1
                                    small ${transactionType === 'credit' ? 'text-success' : 'text-danger'} me-2`}
                                >
                                    <span className={`fas me-2 ${transactionType === 'credit' ?
                                        'fa-plus' : 'fa-minus'}`} />
                                    {formatCredits(amount)}
                                </span>
                            </span>
                        </div>
                    ))}
                </div>
            )}

            {searchValue !== '' && filteredTransactions.length === 0 && (
                <div className="mt-4 text-primary lead">
                    <span className="fas fa-face-frown me-2" /> No transactions found for "{searchValue}".
                </div>
            )}

            <AddTransaction
                isOpen={creditModal.open}
                action={creditModal.action}
                toggle={toggleCreditModal}
                orgCreditAmount={organization.creditAmount}
                onSave={handleAddTransaction}
            />
        </div>
    );
};

export default Transactions;
