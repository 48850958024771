import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SiteHomepage from './SiteHomepage';
import RequestsCard from './RequestsCard';
import {fetchHomepageOrders, fetchHomepageOrganizations} from '../../slices/orders.slice';
import OrdersCard from './OrdersCard';
import OrganiztionsCard from './OrganizationsCard';
import './Homepage.scss';
import {Col, Container, Row} from 'reactstrap';

const Homepage = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const { homepageList: ordersCount, homepageOrganizations } = useSelector(state => state.orders);

    useEffect(() => {
        if (user && ['bac-admin', 'bac-user', 'root' ].includes(user.role)) {
            dispatch(fetchHomepageOrders())
            dispatch(fetchHomepageOrganizations())
        }
    }, [dispatch, user]);

    if (!user) {
        return;
    }

    return (
        <div className="homepage-view min-vh-100 mb-5 position-relative">
            { ['bac-admin', 'bac-user', 'root' ].includes(user.role) && (
                <div className="d-flex justify-content-start">
                    <Container>
                        <Row>
                            <Col>
                                <RequestsCard requests={ordersCount?.find(({ statusWorkflow }) => statusWorkflow === 'request-pending')} />
                            </Col>
                            <Col>
                                <OrdersCard ordersCount={ordersCount} />
                            </Col>
                            <Col>
                                <OrganiztionsCard organizations={homepageOrganizations} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}


            {['site-admin', 'site-user'].includes(user.role) && (
                <div className="homepage-view bg-black">
                    <SiteHomepage/>
                </div>
            )}
        </div>
    );
};

export default Homepage;
