import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchUser = createAsyncThunk('users/fetchUser', async ({ token, userId }) => {
    const response = await api.get(`/users/${userId}`);
    return response.data;
});

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
    const response = await api.get('/users');
    return response.data;
});

export const updateUser = createAsyncThunk('users/update', async ({ userBody, userId }) => {
    const response = await api.put(`/users/${userId}`, userBody);
    return response.data;
});

export const deleteUser = createAsyncThunk('users/delete', async (userId) => {
    const response = await api.delete(`/users/${userId}`);
    return response.data;
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        list: [],
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
        user: null,
        userStatus: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        userError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = action.payload;
                state.error = null;
            })
            .addCase(fetchUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(fetchUser.pending, (state) => {
                state.userStatus = 'loading';
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.userStatus = 'success';
                state.user = action.payload;
                state.userError = null;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.userStatus = 'failed';
                state.userError = action.error.message || 'An error occurred';
            });
    },
});

export default userSlice.reducer;
