import React, {Fragment, useEffect, useState} from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import Avatar from '../../components/Avatar';
import SearchBar from '../../components/SearchBar';

const SelectDistributor = ({ isOpen, toggle, distributors = [], onSelect, selectedDistributor, avatarSize = 75, hideName = false }) => {
    const [filteredValues, setFilteredValues] = useState([]);
    const [searchValue, setSearchInput] = useState('');

    const handleFilter = event => {
        event.preventDefault();
        setSearchInput(event.target.value);
        setFilteredValues(distributors.filter(({ name }) => name.toLowerCase().includes(event.target.value.toLowerCase())));
    };

    useEffect(() => {
        setFilteredValues(distributors);
    }, [distributors]);

    return (
        <Fragment>
            <div
                className="distributor-select d-flex align-content-between align-items-center "
                onClick={toggle}>
                <Avatar
                    icon={<span className="fas fa-warehouse ms-1"></span>}
                    size={avatarSize}
                    imageUrl={distributors.find(({_id}) => selectedDistributor === _id)?.logo?.imageUrl}
                />
                {!hideName && (
                    <h5 className="ms-3 mb-0 text-primary">
                        {selectedDistributor ? (
                            <span className="text-uppercase">
                            {distributors.length && distributors.find(({_id}) => selectedDistributor === _id).name}
                        </span>
                        ) : (
                            <span>Select distributor <span className="fas fa-pencil"/></span>
                        )}
                    </h5>
                )}
            </div>


            <Modal isOpen={isOpen} toggle={toggle} scrollable className="distributor-select-modal">
                <ModalHeader toggle={toggle}>Select a Distributor</ModalHeader>
                <ModalBody>
                    <SearchBar initialValue={searchValue} onChange={handleFilter}/>

                    {filteredValues.map((distributor) => (
                        <div
                            key={distributor._id}
                            className="d-flex align-content-between align-items-center border-start border-primary mb-2 ps-3 py-2 distributor-row position-relative"
                            onClick={() => onSelect(distributor)}
                        >
                            <Avatar name={distributor.name} imageUrl={distributor.logo?.imageUrl} size={50}/>

                            <p className="mb-0 ms-3">
                                <span className="text-uppercase">{distributor.name}</span>
                            </p>

                            {distributor._id === selectedDistributor && (
                                <span className="fas fa-check-circle text-primary position-absolute end-0 me-3"/>
                            )}
                        </div>
                    ))}
                </ModalBody>
            </Modal>
        </Fragment>
    );
};

export default SelectDistributor;
