import React from 'react';
import {NavLink} from 'react-router-dom';


const OrganiztionsCard = ({ organizations }) => {
    if (!organizations) {
        return;
    }

    return (
        <div className="home-component d-flex flex-column p-3 m-3 w-100 bg-broken border-start border-primary">
            <div className="pic-and-name d-flex justify-content-between mb-3">
                <NavLink to={`/organizations`} className="text-decoration-none">
                    <h1 className="text-capitalize">Organizations</h1>
                </NavLink>
            </div>

            <div className="mb-3 flex-grow-1 ">
                <span className="fas fa-building me-2 text-primary" />
                {organizations.online || 0} of {organizations.total} organizations connected
            </div>

            <NavLink to={`/organizations`} className="text-decoration-none">
                View organizations <span className="fas fa-arrow-right ms-2" />
            </NavLink>
        </div>
    );
};

export default OrganiztionsCard;
