import { configureStore } from '@reduxjs/toolkit';

import auth from './auth.slice';
import automationCues from './automation-cues';
import crew from './crew.slice';
import distributors from './distributors.slice';
import movies from './movies.slice';
import movieVersions from './movie-versions.slice';
import orders from './orders.slice';
import organizations from './organizations.slice';
import notifications from './notifications.slice';
import playlists from './playlists.slice';
import users from './user.slice';
import playMovie from './play-movie.slice';
import auditLogs from './audit-logs.slice';

export default configureStore({
    reducer: {
        auth,
        automationCues,
        crew,
        distributors,
        movies,
        movieVersions,
        orders,
        organizations,
        notifications,
        playlists,
        users,
        playMovie,
        auditLogs,
    },
    devTools: true,
})
