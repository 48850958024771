import React, {Fragment, useEffect, useState} from 'react';
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useParams,useNavigate} from 'react-router-dom';
import {requestApproval, fetchOrder, updateOrder} from '../../slices/orders.slice';
import Loading from '../../components/Loading';
import {imgUrl} from '../../utils/api-request';
import OrderStatusBadge, {statusMap} from '../../components/OrderStatusBadge';
import UserCard from '../../components/UserCard';
import {formatCredits, computeOrderPrice} from '../../utils/credits';
import {toast} from 'react-toastify';
import DatePicker from 'react-datepicker';

const OrderDetails = () => {
    const { orderId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const { currentOrder: order, status } = useSelector((state) => state.orders);
    const [ approvalModal, setApprovalModal ] = useState({ isOpen: false, operation: null });
    const [ declineReason, setDeclineReason ] = useState('');
    const [ screeningTerms, setScreeningTerms ] = useState('');
    const [ bacCorrection, setBacCorrection ] = useState({
        defaultHirePeriodCorrection: null,
        hirePeriodTypeCorrection: null,
        screeningDateCorrection: null,
        priceCorrection: '',
    });

    const handleUpdateStatus = (e) => {
        const promise = dispatch(updateOrder({ orderId, body: { [e.target.name]: e.target.value } })).unwrap();
        toast.promise(promise, {
            pending: `Updating ${e.target.name}`,
            success: `${e.target.name} updated successfully`,
            error: `Error updating ${e.target.name}`,
        })
    };

    const handleRequestApproval = () => {
        let promise;
        if (approvalModal.operation === 'approve') {
            const body = { screeningTerms, bacCorrection };
            promise = dispatch(requestApproval({ orderId, operation: 'approve', body })).unwrap();
        }

        if (approvalModal.operation === 'decline') {
            const body = { declineReason };
            promise = dispatch(requestApproval({ orderId, operation: 'decline', body })).unwrap();
        }

        toast.promise(promise, {
            pending: 'Updating order',
            success: 'Order updated successfully',
            error: 'Error updating order',
        });
        setApprovalModal({ isOpen: false, operation: null })
    };

    const toggleApprovalModal = operation => {
        setApprovalModal(prevState => ({
            isOpen: !prevState.isOpen,
            operation: prevState.isOpen ? null : operation
        }));
    };

    const handleChange = (e)=>{
        const { name, value } = e.target;
        setBacCorrection({
            ...bacCorrection,
            [name]:value,
        })
    };

    useEffect(() => {
        dispatch(fetchOrder(orderId));
    }, [dispatch, orderId]);

    useEffect(() => {
        if (order) {
            setBacCorrection({
                defaultHirePeriodCorrection: order.version.defaultHirePeriod,
                hirePeriodTypeCorrection: order.version.hirePeriodType,
                screeningDateCorrection: new Date(order.screeningDate),
                priceCorrection: '',
            });
        }
    }, [order]);

    if (['idle', 'loading'].includes(status)) {
        return <Loading fullHeight />;
    }

    return status === 'success' && order && (
        <div className="order-view p-3 ps-4 ms-1 vh-100">
            <Row>
                <Col>
                    <NavLink onClick={()=>{navigate(-1)}} className="text-decoration-none">
                        <span className="fas fa-chevron-left me-2"/> Orders
                    </NavLink>
                </Col>
            </Row>

            <h3 className="text-primary mt-3">Order Details</h3>

            <div className="d-flex gap-3 header border-start border-primary bg-broken">
                <div className="pic-container">
                    {order.movie.poster ? (
                        <img
                            className="logo-img"
                            width={75}
                            alt={order.movie.title}
                            src={imgUrl(order.movie.poster.imageUrl)}
                        />
                    ) : (
                        <img
                            className="logo-img opacity-25"
                            width={75}
                            alt={order.movie.title}
                            src={`https://picsum.photos/seed/${order.movie.title}/75/112`}
                        />

                    )}
                </div>

                <div className="header-content flex-grow-1 d-flex justify-content-between align-items-center">
                    <div>
                        <p className="mb-0 text-uppercase text-expand small">
                            Order type: <span className="text-capitalize">{order.orderType}</span>
                        </p>
                        <h1 className="name text-capitalize text-primary d-flex align-items-center">
                            {order.movie.title}
                        </h1>
                        <h6>{order.version.name}</h6>
                    </div>

                    {order.status === 'request-pending' && (
                        <div className="d-flex justify-content-start gap-3">
                            <Button color="danger" outline onClick={() => toggleApprovalModal('decline')}>
                                <span className="fas fa-times-circle me-3"/>
                                Decline
                            </Button>
                            <Button color="success" outline onClick={() => toggleApprovalModal('approve')}>
                                <span className="fas fa-check-circle me-3"/>
                                Approve
                            </Button>
                        </div>
                    )}

                    <div className="d-flex justify-content-start gap-3">
                        <span>
                            <OrderStatusBadge status={order.status} isCard className="d-inline-flex"/>
                        </span>
                    </div>
                </div>
            </div>

            {order.purchasedAt && order.purchasedBy && (
                <div className="mt-4">
                    <h3 className="text-primary">Purchase details</h3>
                    <span className="fas fa-calendar me-2"/>
                    Placed at {new Date(order.purchasedAt).toLocaleString()} by:

                    <UserCard user={order.purchasedBy}/>
                </div>
            )}

            {order.downpaymentTransaction && (
                <div className="my-4">
                    <h3 className="text-primary">Transactions</h3>
                    <div
                        className={`transaction-item bg-broken px-3 py-2 border-start border-end mb-2 pointer
                            justify-content-between align-items-center ${order.downpaymentTransaction.transactionType}
                            d-flex ${order.downpaymentTransaction.transactionType === 'credit' ? 'border-success' : 'border-danger'}`}
                    >
                        <div className="text-uppercase">
                            <strong>
                                <span className="fas fa-file-text me-2 text-primary"/>
                                {order.downpaymentTransaction.description}
                            </strong>
                            <br/>
                            <small>{new Date(order.downpaymentTransaction.effectiveDate).toLocaleString()}</small>
                        </div>

                        <div className="ba-transaction small">
                            {/*{formatCredits(order.downpaymentTransaction.balanceBeforeTransaction)}*/}
                            <span className="text-primary fas fa-minus mx-2"/>
                            {formatCredits(order.downpaymentTransaction.amount)} credits
                            {/*<span className="text-primary fas fa-equals mx-2"/>*/}
                            {/*{formatCredits(order.downpaymentTransaction.balanceAfterTransaction)}*/}
                        </div>

                    </div>
                </div>
            )}

            {user && ['root'].includes(user.role) && (
                <div className="mt-5 bg-black p-5 border-start border-primary mb-2">
                    <h4 className="text-danger">
                        <span className="fas fa-exclamation-triangle me-2" />DANGER ZONE: Override statuses
                    </h4>

                    <div className="d-flex justify-content-between gap-2 mt-4">
                        <FormGroup>
                            <Label className="fw-bold text-primary text-uppercase text-center" htmlFor="status">Status</Label>
                            <select value={order.status} onChange={handleUpdateStatus} name="status"
                                    className="form-select">
                                {Object.entries(statusMap).map(([key, {label}]) => (
                                    <option className="text-black" key={key} value={key}>{label}</option>
                                ))}
                            </select>
                        </FormGroup>

                        <FormGroup>
                            <Label className="fw-bold text-primary text-uppercase text-center" htmlFor="status">StatusWorkflow</Label>
                            <select value={order.statusWorkflow} onChange={handleUpdateStatus} name="statusWorkflow"
                                    className="form-select">
                                {Object.entries(statusMap).map(([key, {label}]) => (
                                    <option className="text-black" key={key} value={key}>{label}</option>
                                ))}
                            </select>
                        </FormGroup>

                        <FormGroup>
                            <Label className="fw-bold text-primary text-uppercase text-center" htmlFor="status">Request Status</Label>
                            <select value={order.requestStatus || ""} onChange={handleUpdateStatus} name="requestStatus"
                                    className="form-select">
                                <option className="text-black" value=""> - Select -</option>
                                <option className="text-black" value="pending">Pending</option>
                                <option className="text-black" value="approved">Approved</option>
                                <option className="text-black" value="declined">Declined</option>
                            </select>
                        </FormGroup>

                        <FormGroup>
                            <Label className="fw-bold text-primary text-uppercase text-center" htmlFor="status">Order Type</Label>
                            <select value={order.orderType || ""} onChange={handleUpdateStatus} name="orderType"
                                    className="form-select">
                                <option className="text-black" value="to-order">to-order</option>
                                <option className="text-black" value="order">order</option>
                                <option className="text-black" value="request">request</option>
                                <option className="text-black" value="re-order">re-order</option>
                            </select>
                        </FormGroup>
                    </div>
                </div>
            )}

            <div className="clearfix py-4"/>

            <Modal isOpen={approvalModal.isOpen} toggle={toggleApprovalModal}>
                <ModalHeader toggle={toggleApprovalModal} className="text-primary">Request approval</ModalHeader>
                <ModalBody>
                    {approvalModal.operation === 'approve' && (
                        <div>
                            <Label>Screening terms</Label>

                            <Input
                                type="textarea"
                                className="py-3"
                                value={screeningTerms}
                                onChange={e => setScreeningTerms(e.target.value)}
                            />

                            <Label className="mt-3">Screening date & time</Label>
                            <div className="d-flex">
                                <DatePicker
                                    className="form-control"
                                    wrapperClassName="w-100"
                                    showIcon
                                    selected={bacCorrection.screeningDateCorrection || new Date(order.screeningDate)}
                                    onChange={(datetime) =>
                                        setBacCorrection((prevState) => ({
                                            ...prevState,
                                            screeningDateCorrection: datetime,
                                        }))
                                    }
                                    showTimeSelect
                                    timeIntervals={15}
                                    dateFormat="dd-MM-yyyy hh:mm aa"
                                    placeholderText="Select date and time"
                                />
                            </div>

                            <Label className="mt-3">Hire Period</Label>
                            <div className="d-flex">
                                <Input
                                    value={bacCorrection.defaultHirePeriodCorrection}
                                    name="defaultHirePeriodCorrection"
                                    type="number"
                                    onChange={handleChange}
                                />

                                <select className="form-select-small ms-3"
                                        value={bacCorrection.hirePeriodTypeCorrection} name="hirePeriodTypeCorrection"
                                        onChange={handleChange}>
                                    <option value="days">Days</option>
                                    <option value="hours">Hours</option>
                                </select>
                            </div>

                            <Label className="mt-3">Price correction</Label>
                            <Input
                                type="number"
                                className="py-3"
                                name="priceCorrection"
                                value={bacCorrection.priceCorrection}
                                onChange={handleChange}
                            />

                            <div className="mt-3"/>

                            {!!Number(bacCorrection.priceCorrection) && (
                                <Fragment>
                                    <div className="d-flex">
                                        <Label className="flex-grow-1">
                                            Initial amount:
                                        </Label>
                                        <span>{`${computeOrderPrice(order.version.conditionalPrice, 'number')} credits`}</span>
                                    </div>

                                    <div className="d-flex">
                                        <Label className="flex-grow-1">
                                            Correction:
                                        </Label>
                                        <span
                                            className={bacCorrection.priceCorrection > 0 ? 'text-success' : 'text-danger'}>
                                        {`${Number(bacCorrection.priceCorrection)} credits`}
                                    </span>
                                    </div>
                                </Fragment>
                            )}

                            <div className="d-flex">
                                <Label className="flex-grow-1">
                                    Final amount:
                                </Label>
                                <span>{`${computeOrderPrice(order.version.conditionalPrice, 'number') + Number(bacCorrection.priceCorrection)} credits`}</span>
                            </div>
                        </div>
                    )}

                    {approvalModal.operation === 'decline' && (
                        <div>
                            <Label>Reason for decline</Label>
                            <Input
                                type="textarea"
                                className="py-3"
                                value={declineReason}
                                onChange={e => setDeclineReason(e.target.value)}
                            />
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color={approvalModal.operation === 'approve' ? 'success' : 'danger'}
                        outline
                        onClick={handleRequestApproval}
                    >
                        <span className={`fas ${approvalModal.operation === 'approve' ? 'fa-check-circle' : 'fa-times-circle'} me-2`} />
                        {approvalModal.operation === 'approve' ? 'Approve' : 'Decline'}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default OrderDetails;
