import React, {useEffect} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fetchOrganization, updateOrganization} from '../../slices/organizations.slice';
import {fetchAutomationCues} from '../../slices/automation-cues';
import {FormGroup, Input, Label} from 'reactstrap';
import {toast} from 'react-toastify';

const CueSelector = ({ cues, onChange, value }) => {
    return (
        <FormGroup>
            <Label>Select cue to map</Label>
            <Input name="cue-selector" value={value || ''} type="select" onChange={(e) => onChange(e.target.value)}>
                <option value="" className="text-black">- select -</option>
                {cues.map(({ cue_id, name }) => (
                    <option key={cue_id} value={cue_id} className="text-black">
                        {name}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
};

const DefaultCuePlaylist = () => {
    const { organizationId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const token = useSelector(state => state.auth.token);
    const organization = useSelector(state => state.organizations.organization);
    const { list: automationCues } = useSelector(state => state.automationCues);

    const handleChange = (field) => (cue_id) => {
        if (!['douserOpen', 'douserClose', 'lampOn', 'lampOff'].includes(field)) {
            toast.error(`Invalid ${field}`);
            return false;
        }

        const organizationBody = {
            ...organization,
            automationMap: {
                ...organization.automationMap,
                [field]: cue_id,
            },
        };

        const promise = dispatch(updateOrganization({
            orgId: organizationId,
            organizationBody,
        })).unwrap();

        toast.promise(promise, {
            pending: 'Updating organization automation...',
            success: 'Organization automation updated',
            error: 'Error updating the organization automation',
        });
    };

    useEffect(() => {
        if (!user) return;
        const orgId = organizationId === 'own' ? user.parentOrganization._id : organizationId;
        dispatch(fetchOrganization({ orgId }));
        dispatch(fetchAutomationCues(orgId));
    }, [token, dispatch, organizationId, user]);

    return organization && (
        <div className="automation-cues-view p-3 min-vh-100">
            <NavLink to={`/organizations/${organizationId}`} className="text-decoration-none">
                <span className="fas fa-chevron-left me-2" /> Back to {organization.name}
            </NavLink>

            <h1 className="mt-1 mb-3">Automation Cues Mapping</h1>

            <div className="mb-3">
                <h3 className="text-primary">Douser Open</h3>
                <CueSelector cues={automationCues} value={organization.automationMap.douserOpen} onChange={handleChange('douserOpen')} />
            </div>

            <div className="mb-3">
                <h3 className="text-primary">Douser Close</h3>
                <CueSelector cues={automationCues} value={organization.automationMap.douserClose} onChange={handleChange('douserClose')} />
            </div>

            <div className="mb-3">
                <h3 className="text-primary">Lamp ON</h3>
                <CueSelector cues={automationCues} value={organization.automationMap.lampOn} onChange={handleChange('lampOn')} />
            </div>

            <div className="mb-3">
                <h3 className="text-primary">Lamp OFF</h3>
                <CueSelector cues={automationCues} value={organization.automationMap.lampOff} onChange={handleChange('lampOff')} />
            </div>


        </div>
    );
};

export default DefaultCuePlaylist;
