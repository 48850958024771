import React, {useState} from 'react';
import {Button, Form, FormGroup, Label, Input, Container, Row, Col} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../slices/auth.slice';

import './Login.scss';
import {toast} from 'react-toastify';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const result = await dispatch(login({ username, password }));

            if (result.error) {
                toast.error('Username and/or password incorrect');
            } else {
                localStorage.setItem('role', result.payload.user.role);
                toast.success(`Welcome ${username}`);
                navigate('/');
            }
        } catch (error) {
            toast.error('Error logging in');
        }
    };

    return (
        <Container fluid className="login-view">
            <Row className="justify-content-center align-items-center min-vh-100">
                <Col className="form-wrap" md={4}>
                    <div className="text-center">
                        <img src="/logo.svg" alt="" width="220px"/>
                    </div>

                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Input
                                required
                                type="text"
                                name="username"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <Label className='input-text-label' for="username">Username</Label>
                        </FormGroup>
                        <FormGroup>
                            <Input
                                required
                                type="password"
                                name="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Label className='input-text-label' for="password">Password</Label>
                        </FormGroup>

                        <FormGroup check>
                            <Label check className='checkbox-label'>
                                <Input
                                    type="checkbox"
                                    checked={rememberMe}
                                    onChange={(e) => setRememberMe(e.target.checked)}
                                />
                                Remember me
                            </Label>
                        </FormGroup>

                        <div className='submit-wrap d-flex flex-column align-items-center'>
                            <Button type="submit" color="primary" outline size='lg'>Log in</Button>
                        </div>

                    </Form>
                </Col>
            </Row>

        </Container>
    );
};

export default Login;
