import React, {useEffect, useState} from 'react';
import {Form, FormGroup, Label, Input, InputGroup, Button, ButtonGroup} from 'reactstrap';
import TierSwitch from '../../components/TierSwitch';
import CountrySelect from '../../components/CountrySelect';
import OrganizationSelect from '../../components/OrganizationSelect';
import Countries from './countries.json';
import ConditionalPrice from '../../components/ConditionalPrice';
import {apiRequest} from '../../utils/api-request';
import {useDispatch, useSelector} from 'react-redux';
import DatePicker from 'react-datepicker';
import ToggleSwitch from '../../components/ToggleSwitch';
import SelectDistributor from './SelectDistributor';
import BadgeSelect from '../../components/BadgeSelect';
import {fetchDistributors} from '../../slices/distributors.slice'


const JsonDisplay = ({ data }) => {
    const entries = Object.entries(data);

    return (
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px' }}>
            {entries.map(([key, value], index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                    <div className="text-uppercase fw-bold">{key.replace(/([a-z])([A-Z])/g, '$1 $2')}</div>
                    <div>{Array.isArray(value) ? value.join(', ') : value.toString()}</div>
                </div>
            ))}
        </div>
    );
};

const VersionForm = ({ versionData, handleSubmit, handleChange, handleCancel, toggleCountriesModal, countriesModal, toggleOrganizationsModal, organizationsModal, organizations }) => {
    const isEditing = !!versionData._id;
    const [assetFetching, setAssetFetching] = useState(false);
    const [assetDetails, setAssetDetails] = useState(versionData.assetDetails);
    const { list: distributors } = useSelector(state => state.distributors);
    const [distributorModal, setDistributorModal] = useState(false);
    const [hirePeriod, setHirePeriod] = useState({
        defaultHirePeriod:versionData.defaultHirePeriod,
        hirePeriodType: versionData.hirePeriodType
    })
    const dispatch = useDispatch();

    const handleFetchAsset = async e => {
        e.preventDefault();
        setAssetFetching(true);
        const details = await apiRequest(`/external/fetch-asset/${versionData.uuid}`);
        setAssetDetails(details);
        setAssetFetching(false);
        handleChange({
            target: {
                name: 'assetDetails',
                value: details,
            },
        });
    };

    const isValidUUID = () => {
        const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
        return uuidRegex.test(versionData.uuid);
    }

    const handleDistributorSelect = distributor => {
        handleChange({target: {name: 'distributor', value: distributor._id}});
        toggleDistributorModal();
    };

    const toggleDistributorModal = () => setDistributorModal(!distributorModal);

    const changeContentRestrictions = value => {
        handleChange({ target: { name: 'contentRestrictions', value }});
    };

    const handleChangeHirePeriod =(e)=>{
        const { name, value } = e.target;
        handleChange({target:{name:name, value:value}})
        setHirePeriod({...hirePeriod, [name]:value})
    }

    const fetchHirePeriod =()=>{
        handleChange([
            {target:{name: 'defaultHirePeriod',value: distributors?.find(({ _id }) => _id === versionData.distributor).defaultHirePeriod}},
            {target:{ name: 'hirePeriodType',value: distributors.find(({ _id }) => _id === versionData.distributor).hirePeriodType}}
        ]);
        setHirePeriod({defaultHirePeriod: distributors?.find(({ _id }) => _id === versionData.distributor).defaultHirePeriod, hirePeriodType:distributors.find(({ _id }) => _id === versionData.distributor).hirePeriodType})
    }

    useEffect(() => {
        dispatch(fetchDistributors());
    }, [dispatch]);

    return (
        <Form onSubmit={handleSubmit} className="mt-4 w-100">
            <h5 className="text-primary">{isEditing ? `Edit ${versionData.name}` : 'Create New Version'}</h5>
            <hr/>

            <div className="bg-broken p-3 mb-3">
                <SelectDistributor
                    isOpen={distributorModal}
                    toggle={toggleDistributorModal}
                    distributors={distributors}
                    onSelect={handleDistributorSelect}
                    selectedDistributor={versionData.distributor}
                />
            </div>

            <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" name="name" id="name" value={versionData.name} onChange={handleChange} required/>
            </FormGroup>

            <FormGroup>
                <Label for="uuid">Portal UUID</Label>
                <InputGroup>
                    <Input type="text" name="uuid" id="uuid" value={versionData.uuid} onChange={handleChange} required/>
                    <Button color="primary" onClick={handleFetchAsset} disabled={!isValidUUID()}>
                        {isValidUUID() ? (
                            <span className={`fas fa-refresh me-2 ${assetFetching && 'fa-spin'}`}/>
                        ) : (
                            <span className="fas fa-warning me-2"/>
                        )}

                        {isValidUUID() ? 'Fetch Asset Details' : 'Invalid UUID'}
                    </Button>
                </InputGroup>
            </FormGroup>

            <div className="d-flex">
                <FormGroup>
                    <Label className="me-2 d-block">Tier(s)</Label>
                    <TierSwitch value={versionData.tier} onChange={handleChange} multiple/>
                </FormGroup>

                <FormGroup className="ms-md-3">
                    <Label>Is Movie on Request?</Label>
                    <div className="pt-1">
                        <ToggleSwitch
                            isOn={versionData.isMovieOnRequest}
                            onChange={value => handleChange({target: {name: 'isMovieOnRequest', value}})}
                        />
                    </div>
                </FormGroup>

                <FormGroup className="ms-md-3">
                    <Label>BAC Aurora?</Label>
                    <div className="pt-1">
                        <ToggleSwitch
                            isOn={versionData.isAurora}
                            onChange={value => handleChange({target: {name: 'isAurora', value}})}
                        />
                    </div>
                </FormGroup>
            </div>

            <FormGroup>
                <Label>Content Restrictions</Label>
                <BadgeSelect
                    defaultList={[6, 9, 12, 14, 16, 18]}
                    onChange={changeContentRestrictions}
                    values={versionData.contentRestrictions}
                    multiple={false}
                    append="+"
                />
            </FormGroup>

            <FormGroup>
                <Label for="countries">Region availability</Label>
                <CountrySelect
                    name="countries"
                    value={versionData.countries}
                    countries={Countries.regions}
                    onChange={values => handleChange({target: {name: 'countries', value: values}})}
                    placeholder="Select countries"
                    modal={countriesModal}
                    toggle={toggleCountriesModal}
                />
            </FormGroup>

            <FormGroup>
                <Label for="countries">Organization availability</Label>
                <OrganizationSelect
                    name="organizations"
                    value={organizations.filter((org)=> versionData.availableForOrganizations?.includes(org._id) )}
                    organizations={organizations}
                    addLogo
                    useId
                    onChange={values => handleChange({target: {name: 'availableForOrganizations', value: values}})}
                    placeholder="Select organizations"
                    modal={organizationsModal}
                    toggle={toggleOrganizationsModal}
                />
            </FormGroup>

            <div className="d-flex">
                <FormGroup>
                    <Label>Release Date</Label>
                    <DatePicker
                        className="date-field"
                        showIcon
                        withPortal
                        fixedHeight
                        selected={versionData.releaseDate}
                        onChange={(date) => handleChange({target: {name: 'releaseDate', value: date?.toISOString()}})}
                        dateFormat="dd-MM-yyyy"
                        placeholderText="After"
                        monthsShown={2}
                        toggleCalendarOnIconClick
                        isClearable={true}
                    />
                </FormGroup>
            </div>

            <FormGroup>
                <Label>Conditional Pricing</Label>

                {versionData.releaseDate ? (
                    <ConditionalPrice
                        releaseDate={versionData.releaseDate}
                        handleChange={handleChange}
                        conditionalPrice={versionData.conditionalPrice}
                        distributor={distributors?.find(({ _id }) => _id === versionData.distributor )}
                        tiers={versionData.tier}
                    />
                ) : <div className="small mb-4">Release Date is missing</div>}

            </FormGroup>

            <FormGroup>
                <Label>Hire Period</Label>
                <Button color="link" onClick={fetchHirePeriod}>
                                    <span className="fas fa-refresh me-2" />
                                    Fetch from distributor
                                </Button>
                <div className='d-flex'>
                    <Input
                        value={hirePeriod.defaultHirePeriod}
                        name="defaultHirePeriod"
                        type='number'
                        onChange={handleChangeHirePeriod}/>

                    <select className="form-select-small ms-3" value={hirePeriod.hirePeriodType} name='hirePeriodType' onChange={handleChangeHirePeriod}>
                        <option value="days">Days</option>
                        <option value="hours">Hours</option>
                    </select>
                </div>
            </FormGroup>

            {assetDetails && <JsonDisplay data={assetDetails}/>}

            <div className="d-flex float-end my-3">
                <ButtonGroup>
                    <Button color="primary" outline onClick={handleCancel}>Cancel</Button>
                    <Button color="primary" type="submit" disabled={!versionData.distributor || !isValidUUID() || !versionData.assetDetails}>
                        <span className="fas fa-save me-2"/>
                        {isEditing ? 'Save Changes' : 'Create Version'}
                    </Button>
                </ButtonGroup>
            </div>
        </Form>
    );
};

export default VersionForm;
