import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchOrganizations = createAsyncThunk('organizations/fetchOrganizations', async () => {
    const response = await api.get('/organizations');
    return response.data;
});

export const createOrganization = createAsyncThunk('organization/create', async ({ organizationBody }) => {
    const response = await api.post('/organizations', organizationBody);
    return response.data;
});

export const fetchOrganization = createAsyncThunk('organizations/fetchOrganization', async ({ orgId }) => {
    const response = await api.get(`/organizations/${orgId}`);
    return response.data;
});

export const updateOrganization = createAsyncThunk('users/update', async ({ organizationBody, orgId }) => {
    const response = await api.put(`/organizations/${orgId}`, organizationBody);
    return response.data;
});

export const deleteOrganization = createAsyncThunk('users/delete', async (organizationId) => {
    const response = await api.delete(`/organizations/${organizationId}`)
    return response.data;
});

export const fetchOrganizationUsers = createAsyncThunk('organizations/fetchOrganizationUsers', async ({ token, orgId }) => {
    const response = await api.get(`/organizations/${orgId}/users`);
    return response.data;
});

export const fetchOrganizationContent = createAsyncThunk('organizations/fetchOrganizationContent', async ({ token, orgId }) => {
    const response = await api.get(`/organizations/${orgId}/content`);
    return response.data;
});

export const togglePinnedContent = createAsyncThunk('organizations/togglePinnedContent', async (contentId) => {
    const response = await api.post(`/content/${contentId}/toggle-pinned`);
    return response.data;
});

const organizationsSlice = createSlice({
    name: 'organizations',
    initialState: {
        list: [],
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
        organization: null,
        organizationStatus: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        organizationError: null,
        organizationUsers: [],
        organizationUsersStatus: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        organizationUsersError: null,
        organizationContent: [],
        organizationContentStatus: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        organizationContentError: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizations.pending, (state) => {
                state.status = 'loading';
                state.list = [];
            })
            .addCase(fetchOrganizations.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = action.payload;
                state.error = null;
            })
            .addCase(fetchOrganizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(fetchOrganization.pending, (state) => {
                state.organizationStatus = 'loading';
            })
            .addCase(fetchOrganization.fulfilled, (state, action) => {
                state.organizationStatus = 'success';
                state.organization = action.payload;
                state.organizationError = null;
            })
            .addCase(fetchOrganization.rejected, (state, action) => {
                state.organizationStatus = 'failed';
                state.organizationError = action.error.message || 'An error occurred';
            })

            .addCase(updateOrganization.pending, (state) => {
                state.organizationStatus = 'loading';
            })
            .addCase(updateOrganization.fulfilled, (state, action) => {
                state.organizationStatus = 'success';
                state.organization = action.payload;
                state.organizationError = null;
            })
            .addCase(updateOrganization.rejected, (state, action) => {
                state.organizationStatus = 'failed';
                state.organizationError = action.error.message || 'An error occurred';
            })

            .addCase(fetchOrganizationUsers.pending, (state) => {
                state.organizationUsersStatus = 'loading';
            })
            .addCase(fetchOrganizationUsers.fulfilled, (state, action) => {
                state.organizationUsersStatus = 'success';
                state.organizationUsers = action.payload;
                state.organizationUsersError = null;
            })
            .addCase(fetchOrganizationUsers.rejected, (state, action) => {
                state.organizationUsersStatus = 'failed';
                state.organizationUsersError = action.error.message || 'An error occurred';
            })

            .addCase(fetchOrganizationContent.pending, (state) => {
                state.organizationContentStatus = 'loading';
                state.organizationContent = [];
            })
            .addCase(fetchOrganizationContent.fulfilled, (state, action) => {
                state.organizationContentStatus = 'success';
                state.organizationContent = action.payload;
                state.organizationContentError = null;
            })
            .addCase(fetchOrganizationContent.rejected, (state, action) => {
                state.organizationContentStatus = 'failed';
                state.organizationContentError = action.error.message || 'An error occurred';
            })

            .addCase(togglePinnedContent.pending, (state) => {
                state.organizationContentStatus = 'loading';
            })
            .addCase(togglePinnedContent.fulfilled, (state, action) => {
                state.organizationContentStatus = 'success';
                state.organizationContentError = null;
                const updatedContent = action.payload;
                const index = state.organizationContent.findIndex(
                    (content) => content._id === updatedContent._id
                );
                if (index !== -1) {
                    state.organizationContent[index] = updatedContent;
                }
            })
            .addCase(togglePinnedContent.rejected, (state, action) => {
                state.organizationContentStatus = 'failed';
                state.organizationContentError = action.error.message || 'An error occurred';
            })
        ;
    },
});

export default organizationsSlice.reducer;
