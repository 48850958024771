import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const ingestCPL = createAsyncThunk('content/ingestCPL', async (cpl) => {
    const response = await api.post(`/content/${cpl._id}`, cpl);
    return response.data;
});

const contentSlice = createSlice({
    name: 'user',
    initialState: {
        current: null,
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(ingestCPL.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(ingestCPL.fulfilled, (state, action) => {
                state.status = 'success';
                state.current = action.payload;
            })
            .addCase(ingestCPL.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })
    },
});

export default contentSlice.reducer;
