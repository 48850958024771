import React from 'react';
import './Avatar.scss';
import {imgUrl} from '../../utils/api-request';

const Avatar = ({imageUrl, name, size = 100, onEdit, icon}) => {
    const renderAvatarContent = () => {
        if (imageUrl) {
            return <img src={imgUrl(imageUrl)} alt={name} className="avatar-img w-100 h-100 object-fit-cover"/>;
        }
        const initials = icon || name.split(' ').slice(0, 2).map(word => word[0]).join('').toUpperCase();
        return <span className="avatar-initials">{initials}</span>;
    };

    return (
        <div
            className="avatar d-flex justify-content-center align-items-center text-white fw-bold text-uppercase
                       position-relative overflow-hidden"
            style={{'--avatar-size': `${size}px`}}
            onClick={onEdit}
        >
            {renderAvatarContent()}

            {onEdit && (
                <div className="avatar-overlay position-absolute w-100 h-100 d-flex justify-content-center align-items-center gap-2">
                    <span className="fas fa-pencil mr-2"></span> Edit
                </div>
            )}
        </div>
    );
};

export default Avatar;
