import React, {useState, Fragment} from 'react';
import {Button, FormGroup, Label, Input, ButtonGroup, Row, Col, ModalFooter, ModalBody} from 'reactstrap';

const CinemaProperties = ({ initialSettings, onSave }) => {
    const [cinemaSettings, setCinemaSettings] = useState(initialSettings);

    const handleSelectChange = (field, value, multiple = false) => {
        setCinemaSettings((prevSettings) => {
            const currentValues = prevSettings[field] || [];
            if (!multiple) {
                return {
                    ...prevSettings,
                    [field]: value,
                };
            }

            const isAlreadySelected = currentValues.includes(value);
            const newValues = isAlreadySelected
                ? currentValues.filter((v) => v !== value)
                : [...currentValues, value];

            return {
                ...prevSettings,
                [field]: newValues,
            };
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCinemaSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleOther = (e) => {
        setCinemaSettings((prevSettings) => ({
            ...prevSettings,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(cinemaSettings);
    };

    const renderButtonOption = (field, optionValue, displayValue, multiple = false) => {
        const isSelected = multiple
            ? (cinemaSettings[field] || []).includes(optionValue)
            : cinemaSettings[field] === optionValue;

        return (
            <Button
                key={optionValue}
                className="px-3"
                color="primary"
                outline={!isSelected}
                onClick={() => handleSelectChange(field, optionValue, multiple)}
            >
                {displayValue}
            </Button>
        );
    };


    return (
        <Fragment>
            <ModalBody>
                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <Label className="d-block">
                                <span className="fas fa-volume-up me-2 text-primary" title="Audio Type"/>
                                Audio Types
                            </Label>
                            <ButtonGroup>
                                {renderButtonOption('audioType', '5.1', '5.1', true)}
                                {renderButtonOption('audioType', '7.1', '7.1', true)}
                                {renderButtonOption('audioType', '9.1', '9.1', true)}
                                {renderButtonOption('audioType', '11.1', '11.1', true)}
                                {renderButtonOption('audioType', 'Atmos', 'Atmos', true)}
                                {renderButtonOption('audioType', 'Auro', 'Auro', true)}
                                <Input
                                    type="text"
                                    name="audioTypeOther"
                                    value={cinemaSettings?.audioTypeOther || ''}
                                    onChange={handleInputChange}
                                    placeholder="Other"
                                    className="border-primary"
                                    style={{height: '38px', width: '100px'}}
                                />
                            </ButtonGroup>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label className="d-block">
                                <span className="fas fa-file-audio me-2 text-primary" />
                                Auxiliary
                            </Label>
                            <ButtonGroup>
                                {renderButtonOption('auxiliary', 'dbox', 'Dbox', true)}
                                {renderButtonOption('auxiliary', '2d', '2D', true)}
                                {renderButtonOption('auxiliary', '3d', '3D', true)}
                            </ButtonGroup>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label className="d-block">
                                <span className="fas fa-expand me-2 text-primary" />
                                Aspect Ratio
                            </Label>
                            <ButtonGroup>
                                {renderButtonOption('aspectRatio', 'flat', 'Flat')}
                                {renderButtonOption('aspectRatio', 'scope', 'Scope')}
                                <Input
                                    type="text"
                                    name="aspectRatio"
                                    value={cinemaSettings?.aspectRatio || ''}
                                    onChange={handleOther}
                                    placeholder="Other"
                                    className="border-primary"
                                    style={{ height: '38px', width: '100px' }}
                                />
                            </ButtonGroup>
                        </FormGroup>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <Label className="d-block">
                                <span className="fas fa-desktop me-2 text-primary" />
                                Resolution
                            </Label>
                            <ButtonGroup>
                                {renderButtonOption('resolution', '2K', '2K')}
                                {renderButtonOption('resolution', '4K', '4K')}
                            </ButtonGroup>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label className="d-block">
                                <span className="fas fa-film me-2 text-primary" />
                                Framerate (FPS)
                            </Label>
                            <ButtonGroup>
                                {renderButtonOption('framerate', '60', '60 FPS')}
                                <Input
                                    type="text"
                                    name="framerate"
                                    value={cinemaSettings?.framerate || ''}
                                    onChange={handleOther}
                                    placeholder="Other"
                                    className="border-primary"
                                    style={{ height: '38px', width: '100px' }}
                                />
                            </ButtonGroup>
                        </FormGroup>
                    </Col>
                </Row>



                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <Label for="audioProcessor">
                                <span className="fas fa-microchip me-2 text-primary" title="Audio processor"/>
                                Audio processor
                            </Label>
                            <Input
                                id="audioProcessor"
                                name="audioProcessor"
                                value={cinemaSettings?.audioProcessor || ''}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="movieListEmailAddress">
                                <span className="fas fa-at me-2 text-primary" title="Movie list email address"/>
                                Movie list email address
                            </Label>
                            <Input
                                id="movieListEmailAddress"
                                name="movieListEmailAddress"
                                value={cinemaSettings?.movieListEmailAddress || ''}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <Label for="languageSpoken">
                                <span className="fas fa-language me-2 text-primary" title="Spoken Language"/>
                                Spoken Language
                            </Label>
                            <Input
                                id="languageSpoken"
                                name="languageSpoken"
                                value={cinemaSettings?.languageSpoken || ''}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label for="languageSubtitle">
                                <span className="fas fa-closed-captioning me-2 text-primary" title="Spoken Language"/>
                                Subtitle Language
                            </Label>
                            <Input
                                id="languageSubtitle"
                                name="languageSubtitle"
                                value={cinemaSettings?.languageSubtitle || ''}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <Label for="projectorMakeModel">
                                <span className="fas fa-box me-2 text-primary" title="Projector Make/Model" />
                                Projector Make/Model
                            </Label>
                            <Input
                                id="projectorMakeModel"
                                name="projectorMakeModel"
                                value={cinemaSettings?.projectorMakeModel || ''}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="projectorSN">
                                <span className="fas fa-barcode me-2 text-primary" title="Projector S/N" />
                                Projector S/N
                            </Label>
                            <Input
                                id="projectorSN"
                                name="projectorSN"
                                value={cinemaSettings?.projectorSN || ''}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for="ipAddress1">
                                <span className="fas fa-network-wired me-2 text-primary" title="IP Address" />
                                IP Address
                            </Label>
                            <Input
                                id="ipAddress1"
                                name="ipAddress1"
                                value={cinemaSettings?.ipAddress1 || ''}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="firmwareVersion">
                                <span className="fas fa-floppy-disk me-2 text-primary" title="Firmware/Software version"/>
                                Firmware/Software version
                            </Label>
                            <Input
                                id="firmwareVersion"
                                name="firmwareVersion"
                                value={cinemaSettings?.firmwareVersion || ''}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>

            <ModalFooter>
                <Button type="submit" color="primary" className="float-end" onClick={handleSubmit}>
                    <span className="fas fa-save me-2" />
                    Save Changes
                </Button>
            </ModalFooter>
        </Fragment>
    );
};

export default CinemaProperties;
