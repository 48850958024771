import './movieSlider.scss';
import React, { useRef, useState, useEffect } from 'react';
import TrailerThumb from "../MovieThumb/TrailerThumb";

const MovieSlider = ({ versions = [] }) => {
    const sliderRef = useRef(null);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(false);

    const scrollLeft = () => {
        sliderRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    };

    const scrollRight = () => {
        sliderRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    };

    const updateScrollButtons = () => {
        if (sliderRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
            setShowLeftButton(scrollLeft > 0);
            setShowRightButton(scrollWidth - clientWidth - scrollLeft > 0);
        }
    };

    useEffect(() => {
        const slider = sliderRef.current;
        updateScrollButtons();
        window.addEventListener('resize', updateScrollButtons);
        slider.addEventListener('scroll', updateScrollButtons);
        return () => {
            window.removeEventListener('resize', updateScrollButtons);
            slider.removeEventListener('scroll', updateScrollButtons);
        };
    }, []);

    return (
        <div className="ps-0 mt-4 position-relative movie-slider-component">
            <h1 className="text-uppercase">Trailers</h1>

            {showLeftButton && (
                <button className="scroll-button scroll-button-left h-100" onClick={scrollLeft}>
                    <span className="fas fa-chevron-left" />
                </button>
            )}

            <div ref={sliderRef} className="movie-slider d-flex justify-content-start mt-2 overflow-x-auto h-100">
                {versions.map(order => (
                    <div key={order._id} className="text-decoration-none text-white position-relative">
                        <TrailerThumb movie={order.movie} order={order}/>
                    </div>
                ))}
            </div>

            {showRightButton && (
                <button className="scroll-button scroll-button-right h-100 pointer" onClick={scrollRight}>
                    <span className="fas fa-chevron-right" />
                </button>
            )}
        </div>
    );
};

export default MovieSlider;
