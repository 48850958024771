import React, {Fragment, useEffect, useState} from 'react';
import SearchBar from '../SearchBar';
import {Button} from 'reactstrap';
import {NavLink} from 'react-router-dom';

import ListFilter from '../ListFilter';
import './PageHeader.scss'

const PageHeader = ({title, searchBar = {}, filters,  addButtonAction, otherActions })=>{
    const [shrink, setShrink] = useState(false)
    const [shrinkStyle, setShrinkStyle] = useState('');

    const {searchValue, handleSearch} = searchBar;

    const titleSingle = title.slice(-1) === 's'? title.slice(0,-1) : title;

    const page = document.querySelector('.page')

    page?.addEventListener('scroll', ()=>{
        if (page.scrollTop > 150){
            setShrink(true);
        } else if (page.scrollTop < 200 ){
            setShrink(false);
        }

    });

    useEffect(() => {
        setShrinkStyle(shrink ? 'shrink' : '');
    }, [shrink]);

    return (
        <Fragment>
            {shrink && <div className="dummy" />}
            <header className={`header mb-3  ${shrinkStyle}`}>
                <div className='d-flex title'>
                    <h1 className='text-capitalize'>{title}</h1>
                    {addButtonAction?.toggle && (
                        <Button className='align-content-center' color="link" onClick={addButtonAction.toggle}>
                            <span className="fas fa-plus me-2"/>Add new {addButtonAction?.label? addButtonAction?.label:titleSingle}
                        </Button>
                    )}
                    {addButtonAction?.navigateTo && (
                        <NavLink className="btn btn-link align-content-center" to={addButtonAction.navigateTo}>
                            <span className="fas fa-plus me-2"/>Add new {addButtonAction?.label? addButtonAction?.label:titleSingle}
                        </NavLink>
                    )}
                </div>

                <SearchBar initialValue={searchValue} onChange={handleSearch} />
                {filters && (
                    <ListFilter
                        isShrink={shrink}
                        selectedOptions={filters.filterBy}
                        name="organization"
                        filterOptions={filters.options}
                    />
                )}

                {otherActions && otherActions(shrink)}
            </header>
    </Fragment>
)}
export default PageHeader;