import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchNotifications = createAsyncThunk('notifications/fetchAll', async () => {
    const response = await api.get('/notifications');
    return response.data;
});

export const markNotificationAsRead = createAsyncThunk('notifications/markAsRead', async (notificationId) => {
    await api.put(`/notifications/${notificationId}/read`);
    return { notificationId };
});

const NotificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        list: [],
        unreadCount: 0,
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = action.payload;
                state.unreadCount = action.payload.filter(({ read }) => !read).length;
                state.error = null;
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(markNotificationAsRead.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(markNotificationAsRead.fulfilled, (state, action) => {
                state.status = 'success';
                const index = state.list.findIndex(notif => notif._id === action.payload.notificationId);
                if (index !== -1) {
                    state.list[index].read = true;
                    state.unreadCount -= 1;
                }
            })
            .addCase(markNotificationAsRead.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to mark notification as read';
            });
    },
});

export default NotificationsSlice.reducer;
