export const formatDate = (date) => date ? new Date(date).toLocaleDateString() : '';
export const getFiterDatesLabel = (filterDates, label = 'Dates') => {
    const { from, to } = filterDates;

    if (from && to) {
        return `${formatDate(from)} - ${formatDate(to)}`;
    } else if (from) {
        return `${formatDate(from)} - today`;
    } else if (to) {
        return `Until ${formatDate(to)}`;
    }

    return label;
};