import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchOrders = createAsyncThunk('orders/fetchMany', async (params) => {
    const response = await api.get('/orders', { params })
    return response.data;
});

export const fetchHomepageOrders = createAsyncThunk('orders/fetchHomepage', async () => {
    const response = await api.get('/homefeed/bac/orders');
    return response.data;
});

export const fetchHomepageOrganizations = createAsyncThunk('organizations/fetchHomepage', async () => {
    const response = await api.get('/homefeed/bac/organizations');
    return response.data;
});

export const fetchOrder = createAsyncThunk('orders/fetch', async (orderId) => {
    const response = await api.get(`/orders/${orderId}`);
    return response.data;
});

export const orderPurchase = createAsyncThunk('orders/purchase', async ({ orderId, body }) => {
    const response = await api.post(`/orders/${orderId}/purchase`, body);
    return response.data;
});

export const updateOrder = createAsyncThunk('orders/update', async ({ orderId, body }) => {
    const response = await api.put(`/orders/${orderId}`, body);
    return response.data;
});

export const requestApproval = createAsyncThunk('orders/requestApprove', async ({ orderId, operation, body }) => {
    const response = await api.put(`/orders/${orderId}/${operation}`, body);
    return response.data;
});

export const getIngestJobs = createAsyncThunk('orders/getIngestJobs', async () => {
    const response = await api.get('/orders/ingest-status');
    return response.data;
});

const orderSlice = createSlice({
    name: 'orders',
    initialState: {
        list: [],
        homepageList: null,
        homepageOrganizations: null,
        currentOrder: null,
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
        statusIngest: 'idle',
        ingestJobs: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, (state) => {
                state.status = 'loading';
                state.list = [];
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = action.payload;
                state.error = null;
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
                state.list = [];
            })

            .addCase(fetchHomepageOrders.pending, (state) => {
                state.status = 'loading';
                state.homepageList = null;
            })
            .addCase(fetchHomepageOrders.fulfilled, (state, action) => {
                state.status = 'success';
                state.homepageList = action.payload;
                state.error = null;
            })
            .addCase(fetchHomepageOrders.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
                state.homepageList = null;
            })

            .addCase(fetchHomepageOrganizations.pending, (state) => {
                state.status = 'loading';
                state.homepageOrganizations = null;
            })
            .addCase(fetchHomepageOrganizations.fulfilled, (state, action) => {
                state.status = 'success';
                state.homepageOrganizations = action.payload;
                state.error = null;
            })
            .addCase(fetchHomepageOrganizations.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
                state.homepageOrganizations = null;
            })

            .addCase(fetchOrder.pending, (state) => {
                state.status = 'loading';
                state.currentOrder = null;
            })
            .addCase(fetchOrder.fulfilled, (state, action) => {
                state.status = 'success';
                state.currentOrder = action.payload;
                state.error = null;
            })
            .addCase(fetchOrder.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(orderPurchase.pending, (state) => {
                state.status = 'loading';
                state.currentOrder = null;
            })
            .addCase(orderPurchase.fulfilled, (state, action) => {
                state.status = 'success';
                state.currentOrder = action.payload;
                state.error = null;
            })
            .addCase(orderPurchase.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(updateOrder.pending, (state) => {
                state.status = 'loading';
                state.currentOrder = null;
            })
            .addCase(updateOrder.fulfilled, (state, action) => {
                state.status = 'success';
                state.currentOrder = action.payload;
                state.error = null;
            })
            .addCase(updateOrder.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(requestApproval.pending, (state) => {
                state.status = 'loading';
                state.currentOrder = null;
            })
            .addCase(requestApproval.fulfilled, (state, action) => {
                state.status = 'success';
                state.currentOrder = action.payload;
                state.error = null;
            })
            .addCase(requestApproval.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(getIngestJobs.pending, (state) => {
                state.statusIngest = 'loading';
                state.currentOrder = null;
            })
            .addCase(getIngestJobs.fulfilled, (state, action) => {
                state.statusIngest = 'success';
                state.ingestJobs = action.payload?.ingestJobs || [];
                state.error = null;
            })
            .addCase(getIngestJobs.rejected, (state, action) => {
                state.statusIngest = 'failed';
                state.error = action.error.message || 'An error occurred';
            })
        ;
    },
});

export default orderSlice.reducer;
