import React, {useState} from 'react';
import {Form, Label, Input, FormGroup, Button} from 'reactstrap';
import {NavLink, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
import {postDistributor} from '../../slices/distributors.slice';

const CreateDistributor = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ name: '' });

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const promise = dispatch(postDistributor(formData)).unwrap();
        toast.promise(promise, {
            pending: `Creating ${formData.name}`,
            success: `${formData.name} created successfully`,
            error: 'An error ocorred while saving the distributor',
        });
        const response = await promise;
        navigate(`/distributors/${response._id}`);
    };

    return (
        <div className="distributors-view p-3 min-vh-100 mb-5">
            <NavLink to="/distributors" className="text-decoration-none">
                <span className="fas fa-chevron-left me-2"/> Distributors
            </NavLink>

            <h1 className="mt-2 mb-5">Create new Distributor</h1>

            <Form onSubmit={handleSubmit} className="bg-broken p-3 border-start border-primary">
                <FormGroup>
                    <Label for="name">Distributor Name</Label>
                    <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Distributor name Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </FormGroup>

                <Button
                    color="primary"
                    outline
                    type="submit"
                    className="float-end"
                >
                    Save & Configure
                    <span className="fas fa-chevron-right ms-2" />
                </Button>

                <div className="clearfix" />
            </Form>
        </div>
    );
};

export default CreateDistributor;
