import React, {useEffect, useState} from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input} from 'reactstrap';
import ImageUpload from './ImageUpload';
import BadgeSelect from './BadgeSelect';
import {imgUrl} from '../utils/api-request';

const EditModal = ({isOpen, toggle, dataType, label, value, onSave, mediaType, list}) => {
    const [editedValue, setEditedValue] = useState();

    const handleSave = (e) => {
        e.preventDefault();
        onSave(editedValue);
    };

    const toggleArray = value => {
        setEditedValue(prev => prev.includes(value) ? prev.filter(v => v !== value) : [...prev, value]);
    };

    useEffect(() => {
        setEditedValue(value);
    }, [dataType, value]);

    const renderInput = () => {
        switch (dataType) {
            case 'textarea':
                return <Input
                    type="textarea"
                    value={editedValue}
                    onChange={e => setEditedValue(e.target.value)}
                />;

            case 'number':
                return <Input
                    type="number"
                    value={editedValue}
                    onChange={e => setEditedValue(e.target.value)}
                />

            case 'image':
                return (
                    <div className="clearfix">
                        <ImageUpload
                            mediaType={mediaType || label}
                            onSuccess={onSave}
                            startImageUrl={imgUrl(value)}
                        />
                    </div>
                );

            case 'array':
                return <BadgeSelect
                    defaultList={list}
                    onChange={toggleArray}
                    values={editedValue}
                />

            default:
                return <Input
                    type="text"
                    value={editedValue}
                    onChange={e => setEditedValue(e.target.value)}
                />;
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <Form onSubmit={handleSave}>
                <ModalHeader toggle={toggle}>Edit {label}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="editField" className="text-capitalize">{label}</Label>
                        {renderInput()}
                    </FormGroup>
                </ModalBody>
                {dataType !== 'image' && (
                    <ModalFooter>
                        <Button color="primary" outline onClick={toggle}>Cancel</Button>
                        <Button type="submit" color="primary">Save</Button>
                    </ModalFooter>
                )}
            </Form>
        </Modal>
    );
};

export default EditModal;
