import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, NavbarToggler } from 'reactstrap';

const NavbarComponent = ({ toggle }) => {
    return (
        <Navbar color="dark" light expand="xl" fixed="top" className="d-xl-none">
            <NavbarToggler onClick={toggle} children={<span className="fa fa-bars text-primary" />} />
            <NavLink to={'/'} className="mx-auto navbar-brand">
                <img src="/logo.svg" alt="BEL AIR Cinema" width={120}/>
            </NavLink>
            <span className="fas fa-bell text-primary" />
        </Navbar>
    );
};

export default NavbarComponent;
