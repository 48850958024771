import React, { useState, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import {getLanguageByCode} from '../../../utils/languageCodes';
import DatePicker from 'react-datepicker';
import {useDispatch, useSelector} from 'react-redux';
import {formatCredits, computeOrderPrice} from '../../../utils/credits';
import {orderPurchase, requestApproval} from '../../../slices/orders.slice';
import {getPlayingMovie, getPlayStatus, loadMovie, playMovie, preparePlayback} from '../../../slices/play-movie.slice';
import {toast} from 'react-toastify';

const ActionButton = ({ order, version }) => {
    const dispatch = useDispatch();
    const [modal, setModal] = useState({ isOpen: false, action: '' });
    const [screeningDate, setScreeningDate] = useState(null);
    const { user } = useSelector(state => state.auth);
    const [downpayment] = useState(250);
    const [buttonIsLoading, setButtonIsLoading] = useState(false);

    const purchaseAmount = computeOrderPrice(version.conditionalPrice, 'number')
    let amountToSubtract = ( order?.statusWorkflow === 'movie-on-request') ? downpayment  : purchaseAmount

    if (!order) return null;

    const { statusWorkflow, orderType } = order;

    const toggleModal = () => setModal((prev) => ({ ...prev, isOpen: !prev.isOpen }));

    const handleClick = (action) => async () => {
        if (action === 'PREPARE_PLAYBACK') {
            setButtonIsLoading(true);
            const dispatchParams = { orderId: order._id, movieId: order.movie._id };
            await dispatch(preparePlayback(dispatchParams)).unwrap();
            await dispatch(loadMovie(dispatchParams)).unwrap();
            await dispatch(getPlayStatus(dispatchParams)).unwrap();
            await dispatch(playMovie(dispatchParams)).unwrap();
            dispatch(getPlayingMovie());
            setButtonIsLoading(false);
            return;
        }

        setModal({ isOpen: true, action });
    };

    const renderButton = (color, icon, label, handler, outline = true, disabled = false) => (
        <Button color={color} outline={outline} onClick={handler} disabled={disabled || buttonIsLoading}>
            {buttonIsLoading ? (
                <span className='fas fa-spinner fa-spin me-3' />
            ) : (
                <span className={`fas ${icon} me-3`}/>
            )}
            {label}
        </Button>
    );

    const addHoursToDate = (date, hours) => {
        const newDate = new Date(date).getTime() + (hours * 3600 *1000);
        return new Date(newDate)
    };

    let addedTime
    const computeDate = ()=>{
        addedTime = 0
        return addHoursToDate(new Date(), addedTime);

    }

    const handleOrder = async () => {
        const body = {
            downpayment,
            screeningDate,
        };

        const promise = dispatch(orderPurchase({ orderId: order._id, body })).unwrap();
        toast.promise(promise, {
            pending: 'Processing order',
            success: 'Order processed successfully',
            error: 'Error creating the order'
        });
        await promise;
        setModal({ isOpen: false, action: '' });
    };

    const handleApproval = (operation)=>{
        const body = {
            status: operation === 'approve'? 'movie-delivery-pending' : 'movie-on-request',
            statusWorkflow: operation === 'approve'? 'movie-delivery-pending' : 'movie-on-request',
        }

        if (operation === 'approve') {
            body.orderType = 'order'
        }

        const promise = dispatch(requestApproval({ orderId: order._id, operation, body })).unwrap();

        toast.promise(promise, {
            pending: `Updating order`,
            success: `order updated successfully`,
            error: `Error updating order`,
        });

        setModal({ isOpen: false, action: '' });
    }
    const formatTime = screeningDate => screeningDate.toLocaleDateString([], {hour: '2-digit', minute: '2-digit', hour12: true}).split(',')[1]

    const modalData = {
        ORDER: {
            title: order.movie.title,
            content: (<Fragment>
                <div className="bg-body-tertiary bg-opacity-10 p-3 border-start border-primary border-3">
                    <div className="mb-2">
                        <span className="text-primary text-uppercase">{order.movie.title}</span> | {order.version.name}
                    </div>

                    {order.version.assetDetails && (
                        <Fragment>
                            {order.version.assetDetails?.audioLanguage?.length > 0 && (
                                <Fragment>
                                    <span className="fas fa-language me-2"/>
                                    {order.version.assetDetails?.audioLanguage.map(code => getLanguageByCode(code)).join(', ')}
                                </Fragment>
                            )}

                            {order.version.assetDetails?.subtitlesLanguage?.length > 0 && (
                                <Fragment>
                                    <span className="fas fa-closed-captioning ms-3 me-2"/>
                                    {order.version.assetDetails?.subtitlesLanguage.map(code => getLanguageByCode(code)).join(', ')}
                                </Fragment>
                            )}

                            <span className="fas fa-video-camera ms-3 me-2"/>
                            {order.version.assetDetails?.resolution} | {order.version.assetDetails?.ratio?.toUpperCase()} | {order.version.assetDetails?.dimensions}

                            <span className="fas fa-volume-off ms-3 me-2"/>
                            {order.version.assetDetails?.audioConfig}
                        </Fragment>
                    )}
                </div>

                {(user.parentOrganization.creditAmount - downpayment) < 0 ? (
                    <p className="text-danger lead mt-3 text-center">
                        <span className="fas fa-warning me-3" />
                        Not enough credits. You need to buy more credits.
                    </p>
                ) : (<Fragment >
                    <h5 className="text-expand text-uppercase mt-4">Screening date & time</h5>
                    <div className="bg-body-tertiary bg-opacity-10 p-3 border-start border-primary border-3 ">
                        <Row >
                            <Col className='d-flex gap-3 mb-3'>

                                <DatePicker
                                    className="date-field"
                                    showIcon
                                    withPortal
                                    fixedHeight
                                    minDate={computeDate(order)}
                                    selected={screeningDate}
                                    onChange={setScreeningDate}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="date"
                                    monthsShown={2}
                                    toggleCalendarOnIconClick
                                    />
                                <DatePicker
                                    className="time-field"
                                    showIcon
                                    withPortal
                                    selected={screeningDate}
                                    onChange={setScreeningDate}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="hh:mm aa"
                                    placeholderText="time"
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            {addedTime !== 0 && <Col>
                                <span className="fas fa-circle-info me-2"/>
                                The screening date must be at least <strong>{`${addedTime} hours`}</strong> from now.
                            </Col>}
                            {/* // TODO: remove this <Col> (its here just for testing) */}<Col> <span className="fas fa-circle-info me-2"/>All dates in the future are allowed (only for testing!)  </Col>
                        </Row>
                    </div>

                    <h5 className="text-expand text-uppercase mt-4">Key validity</h5>

                    <div className="bg-body-tertiary bg-opacity-10 p-3 border-start border-primary border-3
                d-flex justify-content-start align-items-center gap-5">
                        {screeningDate ? (
                            <Fragment>
                                <Row>
                                    <Col className='me-4 ms-2 '>
                                        <p className=" small text-uppercase text-nowrap  ">
                                            <span className="fas fa-clock me-2 text-primary"/>
                                            Hire period
                                        </p>
                                        <p className="lead mb-0">{`${order.version.defaultHirePeriod} ${order.version.hirePeriodType}`} </p>

                                    </Col>
                                    <Col className='me-4 ms-2'>
                                        <p className=" small text-uppercase text-nowrap  ">
                                            <span className="fas fa-calendar-day me-2 text-primary"/>
                                            Valid from
                                        </p>

                                            <p className="lead mb-0">{screeningDate.toLocaleDateString()}</p>
                                            <p className=" ">{` at: ${formatTime(screeningDate)}`}</p>


                                    </Col>
                                    <Col className='sm d-none d-md-block'> <span className="text-primary fas fa-arrow-right me-4 mt-4 pt-3" /></Col>
                                    <Col className='me-4 ms-2'>
                                        <p className=" small text-uppercase text-nowrap  ">
                                            <span className="fas fa-calendar-check me-2 text-primary"/>
                                            Valid until
                                        </p>
                                        <p className="lead mb-0">
                                            {addHoursToDate(screeningDate, order.version.hirePeriod|| 0).toLocaleDateString()}
                                        </p>
                                        <p className=" ">{` at: ${formatTime(screeningDate)}`}</p>
                                    </Col>
                                </Row>
                            </Fragment>
                        ) : (
                            <div><span className="fas fa-calendar me-2"/> Pick a screening date to view key validity
                            </div>
                        )}
                    </div>
                </Fragment>)}

            </Fragment>),
            footer: (
                <div className="d-flex justify-content-between align-items-baseline w-100 gap-4">
                    <div className="credits d-flex justify-content-start gap-3 flex-grow-1">
                        <div className="text-start">
                            <div className="text-white text-opacity-50 text-uppercase">
                                Current credits
                            </div>
                            <div className="text-uppercase">{( order.statusWorkflow === 'movie-on-request')?'Payment' :'Purchase Amount' }</div>
                            <div className="text-uppercase text-primary">Remaining credits</div>
                        </div>
                        <div className="text-end">
                            <div className="text-white text-opacity-50">
                                <span className="fas fa-fw me-2"/>
                                {formatCredits(user.parentOrganization.creditAmount)}
                            </div>

                            <div>
                                <span className="fas fa-minus fa-fw me-2 text-danger"/>
                                <span className="text-end">{formatCredits(amountToSubtract)}</span>

                            </div>

                            <div className="text-primary">
                                <span className="fas fa-fw me-2"/>
                                <span
                                    className="fw-bold text-end">{formatCredits(user.parentOrganization.creditAmount - amountToSubtract)}</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button
                            color="primary" outline
                            onClick={handleOrder}
                            disabled={(user.parentOrganization.creditAmount - downpayment) < 0}
                        >Order now</Button>
                    </div>
                </div>
            ),
        },
        ACCEPT: {
            title: 'Accept proposal',
            content: (<Fragment>
                <div className="border-start border-primary bg-broken my-3 p-3">
                    <p className="text-primary">Your request was accepted under the following conditions</p>
                        <span className="fas fa-file me-2 text-primary"/>
                        <strong>Screening Terms</strong>
                    <p>{order.screeningTerms}</p>

                    {order.bacCorrection !== 0 && (<>
                            <div className=' row my-2 w-100'>
                                <p className="col  text-nowrap  ">
                                    <span className="fas fa-calendar-check me-2 text-primary"/>
                                    <strong>Screening date: </strong>
                                </p>
                                    <div className='col'>{` ${new Date(order?.bacCorrection?.screeningDateCorrection).toLocaleDateString()} at ${new Date(order?.bacCorrection?.screeningDateCorrection).getHours()}:${new Date(order?.bacCorrection?.screeningDateCorrection).getMinutes()}h`}</div>
                            </div>
                            <div className=' row my-2 w-100'>
                                <p className="col  text-nowrap  ">
                                    <span className="fas fa-clock me-2 text-primary"/>
                                    <strong>Hire period: </strong>
                                </p>
                                    <div className='col'>{` ${order?.bacCorrection?.defaultHirePeriodCorrection} ${order?.bacCorrection?.hirePeriodTypeCorrection}`}</div>
                            </div>
                            <div className=' row my-2 w-100'>
                                <p className='col text-nowrap' >
                                    <span className="fas fa-coins me-2 text-primary "/>
                                    <strong  >Price correction:</strong>
                                </p>
                                <div className='col'>{`${formatCredits(order?.bacCorrection?.priceCorrection)} credits`}</div>
                            </div>
                            <div className=' row my-2 w-100'>
                                <p className='col text-nowrap' >
                                    <span className="fas fa-cash-register me-2 text-primary "/>
                                    <strong >Total:</strong>
                                </p>
                                <div className='col'>{`${formatCredits(Number(order?.bacCorrection?.priceCorrection) + computeOrderPrice(version.conditionalPrice, 'number')) } credits`}</div>

                            </div>
                        </>)}
                </div>
            </Fragment>),
            footer: (<Button color="primary" outline onClick={()=>{handleApproval('approve')}}>Accept</Button>),
        },
        DECLINE: {
            title: 'Decline proposal',
            content: (<Fragment>
                <div className="border-start border-primary bg-broken my-3 p-3">
                    <p className="text-primary">Your request was declined under the following conditions</p>
                        <span className="fas fa-file me-2 text-primary"/>
                        <strong>Screening Terms</strong>
                    <p>{order.screeningTerms}</p>

                    {order.bacCorrection !== 0 && (<>
                            <div className=' row my-2 w-100'>
                                <p className="col  text-nowrap  ">
                                    <span className="fas fa-calendar-check me-2 text-primary"/>
                                    <strong>Screening date: </strong>
                                </p>
                                    <div className='col'>{` ${new Date(order?.bacCorrection?.screeningDateCorrection).toLocaleDateString()} at ${new Date(order?.bacCorrection?.screeningDateCorrection).getHours()}:${new Date(order?.bacCorrection?.screeningDateCorrection).getMinutes()}h`}</div>
                            </div>
                            <div className=' row my-2 w-100'>
                                <p className="col  text-nowrap  ">
                                    <span className="fas fa-clock me-2 text-primary"/>
                                    <strong>Hire period: </strong>
                                </p>
                                    <div className='col'>{` ${order?.bacCorrection?.defaultHirePeriodCorrection} ${order?.bacCorrection?.hirePeriodTypeCorrection}`}</div>
                            </div>
                            <div className=' row my-2 w-100'>
                                <p className='col text-nowrap' >
                                    <span className="fas fa-coins me-2 text-primary "/>
                                    <strong  >Price correction:</strong>
                                </p>
                                <div className='col'>{`${formatCredits(order?.bacCorrection?.priceCorrection)} credits`}</div>
                            </div>
                            <div className=' row my-2 w-100'>
                                <p className='col text-nowrap' >
                                    <span className="fas fa-cash-register me-2 text-primary "/>
                                    <strong >Total:</strong>
                                </p>
                                <div className='col'>{`${formatCredits(Number(order?.bacCorrection?.priceCorrection) + computeOrderPrice(version.conditionalPrice, 'number')) } credits`}</div>

                            </div>
                        </>)}
                </div>
            </Fragment>),
            footer: (<Button color="danger" outline onClick={()=>{handleApproval('decline')}}>Decline</Button>),
        },
        REORDER: {
            title: `Re-order ${order.movie.title}`,
            content: (<Fragment>
                <p>Re-order details</p>
            </Fragment>),
            footer: (<Button color="primary" outline onClick={toggleModal}>Close</Button>),
        },
    };

    if (statusWorkflow === 'request-declined') {
        return renderButton('danger', 'fa-times-circle', 'Request declined', null, false, true);
    }

    return (
        <Fragment>
            {['to-order', 'request'].includes(orderType) && statusWorkflow === 'coming-soon' &&
                renderButton('light', 'fa-hourglass-1', 'Coming Soon', null, true, true)
            }

            {['to-order', 'request'].includes(orderType) && (statusWorkflow !== 'coming-soon') && statusWorkflow !== 'request-accepted' && statusWorkflow !== 'request-pending' &&
                renderButton('primary', 'fa-shopping-cart', 'ORDER NOW', handleClick('ORDER'))
            }

            {orderType === 'request' && statusWorkflow === 'request-accepted' &&
                <Fragment>
                    {renderButton('primary', 'fa-check', 'Accept', handleClick('ACCEPT'))}
                    <span className="px-3" />
                    {renderButton('danger', 'fa-times', 'Decline', handleClick('DECLINE'))}
                </Fragment>
            }

            {orderType === 'order' && ['ready-on-mediablock', 'finished-playback'].includes(statusWorkflow) &&
                renderButton('primary', 'fa-play', 'PLAY MOVIE', handleClick('PREPARE_PLAYBACK'))
            }

            {orderType === 'order' && order.purchasedAt && ['ready-to-play', 'movie-delivery-pending', 'key-delivery-pending'].includes(statusWorkflow) &&
                renderButton('light', 'fa-spinner fa-spin', 'Delivery pending', null, true, true)
            }

            {modal.isOpen && (
                <Modal isOpen={modal.isOpen} toggle={toggleModal} size="lg" fullscreen="lg" scrollable>
                    <ModalHeader toggle={toggleModal} tag="div">
                        <div className="text-expand text-uppercase mb-2">Create order</div>
                        <h3 className="text-uppercase text-primary">{modalData[modal.action].title}</h3>
                    </ModalHeader>
                    <ModalBody>{modalData[modal.action].content}</ModalBody>
                    <ModalFooter>{modalData[modal.action].footer}</ModalFooter>
                </Modal>
            )}
        </Fragment>
    );
};

export default ActionButton;
