import React, {Fragment, useEffect, useState} from 'react';
import {
    ListGroup,
    ListGroupItem,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {postNewVersion, deleteVersion, updateVersion} from '../../slices/movies.slice';

import VersionForm from './VersionForm';
import './VersionList.scss';
import { fetchOrganizations } from '../../slices/organizations.slice';
import {toast} from 'react-toastify';
import useSavePrompt from '../../utils/useSavePrompt';
import SavePrompt from '../../components/SavePrompt'

const emptyVersionData = {
    name: '',
    uuid: '',
    tier: ['mid', 'premium'],
    countries: [],
    organizations: [],
    price: '',
    conditionalPrice: [],
    contentRestrictions: null,
    releaseDate: '',
    isMovieOnRequest: false,
    assetDetails: null,
};

const VersionsList = ({ versions, movie, distributor = null }) => {
    const dispatch = useDispatch();
    const organizations = useSelector((state) => state.organizations.list);
    const token = useSelector(state => state.auth.token);

    const [expandedVersionId, setExpandedVersionId] = useState(null);
    const [deleteModal, setDeleteModal] = useState({ isOpen: false, version: null });
    const [organizationsModal, setOrganizationsModalOpen] = useState(false);
    const [countriesModal, setCountriesModalOpen] = useState(false);
    const [newVersionData, setNewVersionData] = useState({...emptyVersionData});
    const [originalData, setOriginaldata] = useState()

    const blocker = useSavePrompt( originalData, newVersionData )

    const toggleCountriesModal = () => setCountriesModalOpen(!countriesModal);

    const toggleOrganizationsModal = () =>  setOrganizationsModalOpen(!organizationsModal)

    const toggleExpand = (versionId) => {
        if (expandedVersionId === versionId) {
            setExpandedVersionId(null);
        } else {
            setExpandedVersionId(versionId);
            if (versions && versions.length) {
                const versionData = versions.find(({_id}) => _id === versionId) || emptyVersionData;
                setNewVersionData({...versionData})
                setOriginaldata({...versionData})
            }
        }
    };

    const resetNewVersionData = () => {
        setNewVersionData({ ...emptyVersionData });
    };

    const handleCancel = () => {
        resetNewVersionData();
        toggleExpand(null);
    };

    const handleEdit = (e) => {
        e.preventDefault();
        const promise = dispatch(updateVersion({
            movieId: movie._id,
            versionId: newVersionData._id,
            body: newVersionData,
        }));
        toast.promise(promise, {
            pending: 'Updating version',
            success: 'Version updated successfully',
            error: 'Error updating version',
        });
    };

    const handleDelete = async (versionId) => {
        try {
            const promise = dispatch(deleteVersion({ movieId: movie._id, versionId })).unwrap();
            toast.promise(promise, {
                pending: 'Deleting version',
                success: 'Version deleted successfully',
                error: 'Error deleting version',
            });
            await promise;
            setDeleteModal({ isOpen: false, version: null });
        } catch (error) {
            console.error(error); // TODO: Handle error
        }
    };

    const showDeleteModal = (e, version) => {
        e.stopPropagation();
        setDeleteModal({ isOpen: true, version });
    };

    const handleModalClose = () => {
        setDeleteModal({ isOpen: false, version: null });
    };

    const handleChange = (e) => {
        if (e.length){
            let bulkData = {}
            e.forEach(element => {
                const { name, value } = element.target;
                bulkData = {...bulkData,[name]: value  }
            });
            setNewVersionData({...newVersionData,...bulkData});
        }else {
            const { name, value } = e.target;
            setNewVersionData({ ...newVersionData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const promise = dispatch(postNewVersion({ movieId: movie._id, body: newVersionData })).unwrap();
            toast.promise(promise, {
                pending: 'Creating version',
                success: 'Version created successfully',
                error: 'Error creating version',
            });
            await promise;
            setExpandedVersionId(null);
            toggleExpand(null);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(()=>{
        dispatch(fetchOrganizations(token));
    },[dispatch, token])

    return (
        <Fragment>
            <ListGroup className="version-list-container mb-5">
                <ListGroupItem
                    className={`d-flex flex-column align-items-start bg-broken text-white my-2 add-new-version${expandedVersionId === 'new-version' ? ' expanded': ''}`}
                >
                    <div className="d-flex align-items-center w-100 header" onClick={() => toggleExpand('new-version')}>
                        <span className="fas fa-plus me-3 fa-2x" />
                        <span className="pt-1">Create new version</span>
                    </div>

                    {expandedVersionId === 'new-version' && (
                        <VersionForm
                            versionData={newVersionData}
                            handleSubmit={handleSubmit}
                            handleChange={handleChange}
                            handleCancel={handleCancel}
                            toggleCountriesModal={toggleCountriesModal}
                            countriesModal={countriesModal}
                            toggleOrganizationsModal={toggleOrganizationsModal}
                            organizationsModal={organizationsModal}
                            organizations={organizations}
                        />
                    )}
                </ListGroupItem>

                {versions?.map(version => (
                    <ListGroupItem
                        key={version._id}
                        className={`d-flex flex-column align-items-start bg-broken text-white my-2${expandedVersionId === version._id ? ' expanded' : ''}`}
                    >
                        <div className="d-flex justify-content-between align-items-center w-100 header"
                             onClick={() => toggleExpand(version._id)}>
                            <span>
                                <strong>{version.name}</strong>{' | '}
                                <span className="text-capitalize text-small">
                                    {version.tier === 'premium' ?
                                        <span className="fas fa-gem text-primary me-2"/> :
                                        <span className="fas fa-medal me-2"/>
                                    }
                                    {version.tier} tier
                                </span>
                            </span>
                            {/*<Button size="sm" color="primary" outline className="me-2" onClick={(e) => handleEdit(e, version)}>*/}
                            {/*    <span className="fas fa-pencil me-2"/> Edit*/}
                            {/*</Button>*/}
                            <Button size="sm" color="danger" outline onClick={(e) => showDeleteModal(e, version)}>
                                <span className="fas fa-trash"/> Remove
                            </Button>
                        </div>

                        {expandedVersionId === version._id && (
                            <div className="mt-2 w-100">
                                <VersionForm
                                    versionData={newVersionData}
                                    handleSubmit={handleEdit}
                                    handleChange={handleChange}
                                    handleCancel={handleCancel}
                                    toggleCountriesModal={toggleCountriesModal}
                                    countriesModal={countriesModal}
                                    toggleOrganizationsModal={toggleOrganizationsModal}
                                    organizationsModal={organizationsModal}
                                    organizations={organizations}
                                    distributor={distributor}
                                />
                            </div>
                        )}
                    </ListGroupItem>
                ))}
            </ListGroup>
            <SavePrompt blocker={blocker}/>
            {deleteModal.version && (
                <Modal isOpen={deleteModal.isOpen} toggle={handleModalClose}>
                    <ModalHeader toggle={handleModalClose}>Confirm Deletion</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this version?
                        <h3>{deleteModal.version.name}</h3>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={handleModalClose}>Cancel</Button>
                        <Button color="danger" onClick={() => handleDelete(deleteModal.version._id)}>Delete</Button>{' '}
                    </ModalFooter>
                </Modal>
            )}
        </Fragment>
    );
};

export default VersionsList;
