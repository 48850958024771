import React, {Fragment} from 'react';
import {Row, Col} from 'reactstrap';

import './MovieCard.scss'
import TierBadge from '../TierBadge';
import {imgUrl} from '../../utils/api-request';

const statusIcons = {
    draft: 'fa-pencil-alt',
    published: 'fa-check',
}

const MovieCard = ({ movie, hideStatus = false }) => {
    const footer = (
        <Fragment>
            <div className="order-credits m-2 d-inline-flex align-items-center">
                <span className="fas fa-fw fa-film me-2"/>
                {movie.versionsCount} version{movie.versionsCount > 1 && 's'}
            </div>
            <div className="order-credits m-2 d-inline-flex align-items-center">
                <span className="fas fa-fw fa-language me-2 "/>
                {/*{movie.versions[0].audioLanguage}*/} N/A
            </div>
            <div className="order-credits m-2 d-inline-flex align-items-center">
                <span className="fas fa-fw fa-closed-captioning me-2"/>
                {/*{movie.versions[0].subtitles}*/} N/A
            </div>
        </Fragment>
    );

    return (
        <Row className="movie-card-component py-3 mx-0 my-3 d-flex">
            <Col xs="auto">
                {movie.poster ? (
                    <img
                        className="logo-img"
                        width={75}
                        alt={movie.title}
                        src={imgUrl(movie.poster.imageUrl)}
                    />
                ) : ( // TODO: Handle placeholder image when there is no cover
                    <img
                        className="logo-img"
                        width={75}
                        alt={movie.title}
                        src={`https://picsum.photos/seed/${movie.title}/75/112`}
                    />

                )}
            </Col>
            <Col>
                <div>
                    <div className="title text-uppercase text-primary d-flex flex-column flex-lg-row">
                        {movie.title}
                        <div className=" flex-md-grow-1 ms-lg-2 ms-0">
                            <TierBadge tier={'premium-tier'}/>
                        </div>
                        {!hideStatus && (
                            <span className="status my-lg-0 ps-2 pe-3 my-2  ">
                                <span className={`fas fa-fw ${statusIcons[movie.status]} mx-2 pt-1 ${movie.status === 'published' ? 'text-success' : 'text-danger'}`}/>
                                {movie.status}
                            </span>
                        )}
                    </div>
                </div>
                <div className="d-none d-lg-flex mt-5">
                    {footer}
                </div>
            </Col>
            <div className="d-flex d-lg-none">
                {footer}
            </div>
        </Row>
    );
};

export default MovieCard;
