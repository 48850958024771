import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
    Badge,
    Button,
    Col,
    FormGroup, FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import {deleteUser, fetchUser, updateUser} from '../../slices/user.slice';
import {requestImpersonation} from '../../slices/auth.slice';
import Avatar from '../../components/Avatar';
import Loading from '../../components/Loading';
import PinInputKeyboard from '../../components/PinInputKeyboard';
import RoleBadge from '../../components/RoleBadge';
import EditModal from '../../components/EditModal';
import DeleteButton from '../../components/DeleteButton';
import {apiRequest, imgUrl} from '../../utils/api-request';
import './UserDetails.scss'
import UserNotificationsModal from './UserNoticationsModal';

const UserDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.users.user);
    const userStatus = useSelector(state => state.users.userStatus);
    const myUser = useSelector(state => state.auth.user);
    const token = useSelector(state => state.auth.token);
    const { userId } = useParams();
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [securityModal, setSecurityModal] = useState(false);
    const [definePin, setDefinePin] = useState(false);
    const [logoModal, setLogoModal] = useState({open: false, value: '' });
    const [notificationsModal, setNotificationsModal] = useState(false);

    const canImpersonate = () => {
        if (!['site-guest', 'site-user', 'site-admin'].includes(user.role)) {
            return false;
        }

        return ['root', 'bac-user', 'bac-admin'].includes(myUser?.role);
    };

    const toggleSecurityModal = () => {
        if (securityModal) setDefinePin(false);
        setSecurityModal(!securityModal);
    }

    const toggleNotificationsModal = () => {
        setNotificationsModal(prevState => !prevState);
    };

    const impsersonate = () => {
        localStorage.setItem('impersonatedUser', user._id);
        dispatch(requestImpersonation({ userId: user._id }));
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();
        try {
            const promise = apiRequest(`/users/${userId}`, 'PUT', { password });
            toast.promise(promise, {
                pending: 'Updating user',
                success: `${user.firstName} updated successfully`,
                error: 'Error updating user',
            });
            await promise;
            setPassword('');
            setNewPassword('');
            toggleSecurityModal();
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    const handleUpdatePin = async pinCode => {
        try {
            const promise = apiRequest(`/users/${userId}`, 'PUT', { pinCode });
            toast.promise(promise, {
                pending: 'Updating user',
                success: `${user.firstName} updated successfully`,
                error: 'Error updating user',
            });
            await promise;
            setDefinePin(false);
            toggleSecurityModal();
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    const handleUpdate = async ({ _id }) => {
        const userBody = {
            ...user,
            profilePic: _id,
        }
        const promise = dispatch(updateUser({ token, userBody, userId })).unwrap();
        toast.promise(promise, {
            pending: 'Updating profile picture...',
            success: 'Profile picture saved',
            error: 'Error updating profile picture',
        });
        await promise;
        dispatch(fetchUser({ token, userId: user._id }));
        setLogoModal(false);
    };

    const handleDelete = async () => {
        const promise = dispatch(deleteUser(user._id)).unwrap();
        toast.promise(promise, {
            pending: 'Deleting user',
            success: 'User deleted successfully',
            error: 'Error deleting user',
        });
        await promise;
        navigate('/users');
    };

    useEffect(() => {
        if (!myUser) return;
        const uid = userId === 'me' ? myUser._id : userId;
        dispatch(fetchUser({ token, userId: uid }));
    }, [token, dispatch, userId, myUser]);

    if (!user || !myUser || userStatus === 'loading') {
        return <Loading fullHeight />;
    }

    const bacView = ['bac-admin', 'bac-user', 'root'].includes(user?.role);

    return (
        <div className="user-view p-3 min-vh-100">
            <Row>
                <Col>
                    <NavLink to={`/users`} className="text-decoration-none">
                        <span className="fas fa-chevron-left me-2" /> Users
                    </NavLink>
                </Col>
            </Row>
            <Row className="header py-3 mx-0 my-4 border-start border-primary bg-broken">
                <Col className="pic-container" xs="auto">
                    <Avatar
                        imageUrl={user.profilePic?.imageUrl}
                        name={`${user.firstName} ${user.lastName}`}
                        size={100}
                        onEdit={() => setLogoModal({ open: !logoModal.open })}
                    />
                </Col>
                <Col>
                    <h1 className="name text-capitalize text-primary">
                        {user.firstName} {user.lastName}
                        {canImpersonate() && user._id !== myUser._id && (
                            <Button
                                size="sm"
                                color="primary"
                                className="ms-3"
                                outline
                                onClick={impsersonate}
                            >
                                <span className="fas fa-user-astronaut me-2"/>
                                Impersonate
                            </Button>
                        )}
                    </h1>

                    {user.email && (
                        <span className="email d-block d-md-inline-block me-3 mt-3 ps-2 pe-3 text-secondary ">
                            <span className="fas fa-fw fa-envelope mx-2 pt-1"/> {user.email}
                        </span>
                    )}

                    <RoleBadge role={user.role} />
                </Col>
            </Row>

            <div className="details">
                <NavLink to={`/users/${userId}/edit`} className="text-white text-decoration-none">
                    <div
                        className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3">
                        <span
                            className="fas fa-user-pen bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                        <div>
                            <p className="mb-0 text-uppercase text-primary">
                                User Details
                                <span className="fas fa-pencil ms-2 edit-icon"/>
                            </p>
                            <p className="mb-0">
                                Edit user details
                            </p>

                            {user.allowedContentRestrictions.map(rate => (
                                <Badge color="transparent" className="border border-white me-2" key={rate}>
                                    {rate}+
                                </Badge>
                            ))}
                        </div>
                    </div>
                </NavLink>

                <div className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3" onClick={toggleSecurityModal}>
                    <span
                        className="fas fa-lock bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                    <div>
                        <p className="mb-0 text-uppercase text-primary">
                            Security & Authentication
                            <span className="fas fa-pencil ms-2 edit-icon"/>
                        </p>
                        Password: <span
                        className="fas fa-check-circle text-success ms-2 me-3 pe-3 border-end border-primary"/>
                        PIN Code: <span
                        className={`fas ${user.hasPinCode ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'} ms-2 me-4`}/>
                    </div>
                </div>

                {user.parentOrganization && bacView && (
                    <NavLink to={`/organizations/${user.parentOrganization._id}`}
                             className="text-white text-decoration-none">
                        <div
                            className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3">
                            <span
                                className="fas fa-building bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                            <div>
                                <p className="mb-0 text-uppercase text-primary">
                                    Organization
                                    <span className="fas fa-eye ms-2 edit-icon"/>
                                </p>
                                <p className="mb-0">{user.parentOrganization.name}</p>
                            </div>
                        </div>
                    </NavLink>
                )}

                <div className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3"
                    onClick={toggleNotificationsModal}>
                    <span
                        className="fas fa-bell bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                    <div>
                        <p className="mb-0 text-uppercase text-primary">
                            Notifications
                            <span className="fas fa-pencil ms-2 edit-icon"/>
                        </p>
                        Edit Notification preferences
                    </div>
                </div>

                <NavLink to={`/users/${userId}/sessions`} className="text-white text-decoration-none">
                    <div
                        className="d-flex position-relative border-start border-primary bg-broken details-card align-items-center mb-3">
                        <span
                            className="fas fa-user-check bg-broken position-absolute align-items-center d-flex justify-content-center text-primary"/>
                        <div>
                            <p className="mb-0 text-uppercase text-primary">
                                User Sessions
                                <span className="fas fa-eye ms-2 edit-icon"/>
                            </p>
                            <p className="mb-0">View all user's login sessions</p>
                        </div>
                    </div>
                </NavLink>

                {myUser._id !== user._id && (
                    <div className="float-end">
                        <DeleteButton
                            entityId={user._id}
                            entityLabel="user"
                            title={`${user.firstName} ${user.lastName}`}
                            onClick={handleDelete}
                            typeToConfirm
                        />
                    </div>
                )}
            </div>

            <Modal isOpen={securityModal} toggle={toggleSecurityModal}>
                <ModalHeader toggle={toggleSecurityModal}>Security & Authentication</ModalHeader>
                <ModalBody>
                    {definePin ? (
                        <Fragment>
                            <h5 className="mb-3 text-primary text-center text-uppercase">
                                <span className="fas fa-lock me-2"/>
                                new PIN
                            </h5>

                            <div
                                className="pincode-container d-flex justify-content-center align-items-center flex-column">
                                <PinInputKeyboard onSubmit={handleUpdatePin} onError={console.error}/>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                        <FormGroup>
                                <Label for="password">New password</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    value={password}
                                    valid={password.length >= 8 && password === newPassword}
                                    invalid={password.length > 0 && !(password.length >= 8 && password === newPassword)}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <FormText color="primary">(8 characters minimum)</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Confirm new password</Label>
                                <Input
                                    type="password"
                                    name="passwordConfirm"
                                    value={newPassword}
                                    valid={password.length >= 8 && password === newPassword}
                                    invalid={newPassword.length > 0 && !(password.length >= 8 && password === newPassword)}
                                    onChange={e => setNewPassword(e.target.value)}
                                />
                            </FormGroup>

                        </Fragment>
                   )}
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                    <Button color="primary" outline onClick={() => setDefinePin(!definePin)}>
                        <span className={`fas fa-${definePin ? 'lock' : 'calculator'} me-2`} />
                        {definePin ? 'Password' : 'Pin'}
                    </Button>
                    {!definePin && (
                        <Button
                            color="primary"
                            onClick={handleUpdateUser}
                            disabled={password !== newPassword}
                        >Save password</Button>
                    )}
                </ModalFooter>
            </Modal>

            <EditModal
                isOpen={logoModal.open}
                toggle={() => setLogoModal({ open: !logoModal.open })}
                dataType="image"
                label="User Avatar"
                value={imgUrl(user.profilePic?.imageUrl)}
                onSave={handleUpdate}
                mediaType="user-avatar"
            />

            <UserNotificationsModal userRole={user?.role} toggle={toggleNotificationsModal} isOpen={notificationsModal} />
        </div>
    );
};

export default UserDetails;

