import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchCrewMembers = createAsyncThunk('crew/fetch', async () => {
    const response = await api.get('/crew-members');
    return response.data;
});

export const postCrewMember = createAsyncThunk('crew/post', async (crewMemberData) => {
    const response = await api.post('/crew-members', crewMemberData);
    return response.data;
});

export const updateCrewMember = createAsyncThunk('crew/update', async (crewMemberData) => {
    const response = await api.put(`/crew-members/${crewMemberData._id}`, crewMemberData);
    return response.data;
});

const crewSlice = createSlice({
    name: 'crewMembers',
    initialState: {
        list: [],
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCrewMembers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCrewMembers.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = action.payload;
                state.error = null;
            })
            .addCase(fetchCrewMembers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(postCrewMember.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postCrewMember.fulfilled, (state, action) => {
                state.status = 'success';
                const index = state.list.findIndex(member => member._id === action.payload._id);
                if (index !== -1) {
                    state.list[index] = action.payload;
                } else {
                    state.list.push(action.payload);
                }
            })
            .addCase(postCrewMember.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message || 'Error posting crew member';
            });;
    },
});

export default crewSlice.reducer;
