import React, {useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import SearchBar from '../SearchBar';

import './CrewCard.scss';
import {imgUrl} from '../../utils/api-request';

const AddCrewCard = ({
    defaultMembers,
    type = 'crew',
    alreadyAddedIds,
    onAdd,
}) => {
    const [modal, setModal] = useState(false);
    const [crewMembers, setCrewMembers] = useState(defaultMembers);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [searchValue, setSearchInput] = useState('');

    const filterCrew = e => {
        setSearchInput(e.target.value);
        setCrewMembers(defaultMembers.filter(({name}) => name.toLowerCase().includes(e.target.value.toLowerCase())));
    };

    const handleSelectedMember = member => () => {
        if (selectedMembers.includes(member)) {
            setSelectedMembers(selectedMembers.filter(item => item !== member));
        } else {
            setSelectedMembers([...selectedMembers, member]);
        }
    };

    const toggle = () => {
        setModal(!modal);
        setCrewMembers(defaultMembers);
    }

    const handleAddMembers = e => {
        e.preventDefault();
        onAdd(selectedMembers);
        setSelectedMembers([]);
        toggle();
    };

    return (
        <div className={`${type}-card-component position-relative d-flex align-items-center add`} onClick={toggle}>
            <span className="fas fa-plus fa-3x mx-3 add-icon"></span> Add {type} member

            <Modal isOpen={modal} toggle={toggle} size="lg" scrollable>
                <ModalHeader toggle={toggle} tag="h1" className="p-0">
                    Add {type} member(s)
                </ModalHeader>
                <div className="search-box pb-2">
                    <SearchBar initialValue={searchValue} onChange={filterCrew} />
                </div>
                <ModalBody className="p-0">
                    <div className="crew-members-container d-flex flex-wrap gap-3 mt-3 justify-content-start pb-3">
                        {crewMembers.map(crewMember => (
                            <div
                                className={`crew-member d-inline-flex align-items-center position-relative${selectedMembers.includes(crewMember) ? ' selected' : ''}`}
                                key={crewMember._id}
                                onClick={handleSelectedMember(crewMember)}
                            >
                                {(selectedMembers.includes(crewMember) || alreadyAddedIds.includes(crewMember._id)) && (
                                    <span className="selected fas fa-check-circle text-primary position-absolute"/>
                                )}
                                {crewMember.photo.url ? (<img src={imgUrl(crewMember.photo.url)} alt={crewMember.name} width={70} />): (
                                    <span className="fas fa-chain-broken text-primary mx-4" />
                                )}
                                <span className="ms-3">{crewMember.name}</span>
                            </div>
                        ))}
                        {crewMembers.length === 0 && (
                            <div>
                                No matches. <br/>
                                <small className="text-primary">
                                    <span className="fas fa-question-circle me-2" /> Tip: You can import persons from The Movie DB from the <NavLink to="/crew-manager">Crew manager</NavLink>.
                                </small>
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={Boolean(!selectedMembers.length)} onClick={handleAddMembers}>
                        Add {selectedMembers.length} selected
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );

};

export default AddCrewCard;
