import './MovieThumb.scss';
import React, {useState} from 'react';
import {Button, Card, CardFooter, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {imgUrl} from '../../utils/api-request';
import YoutubeTrailer from '../YoutubeTrailer';
import { getPlayingMovie, loadMovie, playMovie } from '../../slices/play-movie.slice';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';

const TrailerThumb = ({ width = '162px', movie, animatedFooter = false, order }) => {
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [playYoutube, setPlayYoutube] = useState(false);
    const [playDCP, setPlayDCP] = useState(false);
    const { status } = useSelector(state => state.playMovie);

    const orderId = order._id;
    const movieId = movie._id;

    const toggle = () => {
        setModal(!modal);
        setPlayYoutube(false);
        setPlayDCP(false);
    };

    const handlePlay = async () => {
        try {
            await dispatch(loadMovie({ orderId, movieId })).unwrap();
            await dispatch(playMovie({ orderId, movieId })).unwrap();
            await dispatch(getPlayingMovie()).unwrap();
            setModal(false);
            setPlayDCP(false);
        } catch (e) {
            toast.error('Error starting playback');
        }
    };

    return (
        <Card className={`movie-card pointer ${animatedFooter ? 'animatedFooter' : ''}`} style={{ width }} onClick={toggle}>
            <img
                className="object-fit-cover w-100"
                alt={movie.title}
                src={imgUrl(movie.poster?.imageUrl)}
            />

            <CardFooter className="d-flex p-0">
                <div className="text-primary">
                    <span className="fas fa-play-circle me-1" /> Play trailer
                </div>
            </CardFooter>

            <Modal isOpen={modal} toggle={toggle} fullscreen={playYoutube}>
                <ModalHeader toggle={toggle} className="text-primary">
                    {movie.title}
                </ModalHeader>
                <ModalBody>
                    {(!playYoutube && !playDCP) && (
                        <div className="d-flex gap-3 justify-content-between trailer-card-actions">
                            {movie.youtubeTrailerId && (
                                <div
                                    className="p-4 bg-danger bg-opacity-10 text-center d-flex flex-column gap-3 w-100 pointer"
                                    onClick={() => setPlayYoutube(!playYoutube)}
                                >
                                    <span className="fa-brands fa-youtube fa-3x text-danger"/>
                                    Play Youtube Trailer<br/>
                                    <small>(on this device)</small>
                                </div>
                            )}

                            <div
                                className="p-4 bg-primary bg-opacity-10 text-center d-flex flex-column gap-3 w-100 pointer"
                                onClick={() => setPlayDCP(true)}
                            >
                                <span className="fas fa-film fa-3x text-primary"/>
                                Play Trailer on Projector
                            </div>
                        </div>
                    )}

                    {playYoutube && (
                        <div className="h-100">
                            <YoutubeTrailer canEdit={false} videoId={movie.youtubeTrailerId} />
                        </div>
                    )}

                    {playDCP && (
                        <div>
                            <h3>Projector controller</h3>
                            <p className="text-expand text-uppercase">
                                Watching version {order.version.name}
                            </p>

                            <hr/>

                            <Button color="primary" outline block onClick={handlePlay} disabled={status === 'loading'}>
                                <span className={`fas me-2 fa-${status === 'loading' ? 'spinner fa-spin' : 'play'}`} />
                                Start playback
                            </Button>

                            <div className="pt-3 small text-center">
                                <span className="fas fa-info-circle me-2" />
                                It may take a few seconds to start playing
                            </div>
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </Card>
    );
};
export default TrailerThumb;
