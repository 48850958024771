import React from 'react';

const Loading = ({ fullHeight = false }) => {
    return (
        <div className={`d-flex align-items-center justify-content-center pt-5 ${fullHeight ? 'min-vh-100' : ''}`}>
            <span className="text-primary opacity-50 text-center">
                <span className="fas fa-spinner fa-spin fa-3x  my-5"/><br/>
                <h1 className="pulse-effect">Loading...</h1>
            </span>
        </div>
    )
}

export default Loading;
