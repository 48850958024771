import axios from 'axios';
import store from './slices';
import { refreshToken, requirePinCode } from './slices/auth.slice';

const rolesWithoutPin = ['root', 'bac-user', 'bac-admin'];

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem(config.refreshToken ? 'refreshToken' : 'token');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use(response => response, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        if (rolesWithoutPin.includes(localStorage.getItem('role'))) {
            await store.dispatch(refreshToken()).unwrap();
            const newAccessToken = localStorage.getItem('token');
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return api(originalRequest);
        } else {
            store.dispatch(requirePinCode());
        }
    }
    return Promise.reject(error);
});

export default api;
