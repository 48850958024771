import store from '../slices';
import {refreshToken, requirePinCode} from '../slices/auth.slice';

const rolesWithoutPin = ['root', 'bac-user', 'bac-admin'];

export const apiRequest = async (endpoint, method = 'GET', body = null) => {
    const isFormData = body instanceof FormData;

    const options = {
        method,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            ...(!isFormData && { 'Content-Type': 'application/json' }),
        },
        body: method === 'POST' || method === 'PUT' ? (isFormData ? body : JSON.stringify(body)) : null,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, options);

    if (response.status === 401 && response.headers.get('Token-Expired') === 'true') {
        if (rolesWithoutPin.includes(localStorage.getItem('role'))) {
            await store.dispatch(refreshToken()).unwrap();
            return apiRequest(endpoint, method, body);
        } else {
            store.dispatch(requirePinCode());
            return;
        }
    }

    if (response.ok) {
        return response.json();
    } else {
        console.error('API request error:', response.statusText);
        throw new Error(response.statusText);
    }
};

export const imgUrl = url => {
    if (!url) return null;
    return `${process.env.REACT_APP_MEDIA_URL}${url}`;
}
