import React, { useRef, useState, useEffect } from 'react';
import MovieThumb from '../MovieThumb/index.js';
import { NavLink } from 'react-router-dom';
import './movieSlider.scss';

const MovieSlider = ({ group, versions = [] }) => {
    const sliderRef = useRef(null);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(false);

    const scrollLeft = () => {
        sliderRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    };

    const scrollRight = () => {
        sliderRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    };

    const updateScrollButtons = () => {
        if (sliderRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
            setShowLeftButton(scrollLeft > 0);
            setShowRightButton(scrollWidth - clientWidth - scrollLeft > 0);
        }
    };

    useEffect(() => {
        const slider = sliderRef.current;
        updateScrollButtons();
        window.addEventListener('resize', updateScrollButtons);
        slider.addEventListener('scroll', updateScrollButtons);
        return () => {
            window.removeEventListener('resize', updateScrollButtons);
            slider.removeEventListener('scroll', updateScrollButtons);
        };
    }, []);

    return (
        <div className="ps-0 mt-4 position-relative movie-slider-component">
            <h1 className="text-uppercase">{group}</h1>

            {showLeftButton && (
                <button className="scroll-button scroll-button-left h-100" onClick={scrollLeft}>
                    <span className="fas fa-chevron-left" />
                </button>
            )}

            <div ref={sliderRef} className="movie-slider d-flex justify-content-start mt-2 overflow-x-auto">
                {versions.map(({ _id: orderId, movie, version, status }) => (
                    <NavLink
                        key={orderId}
                        to={`/movie-version/${version?._id}/order/${orderId}`}
                        className="text-decoration-none text-white position-relative"
                    >
                        {version.assetDetails?.contentKind === 'trailer' && (
                            <div className="position-absolute start-0 top-0 z-3 bg-primary text-black px-2 opacity-50 small">
                                <span className="fas fa-file-video" /> TLR
                            </div>
                        )}
                        <MovieThumb movie={movie} version={version} orderStatus={status} />
                    </NavLink>
                ))}
            </div>

            {showRightButton && (
                <button className="scroll-button scroll-button-right h-100" onClick={scrollRight}>
                    <span className="fas fa-chevron-right" />
                </button>
            )}
        </div>
    );
};

export default MovieSlider;
