import React from 'react';
import {Badge} from 'reactstrap';

export const roles = [
    { role: 'root', label: 'Root', color: 'danger', icon: 'fa-user-secret', rank: 0 },
    { role: 'bac-admin', label: 'BAC Admin', color: 'success', icon: 'fa-user-cog', rank: 1 },
    { role: 'bac-user', label: 'BAC User', color: 'success', icon: 'fa-user-tie', rank: 2 },
    { role: 'site-admin', label: 'Organization Admin', color: 'primary', icon: 'fa-user-shield', rank: 3 },
    { role: 'site-user', label: 'Organization User', color: 'primary', icon: 'fa-user', rank: 4},
    { role: 'site-guest', label: 'Organization Guest', color: 'primary', icon: 'fa-user-clock', rank: 5},
];

const RoleBadge = ({ role }) => {
    const { label, color, icon } = roles.find(({ role: rowRole }) => role === rowRole);
    let textColor = 'text-white';
    if (role.startsWith('site-')) {
        textColor = 'text-black';
    }

    return (
        <Badge color={color} className={textColor} style={{ borderRadius: 0 }}>
            <span className={`fas me-2 ${icon}`}/>
            {label}
        </Badge>
    );
};

export default RoleBadge;
