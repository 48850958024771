import './Notifications.scss';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchNotifications, markNotificationAsRead} from '../../slices/notifications.slice';
import Avatar from '../../components/Avatar';

const Notifications = () => {
    const dispatch = useDispatch();
    const { list: notifications, unreadCount } = useSelector(state => state.notifications);

    useEffect(() => {
        dispatch(fetchNotifications());
    }, [dispatch]);

    const handleRead = notificationId => {
        dispatch(markNotificationAsRead(notificationId));
    };

    const NotificationCard = ({ notificationId, message, createdAt, avatar, read, markAsRead }) => (
        <div className={`notifications-card d-flex position-relative border-start bg-broken 
                border-${read ? 'secondary' : 'primary'} align-items-center mb-3 gap-3`}
             onClick={() => !read && markAsRead(notificationId)}
        >
            <span className={`fas fa-bell bg-broken position-absolute align-items-center d-flex justify-content-center
                border border-${read ? 'secondary' : 'primary'}
                text-${read ? 'secondary' : 'primary'}`} />
            {!read && (
                <span className="fas fa-circle unread position-absolute end-0 text-primary p-2"/>
            )}
            <Avatar imageUrl={avatar} size={50} icon="NT" />
            <div>
                <p className={`mb-0 pe-4 text-uppercase text-primary ${!read ? 'fw-bold' : ''}`}>{message}</p>
                <p className="small mb-0">{new Date(createdAt).toLocaleString()}</p>
            </div>
        </div>
    );

    return (
        <div className="notifications-page-view p-3 min-vh-100 mb-5">
            <h1>Notifications {unreadCount ? `(${unreadCount} new)` : null}</h1>

            {notifications.map(({ _id, message, createdAt, avatar, read }) => (
                <NotificationCard
                    key={_id}
                    notificationId={_id}
                    read={read}
                    avatar={avatar}
                    message={message}
                    createdAt={new Date(createdAt)}
                    markAsRead={handleRead}
                />
            ))}
        </div>
    );
};

export default Notifications;
