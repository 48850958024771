import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import Select from 'react-select';
import {createOrganization} from '../../slices/organizations.slice';
import TimezonePicker from '../../components/TimezonePicker'
import CountrySelect from '../../components/CountrySelect';
import countries from '../Movies/countries.json';
import TierSwitch from '../../components/TierSwitch';
import ToggleSwitch from '../../components/ToggleSwitch';
import currencies, {getCurrencyInfo} from '../../utils/currencies';
import useSavePrompt from '../../utils/useSavePrompt';
import SavePrompt from '../../components/SavePrompt'

const CreateOrganization = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const currencyList = currencies.map(({ value, label }) => ({ value, label: `${value} - ${label}`}));

    const [formData, setFormData] = useState({
        name: '',
        creditAmount: 0,
        tier: 'mid',
        country: '',
        timezone: '',
        currency: '',
        isYacht: false,
    });
    const [originalData] = useState(formData)
    const [modalCountries, setModalCountries] = useState(false);

    const blocker = useSavePrompt(originalData, formData)

    const toggleModalCountries = () => setModalCountries(!modalCountries);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const promise = dispatch(createOrganization({
            token: auth.token,
            organizationBody: formData,
        })).unwrap();

        toast.promise(promise, {
            pending: 'Creating organization...',
            success: 'Organization created',
            error: 'Error creating the organization',
        });

        promise.then(({_id}) => {
            navigate(`/organizations/${_id}`);
        });
    };

    return (
        <div className="create-organization-view p-3 min-vh-100">
            <Row>
                <Col>
                    <NavLink to={`/organizations`} className="text-decoration-none">
                        <span className="fas fa-chevron-left me-2" /> Organizations
                    </NavLink>
                </Col>
            </Row>
            <h1>Create Organization</h1>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Organization Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="type">Country</Label>
                    <CountrySelect
                        countries={countries.regions}
                        onChange={value => handleChange({ target: { name: 'country', value } })}
                        multiple={false}
                        name="country"
                        value={formData.country}
                        placeholder="Select organization country"
                        modal={modalCountries}
                        toggle={toggleModalCountries}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="timezone">Timezone</Label>
                    <TimezonePicker
                        value={formData.timezone}
                        onChange={({value}) => handleChange({ target: { name: 'timezone', value } })}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="currency">Currency</Label>
                    <Select
                        options={currencyList}
                        value={getCurrencyInfo(formData.currency)}
                        classNamePrefix="timezone-select"
                        onChange={({value}) => handleChange({ target: { name: 'currency', value } })}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="creditAmount">Credit Amount</Label>
                    <Input
                        type="number"
                        name="creditAmount"
                        id="creditAmount"
                        placeholder="Credit Amount"
                        value={formData.creditAmount}
                        onChange={handleChange}
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="type">Organization Type</Label>
                    <br/>
                    <TierSwitch value={formData.tier} onChange={handleChange} propName="tier" />
                </FormGroup>

                <FormGroup>
                    <Label for="type">Is yacht?</Label>
                    <ToggleSwitch
                        isOn={formData.isYacht}
                        onChange={value => handleChange({ target: { name: 'isYacht', value }  })}
                    />
                </FormGroup>

                <Button color="primary" outline type="submit">
                    <span className="fas fa-save me-2" />
                    Create organization
                </Button>
                <SavePrompt blocker={blocker}/>
            </Form>
        </div>
    );
};

export default CreateOrganization;
