import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import OrganizationCard from '../../components/OrganizationCard';
import {useDispatch, useSelector} from 'react-redux';
import {fetchOrganizations} from '../../slices/organizations.slice';
import Loading from '../../components/Loading';
import PageHeader from '../../components/PageHeader';

const Organizations = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const organizations = useSelector((state) => state.organizations.list);
    const status = useSelector((state) => state.organizations.status);
    const error = useSelector((state) => state.organizations.error);
    const [searchValue, setSearchInput] = useState('');
    const [filteredValues, setFilteredValues] = useState(organizations);

    useEffect(() => {
        dispatch(fetchOrganizations(token));
    }, [token, dispatch]);

    const handleSearch = event => {
        event.preventDefault();
        setSearchInput(event.target.value);
        setFilteredValues(organizations.filter(({ name }) => name.toLowerCase().includes(event.target.value.toLowerCase())));
    };

    const showValues = filteredValues.length ? filteredValues : organizations

    return (
        <div className='organizations-view page px-4 py-3 d-flex flex-column vh-100 overflow-y-auto' >
            <PageHeader
                title='organizations'
                addButtonAction={{navigateTo: "/organizations/new"}}
                searchBar={{searchValue, handleSearch}}
                />
            <div className='contentContainer'>

                {status === 'loading' && <Loading length={3} />}

                {status === 'success' && showValues.map((organization) => (
                    <NavLink to={`/organizations/${organization._id}`} key={organization._id} className="text-decoration-none text-white">
                        <OrganizationCard organization={organization} />
                    </NavLink>
                ))}

                {status === 'success' && organizations.length === 0 && (
                    <div className="text-center text-uppercase text-expand mt-5">NO ORGANIZATIONS YET</div>
                )}

                {status === 'failed' && (
                    <div className="alert alert-danger">{error}</div>
                )}

            </div>
        </div>
    );
};

export default Organizations;
