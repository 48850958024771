import React from 'react';
import {Progress} from 'reactstrap';

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    let i = Math.floor(Math.log(bytes) / Math.log(k));
    i = i > sizes.length - 1 ? sizes.length - 1 : i;
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}
function calculateDiskUsage(capacity, usedDiskSpace) {
    const usedSpace = formatBytes(usedDiskSpace);
    const percentageUsed = (usedDiskSpace / capacity) * 100;
    return { usedSpace, percentageUsed };
}

const DiskUsage = ({ capacity, usedDiskSpace }) => {
    const toBytes = (value)=>value * 1073741824
    capacity = toBytes(capacity)
    usedDiskSpace = toBytes(usedDiskSpace)

    const { usedSpace, percentageUsed } = calculateDiskUsage(capacity, usedDiskSpace);

    let color = 'success';
    if (percentageUsed > 80) color = 'warning';
    if (percentageUsed >= 90) color = 'danger';

    return (
        <div className="d-inline-flex align-items-center text-nowrap">
            <div className="me-2">{usedSpace}</div>
            <div className="pb-1">
                <Progress
                    color={color}
                    value={percentageUsed.toFixed(0)}
                    style={{minWidth: '150px'}}
                    className="me-2 position-relative">
                <span className="position-absolute w-100 top-0 start-0 text-center fw-bold text-black">
                    {percentageUsed ? `${percentageUsed.toFixed(0)}%` : '- N/A -'}
                </span>
                </Progress>
            </div>
            <div>of {formatBytes(capacity)}</div>
        </div>

    );
};

export default DiskUsage;
