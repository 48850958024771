import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    playMovie,
    pauseMovie,
    getPlayStatus,
    ejectMovie,
    getPlayingMovie,
    goToTimecode,
} from '../slices/play-movie.slice';
import { imgUrl } from '../utils/api-request';
import MovieControlButtons from './MovieControlButtons';

const MovieControl = ({ playing, movieId, orderId }) => {
    const dispatch = useDispatch();
    const { status, playStatus, showStatus } = useSelector((state) => state.playMovie);
    const [playingCursor, setPlayingCursor] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const progressBarRef = useRef(null);

    const refreshPlayStatus = useCallback(async () => {
        await dispatch(getPlayStatus({ movieId, orderId })).unwrap();
        if (showStatus?.spl_position !== undefined) {
            setPlayingCursor(showStatus.spl_position);
        }
    }, [dispatch, movieId, orderId, showStatus?.spl_position]);

    const handleSeek = async (newTime) => {
        const timecode = parseInt(newTime);
        await dispatch(goToTimecode({ movieId, orderId, timecode })).unwrap();
        await refreshPlayStatus();
    };

    const calculatePercentage = () => {
        if (showStatus) {
            return (playingCursor / showStatus.spl_duration) * 100;
        }
        return 0;
    };

    const formatTime = (seconds) => {
        if (isNaN(seconds) || seconds === null || seconds === undefined) {
            return '--:--:--';
        }

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const handleJump = async (direction, seconds) => {
        const promise = new Promise(async (resolve, reject) => {
            try {
                const { spl_position } = showStatus;
                const timecode = direction === 'backward' ? spl_position - seconds : spl_position + seconds;
                await dispatch(goToTimecode({ movieId, orderId, timecode })).unwrap();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
        await promise;
        return refreshPlayStatus();
    };

    const handleDragStart = () => {
        setIsDragging(true);
    };

    const handleDragMove = (event) => {
        if (!isDragging) return;

        const progressBar = progressBarRef.current;
        if (!progressBar) return;

        const rect = progressBar.getBoundingClientRect();
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;
        const offsetX = Math.max(0, Math.min(clientX - rect.left, rect.width));
        const newTime = (offsetX / rect.width) * showStatus.spl_duration;

        setPlayingCursor(newTime); // Update the UI during drag
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        if (playingCursor !== null) {
            handleSeek(playingCursor); // Seek when dragging ends
        }
    };

    useEffect(() => {
        dispatch(getPlayingMovie());
        dispatch(getPlayStatus({ movieId, orderId }));
    }, [dispatch, movieId, orderId]);

    useEffect(() => {
        if (playStatus === 'Play' && !isDragging) {
            const intervalId = setInterval(() => {
                setPlayingCursor((prevCursor) => prevCursor + 1);
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [playStatus, isDragging]);

    return (
        <div className="player-container">
            <div className="ps-5 pt-4 d-flex justify-content-start gap-3">
                <div>
                    {playing.movie.poster && (
                        <img
                            className="logo-img"
                            width={75}
                            alt={playing.movie.title}
                            src={imgUrl(playing.movie.poster.imageUrl)}
                        />
                    )}
                </div>

                <div className="flex-grow-1">
                    <h4 className="text-primary mt-3 mb-0">{playing.movie.title}</h4>

                    <MovieControlButtons
                        playingCursor={playingCursor}
                        status={status}
                        playStatus={playStatus}
                        handlePlay={() => dispatch(playMovie({movieId, orderId}))}
                        handlePause={() => dispatch(pauseMovie({movieId, orderId}))}
                        handleStop={() => dispatch(ejectMovie({movieId, orderId}))}
                        handleJump={handleJump}
                    />

                    {showStatus && (
                        <div className="pe-3">
                            <div className="d-flex justify-content-between small text-center">
                                <span>{formatTime(playingCursor)}</span>
                                <span>{formatTime(showStatus.spl_duration)}</span>
                            </div>
                            <div
                                ref={progressBarRef}
                                className="progress-bar-container"
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '7px',
                                    backgroundColor: '#555',
                                    cursor: 'pointer',
                                }}
                                onMouseDown={handleDragStart}
                                onMouseMove={handleDragMove}
                                onMouseUp={handleDragEnd}
                                onTouchStart={handleDragStart}
                                onTouchMove={handleDragMove}
                                onTouchEnd={handleDragEnd}
                            >
                                <div
                                    className="progress-bar bg-success striped-animated"
                                    style={{
                                        position: 'absolute',
                                        height: '100%',
                                        width: `${calculatePercentage()}%`,
                                    }}
                                />
                                <div
                                    className="progress-thumb"
                                    style={{
                                        position: 'absolute',
                                        left: `${calculatePercentage()}%`,
                                        transform: 'translateX(-50%) translateY(-30%)',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: 'white',
                                        border: '2px solid green',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MovieControl;
