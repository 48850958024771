import React, {useEffect, useState} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form } from 'reactstrap';
import ToggleSwitch from '../../components/ToggleSwitch';
import {updateUser} from '../../slices/user.slice';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';

const initialSettings = {
    HIRE_DURATION_UPDATED: { inApp: false, email: false },
    MOVIE_REQUESTED: { inApp: false, email: false },
    MOVIE_REQUEST_APPROVED: { inApp: false, email: false },
    MOVIE_REQUEST_DENIED: { inApp: false, email: false },
    TERMS_CHANGED: { inApp: false, email: false },
    NEW_TERMS_DECLINED: { inApp: false, email: false },
    MOVIE_PURCHASED: { inApp: false, email: false },
    MOVIE_READY_TO_PLAY: { inApp: false, email: false },
    MISSING_ASSETS_WARNING: { inApp: false, email: false },
    TOP_UP_REQUIRED: { inApp: false, email: false }
};

const roleToNotificationsAccess = {
    root: ['HIRE_DURATION_UPDATED', 'MOVIE_REQUESTED', 'MOVIE_REQUEST_APPROVED', 'MOVIE_REQUEST_DENIED', 'TERMS_CHANGED', 'NEW_TERMS_DECLINED', 'MOVIE_PURCHASED', 'MOVIE_READY_TO_PLAY', 'MISSING_ASSETS_WARNING', 'TOP_UP_REQUIRED'],
    'bac-admin': ['HIRE_DURATION_UPDATED', 'MOVIE_REQUESTED', 'MOVIE_REQUEST_APPROVED', 'MOVIE_REQUEST_DENIED', 'TERMS_CHANGED', 'NEW_TERMS_DECLINED', 'MOVIE_PURCHASED', 'MOVIE_READY_TO_PLAY'],
    'bac-user': ['HIRE_DURATION_UPDATED', 'MOVIE_REQUESTED', 'MOVIE_REQUEST_APPROVED', 'MOVIE_REQUEST_DENIED'],
    'site-admin': ['MOVIE_READY_TO_PLAY', 'MISSING_ASSETS_WARNING', 'TOP_UP_REQUIRED'],
    'site-user': ['MOVIE_READY_TO_PLAY'],
    'site-guest': []
};


const UserNotificationsModal = ({ isOpen, toggle, userRole }) => {
    const dispatch = useDispatch();
    const myUser = useSelector(state => state.auth.user);
    const [settings, setSettings] = useState(initialSettings);

    const handleChange = (type, method, value) => {
        setSettings(prev => ({
            ...prev,
            [type]: {
                ...prev[type],
                [method]: value
            }
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Settings:', settings);

        const promise = dispatch(updateUser({
            userBody: {
                ...myUser,
                settings: {
                    ...myUser.settings,
                    notifications: settings,
                },
            },
            userId: myUser._id,
        })).unwrap();

        toast.promise(promise, {
            pending: 'Updating notifications',
            success: 'User notifications updated successfully',
            error: 'Error updating user notifications',
        });

        toggle();
    };

    const filteredSettings = Object.entries(settings).filter(([type]) => roleToNotificationsAccess[userRole].includes(type));

    useEffect(() => {
        setSettings(myUser.settings.notifications);
    }, [dispatch, myUser]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader toggle={toggle}>User Notification Preferences</ModalHeader>
                <ModalBody>
                    <div className="notification-settings">
                        <div className="header mb-3 d-flex justify-content-end align-items-baseline text-center">
                            <span className="icon-placeholder" />
                            <span className="icon fas fa-bell text-primary me-2" style={{ width: '60px' }} />
                            <span className="icon fas fa-envelope text-primary me-2" style={{ width: '60px' }} />
                        </div>

                        {filteredSettings.length > 0 ? filteredSettings.map(([type, methods]) => (
                            <div key={type} className="setting-item d-flex justify-content-end align-items-center gap-3 mb-3">
                                <p className="flex-grow-1 setting-name m-0">{type.replace(/_/g, ' ')}</p>
                                {Object.entries(methods).map(([method, value]) => (
                                        <ToggleSwitch
                                            key={method}
                                            isOn={value}
                                            onChange={checked => handleChange(type, method, checked)}
                                        />
                                ))}
                            </div>
                        )) : <p>No notifications available for your role.</p>}
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={toggle}>Cancel</Button>
                    <Button type="submit" color="primary">Save</Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default UserNotificationsModal;
