import React, {Fragment, useState} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, Input, Label,
} from 'reactstrap';

const DeleteButton = ({ onClick, entityLabel, title, typeToConfirm, color = 'danger', outline = true, hideEntity = false }) => {
    const [modal, setModal] = useState(false);
    const [confirmInput, setConfirmInput] = useState('');

    const handleDelete = async (e) => {
        onClick(e);
        setModal(false);
    };

    const toggle = () => {
        setModal(!modal);
    };

    return (
        <Fragment>
            <Button
                color={color}
                outline={outline}
                onClick={toggle}
                className={color === 'link' ? 'text-decoration-none text-danger' : ''}
            >
                <span className="fas fa-trash me-2" />
                Delete {entityLabel}
            </Button>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Confirm Deletion</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete {entityLabel}?
                    {!hideEntity && (
                        <h3 className="my-2 text-center bg-danger bg-opacity-10 py-3">
                            {title}
                        </h3>
                    )}

                    {typeToConfirm && (
                        <div className="mt-4 mb-3">
                            <Label for="type-to-confirm">
                                Type <span className="font-monospace bg-primary bg-opacity-10 px-2 py-1">{title}</span> to confirm deletion.
                            </Label>
                            <Input type="text" value={confirmInput} onChange={e => setConfirmInput(e.target.value)} />
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" outline onClick={toggle}>Cancel</Button>
                    <Button
                        color="danger"
                        onClick={handleDelete}
                        disabled={typeToConfirm && title.toLowerCase() !== confirmInput.toLowerCase()}
                    >
                        <span className="fas fa-trash me-2" />
                        Delete
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default DeleteButton;
