import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchAuditLogs = createAsyncThunk('auditLogs/fetchMany', async (params) => {
    const response = await api.get('/audit-logs', { params });
    return response.data;
});

export const fetchAuditLogActions = createAsyncThunk('auditLogs/fetchActionsMany', async () => {
    const response = await api.get('/audit-logs/actions');
    return response.data;
});

const Slice = createSlice({
    name: 'auditLogs',
    initialState: {
        list: [],
        actions: {},
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAuditLogActions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAuditLogActions.fulfilled, (state, action) => {
                state.status = 'success';
                state.actions = action.payload;
                state.error = null;
            })
            .addCase(fetchAuditLogActions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(fetchAuditLogs.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAuditLogs.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = action.payload;
                state.error = null;
            })
            .addCase(fetchAuditLogs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            });
    },
});

export default Slice.reducer;
