import React, {useEffect, useState} from 'react';
import {
    AccordionBody,
        AccordionHeader,
        AccordionItem,
        Badge,
        Modal,
        ModalHeader,
        ModalBody,
        UncontrolledAccordion,
        } from 'reactstrap';

import DatePicker from 'react-datepicker';
import BadgeSelect from '../../components/BadgeSelect';
import './ListFilter.scss'
import { useNavigate, useLocation } from 'react-router-dom';

const ListFilter = ({filterOptions, sortOptions, onChangeFilter, onChangeSort, selectedOptions, isShrink }) => {
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState(selectedOptions||[])
    const [selectedTags, setSelectedTags] = useState({})

    const navigate = useNavigate();
    const location = useLocation();

    const toggleFiltersModal = () => setOpenFilters(!openFilters);

    const setQueryParams = (query)=>{
        navigate({
            pathname: location.pathname,
            search:  new URLSearchParams(query).toString(),
        });
    }

    useEffect(()=>{
        setFilters(selectedOptions||[])
        setSelectedTags(selectedOptions||{})
    },[selectedOptions])

    const handleChangeOption =(option, group)=>{
        const updatedFilters = {...filters}
        if (group === 'from' || group === 'until') {
            if (!option) {
                delete updatedFilters[group]
            }else {
                option = option?.toISOString().split('T')[0]
                updatedFilters[group] = [option]
            }

        }else if (updatedFilters[group]?.includes(option)){
            updatedFilters[group] = updatedFilters[group].filter(item => item !== option)
            if ( !updatedFilters[group].length){
                delete updatedFilters[group]
            }
        } else {
            updatedFilters[group]?.length ? updatedFilters[group] = [...updatedFilters[group], option] : updatedFilters[group] = [option]
        }
        setQueryParams(updatedFilters)
    }

    const removeFilters = (filter)=>{
        const filterKey =  Object.keys(filter)[0]
        const filterValue = filter[filterKey]
        let updatedFilters = {...filters}
        updatedFilters[filterKey] = updatedFilters[filterKey].filter(e=>e!==filterValue)
        if ( !updatedFilters[filterKey].length){
            delete updatedFilters[filterKey]
        }
        setQueryParams(updatedFilters)
    }

    const displayFilters = ()=>{
        let displayValue =[]

        Object.values(filters).forEach((filterGroup, i) =>{
            if (Object.keys(filters)[i] === 'from' || Object.keys(filters)[i] === 'until'){
                Object.values(filterGroup).forEach((date, index)=>{
                    if (date !== null) {
                        displayValue.push( <Badge key={`${index}-${date}`} color='primary' className='text-black mx-1' >
                            {[`${Object.keys(filters)[i]}: ${date.split('T')[0]}`]}
                            <span className="fas border-start border-black fa-xmark text-black ms-2 px-2" onClick={()=>removeFilters( {[Object.keys(filters)[i]]:date})} style={{'cursor':'pointer'}}/>
                        </Badge>)
                    }
                })
            }else {

                filterGroup?.forEach((filterValue, index)=>{
                    displayValue.push( <Badge key={`${index}-${filterValue}`} color='primary' className='text-black mx-1' >
                        {filterValue}
                        <span className="fas border-start border-black fa-xmark text-black ms-2 px-2" onClick={()=>removeFilters({[Object.keys(filters)[i]]: filterValue})} style={{'cursor':'pointer'}}/>
                    </Badge>)
                })
            }
        })
        return displayValue
    };
    const countBadge = ()=>{
        const filterAmount = displayFilters().length
        if (isShrink && (filterAmount > 0))
        return <Badge className='count-badge' pill color='primary'>{filterAmount >= 9 ? '9+': filterAmount}</Badge>
    }
    return (
    <div className="filter pt-3">
        <div className="d-flex ">
            <div className='text-nowrap me-2' style={{'cursor':'pointer'}} onClick={()=>toggleFiltersModal()}><span className="fas fa-sliders px-2 text-primary "/>{!isShrink? 'Filters': ''}</div>
            {!isShrink && <div className='badge-field'>{displayFilters()}</div>}
            {countBadge()}
        </div>
        <Modal className='d-flex justify-content-evenly' toggle={()=>toggleFiltersModal()} isOpen={openFilters} >
            <ModalHeader toggle={()=>toggleFiltersModal()}><span className="fas fa-sliders px-2 text-primary "/>Filters</ModalHeader>
            <ModalBody>
                <UncontrolledAccordion stayOpen>
                    {Object.values(filterOptions).map((filterBy, index)=>
                    <AccordionItem key={index} >
                        <AccordionHeader targetId={index.toString()}>{`${Object.keys(filterOptions)[index].toUpperCase()}: `}</AccordionHeader>
                        <AccordionBody accordionId={index.toString()}>
                            {Object.keys(filterOptions)[index] !== 'screening date' && <BadgeSelect
                                defaultList={filterBy.sort()}
                                onChange={(options)=>handleChangeOption(options, Object.keys(filterOptions)[index])}
                                values={selectedTags[Object.keys(filterOptions)[index]]||[]}
                                multiple={true}
                            />}
                            {Object.keys(filterOptions)[index] === 'screening date' && <div className='date-container'>
                                From: <DatePicker
                                    className="date-field"
                                    showIcon
                                    withPortal
                                    fixedHeight
                                    selected={ filters?.from?.[0] }
                                    onChange={(selectedDate) => handleChangeOption(selectedDate, 'from')}
                                    dateFormat="dd-MM-yyyy"
                                    monthsShown={2}
                                    isClearable
                                    />
                                Until: <DatePicker
                                    className="date-field"
                                    showIcon
                                    withPortal
                                    fixedHeight
                                    selected={filters?.until?.[0]}
                                    onChange={(selectedDate) => handleChangeOption(selectedDate, 'until')}
                                    dateFormat="dd-MM-yyyy"
                                    monthsShown={2}
                                    isClearable
                                    />
                            </div>
                            }
                        </AccordionBody>
                    </AccordionItem>
                    )}
                </UncontrolledAccordion>
            </ModalBody>
        </Modal>
    </div>
    );
};

export default ListFilter;
