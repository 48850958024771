import React from 'react';
import {Route,
        createBrowserRouter,
        createRoutesFromElements,
        RouterProvider
    } from 'react-router-dom';

import PR from './components/ProtectedRoute';
import Layout from './views/Layout';
import Homepage from './views/Homepage';
import Distributors from './views/Distributors';
import CreateDistributor from './views/Distributors/CreateDistributor';
import DistributorPage from './views/Distributors/DistributorPage';
import Orders from './views/Orders';
import OrderDetails from './views/Orders/OrderDetails';
import Movies from './views/Movies';
import MoviePageBAC from './views/Movies/MoviePage/MoviePageBAC';
import MovieVersionPage from './views/Movies/MoviePage/MovieVersionPage';
import CreateMovie from './views/Movies/CreateMovie';
import Organizations from './views/Organizations';
import CreateOrganization from './views/Organizations/CreateOrganization';
import EditOrganization from './views/Organizations/EditOrganization';
import OwnOrganization from './views/Organizations/OrganizationDetails';
import Users from './views/Users';
import Login from './views/Login';
import UserDetails from './views/Users/UserDetails';
import CreateUser from './views/Users/CreateUser';
import EditUser from './views/Users/EditUser';
import Equipment from './views/Equipment';
import CrewManager from './views/CrewManager';
import RolePermissions from './views/Users/RolePermissions';
import Transactions from './views/Organizations/Transactions';
import OrganizationContent from './views/Organizations/OrganizationContent';
import UserSessions from './views/Users/UserSessions';
import Playlists from './views/Playlists';
import PlaylistDetails from './views/Playlists/PlaylistDetails';
import Notifications from './views/Layout/Notifications';
import DefaultCuePlaylist from './views/Organizations/DefaultCuePlaylist';
import AuditLogs from './views/AuditLogs';

function App() {
    return (
        <RouterProvider router={
            createBrowserRouter(
                createRoutesFromElements(
                        [<Route element={<Layout />}>
                            <Route index element={<Homepage />} />
                            <Route path="/audit" element={<PR roles={['root', 'bac-user', 'bac-admin']}><AuditLogs /></PR>} />
                            <Route path="/notifications" element={<Notifications />} />
                            <Route path="/distributors" element={<PR roles={['root', 'bac-user', 'bac-admin']}><Distributors /></PR>} />
                            <Route path="/distributors/new" element={<PR roles={['root', 'bac-user', 'bac-admin']}><CreateDistributor /></PR>} />
                            <Route path="/distributors/:distributorId" element={<PR roles={['root', 'bac-user', 'bac-admin']}><DistributorPage /></PR>} />
                            <Route path="/orders" element={<Orders />} />

                            <Route path="/orders/:orderId" element={<OrderDetails />} />

                            <Route path="/movies" element={<PR roles={['root', 'bac-user', 'bac-admin']}><Movies /></PR>} />
                            <Route path="/movies/:movieId" element={<PR roles={['root', 'bac-user', 'bac-admin']}><MoviePageBAC /></PR>} />
                            <Route path="/movies/new" element={<PR roles={['root', 'bac-user', 'bac-admin']}><CreateMovie /></PR>} />
                            <Route path="/movie-version/:movieVersionId/order?/:orderId?" element={<MovieVersionPage />} />

                            <Route path="/playlists" element={<PR roles={['root', 'bac-user', 'bac-admin']}><Playlists /></PR>} />
                            <Route path="/playlists/:playlistId" element={<PR roles={['root', 'bac-user', 'bac-admin']}><PlaylistDetails /></PR>} />

                            <Route path="/organizations" element={<PR roles={['root', 'bac-user', 'bac-admin']}><Organizations /></PR>} />
                            <Route path="/organizations/new" element={<PR roles={['root', 'bac-user', 'bac-admin']}><CreateOrganization /></PR>} />
                            <Route path="/organizations/:organizationId" element={<OwnOrganization />} />
                            <Route path="/organizations/:organizationId/content" element={<OrganizationContent />} />
                            <Route path="/organizations/:organizationId/transactions" element={<Transactions />} />
                            <Route path="/organizations/:organizationId/edit" element={<EditOrganization />} />
                            <Route path="/organizations/:organizationId/cues" element={<PR roles={['root', 'bac-user', 'bac-admin']}><DefaultCuePlaylist /></PR>} />

                            <Route path="/crew-manager" element={<PR roles={['root', 'bac-user', 'bac-admin']}><CrewManager /></PR>} />

                            <Route path="/users" element={<Users />} />
                            <Route path="/users/:userId" element={<UserDetails />} />
                            <Route path="/users/new" element={<CreateUser />} />
                            <Route path="/users/:userId/edit" element={<EditUser />} />
                            <Route path="/users/:userId/sessions" element={<UserSessions />} />

                            <Route path="/equipment" element={<Equipment />} />
                            <Route path="/permissions" element={<PR roles={['root', 'bac-user', 'bac-admin']}><RolePermissions /></PR>} />
                        </Route>,
                        <Route path="/login" element={<Login />} />]

                )
            )
        }/>
    );
}

export default App;
