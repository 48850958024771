import React, { useEffect, useState } from 'react';
import {Button, Form,
        FormGroup,
        Label,
        Input,
        ButtonGroup,
    } from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {roles} from '../../components/RoleBadge';
import {fetchOrganizations} from '../../slices/organizations.slice';
import BadgeSelect from '../../components/BadgeSelect';
import useSavePrompt from '../../utils/useSavePrompt';
import SavePrompt from '../../components/SavePrompt'

const UserForm = ({ user, isEditing = false, onSubmit, parentOrganization }) => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const auth = useSelector((state) => state.auth);
    const organizations = useSelector(state => state.organizations.list);
    const organizationsStatus = useSelector((state) => state.organizations.status);
    const [originalData, setOriginaldata] = useState(user)
    const defaultContentRatingList = [6, 9, 12, 14, 16, 18];

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: isEditing ? undefined : '',
        role: 'user',
        parentOrganization,
        profilePic: '',
        allowedContentRestrictions: [],
    });

    const errors = {};
    if (auth?.createUserErrors?.length) {
        auth.createUserErrors.forEach(({ field, message }) => errors[field] = message);
    }

    useEffect(() => {
        if (isEditing && user) {
            setFormData({
                ...user,
                parentOrganization: user.parentOrganization ? user.parentOrganization._id : '',
            });
            setOriginaldata({
                ...user,
                parentOrganization: user.parentOrganization ? user.parentOrganization._id : '',
            });
        }
    }, [user, isEditing]);

    useEffect(() => {
        if (user && ['root', 'bac-user', 'bac-admin'].includes(user.role)) {
            dispatch(fetchOrganizations(token));
        }
    }, [token, dispatch, user]);

    const blocker = useSavePrompt(originalData, formData)

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleRoleChange = (role) => {
        setFormData({
            ...formData,
            role,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let result = {...formData};
        if (result.parentOrganization === '') {
            result.parentOrganization = null;
        }
        onSubmit(result);
    };

    const shouldDisplayRole = (role) => {
        if (formData.parentOrganization !== "") {
            return !role.startsWith('site-');
        } else {
            return role.startsWith('site-');
        }
    }

    const getRoleRank = (role) => roles.find(r => r.role === role)?.rank;

    const handleContentRating = (value) => {
        const index = defaultContentRatingList.indexOf(value);
        if (index !== -1) {
            const newValues = defaultContentRatingList.slice(0, index + 1);
            setFormData({
                ...formData,
                allowedContentRestrictions: newValues,
            });
        }
    };

    return user && (
        <Form onSubmit={handleSubmit}>
            <div className="border-start border-primary p-3 bg-broken">
                <div className="d-flex gap-3">
                    <FormGroup className="flex-grow-1">
                        <Label for="firstName">First Name</Label>
                        <Input
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup className="flex-grow-1">
                        <Label for="lastName">Last Name</Label>
                        <Input
                            type="text"
                            name="lastName"
                            id="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                </div>

                <div className="flex gap-3">
                    <FormGroup>
                        <Label for="username">Username</Label>
                        <Input
                            type="text"
                            name="username"
                            id="username"
                            invalid={!!errors.username}
                            placeholder="Username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                        {errors.username && <div className="invalid-feedback">{errors.username}</div>}
                    </FormGroup>

                    {!isEditing && (
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                    )}

                    <FormGroup className="flex-grow-1">
                        <Label for="email">Email (optional)</Label>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            invalid={!!errors.email}
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </FormGroup>
                </div>
            </div>

            <div className="border-start border-primary p-3 bg-broken mt-3">
                {['root', 'bac-user', 'bac-admin'].includes(user.role) && (
                    <FormGroup>
                        <Label for="parentOrganization">Parent Organization</Label>
                        <Input
                            type="select"
                            name="parentOrganization"
                            id="parentOrganization"
                            className="text-primary"
                            value={formData.parentOrganization}
                            onChange={handleChange}
                        >
                            <option value="">Select an Organization</option>
                            {organizationsStatus === 'success' && organizations.map(organization => (
                                <option key={organization._id} value={organization._id}>{organization.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                )}

                <FormGroup>
                    <Label className="d-block">Role</Label>
                    <ButtonGroup className="d-flex flex-wrap justify-content-between">
                        {roles.filter(r => getRoleRank(r.role) >= getRoleRank(user.role)).map(({role, label, color, icon}) => (
                            <Button
                                key={role}
                                className="m-2 col-md-3 py-3"
                                outline={formData.role !== role}
                                color={shouldDisplayRole(role) ? 'secondary' : color}
                                disabled={shouldDisplayRole(role)}
                                onClick={() => handleRoleChange(role)}
                            >
                                <span className={`fas me-2 ${icon}`} /> {label}
                            </Button>
                        ))}
                    </ButtonGroup>
                </FormGroup>
            </div>

            {['root', 'bac-admin', 'bac-user', 'site-admin'].includes(auth.user.role) && (
                <div className="border-start border-primary p-3 bg-broken mt-3">
                    <div className="d-flex gap-3">
                        <FormGroup>
                            <Label>Allowed content ratings</Label>
                            <BadgeSelect
                                defaultList={defaultContentRatingList}
                                onChange={handleContentRating}
                                values={formData.allowedContentRestrictions}
                                multiple
                                append="+"
                                contentRestrictions
                            />
                        </FormGroup>
                    </div>
                </div>
            )}

            <div className="d-flex justify-content-end my-3">
                <Button color="primary" type="submit">
                    <span className={`fas fa-user-${isEditing ? 'check' : 'plus'} me-2`} />
                    {isEditing ? 'Update user' : 'Create user'}
                </Button>
            </div>
            <SavePrompt blocker={blocker}/>
        </Form>
    );
};

export default UserForm;
