import React from 'react';
import './ToggleSwitch.scss';

const ToggleSwitch = ({ isOn, onChange }) => {
    const handleToggle = () => {
        onChange(!isOn);
    };

    return (
        <div className={`toggle-switch ${isOn ? 'on' : 'off'}`} onClick={handleToggle}>
            <div className="toggle-knob"></div>
        </div>
    );
};

export default ToggleSwitch;
