import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import UserCard from '../../components/UserCard';
import Loading from '../../components/Loading';
import {fetchUsers} from '../../slices/user.slice';
import {isAdmin} from '../../utils/admin';
import {nestedSearch} from '../../utils/nestedSearch';
import {useLocation} from 'react-router-dom';
import PageHeader from '../../components/PageHeader';

const Users = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const token = useSelector(state => state.auth.token);
    const users = useSelector((state) => state.users.list);
    const status = useSelector((state) => state.users.status);
    const error = useSelector((state) => state.users.error);
    const [searchValue, setSearchInput] = useState('');
    const [filteredValues, setFilteredValues] = useState([]);
    const [filterBy, setFilterBy] = useState({});
    const location = useLocation();

    useEffect(() => {
        dispatch(fetchUsers(token));
    }, [token, dispatch]);

    useEffect(() => {
        setFilteredValues(users);
    }, [users]);

    const handleSearch = event => {
        event.preventDefault();
        const query = event.target.value.toLowerCase()
        setSearchInput(query);
        const searchFields = ['firstName', 'lastName', 'role']
        let filteredUsers = nestedSearch(users, searchFields, query)
        setFilteredValues(filteredUsers);
    };

    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);
        const filters = {};

        for (const [key, value] of searchParams.entries()) {
            if (key === 'organization') {
                filters.organization = value.split(',');
            }
        }
        setFilterBy(filters);
    }, [location.search])

    useEffect(()=>{
        let filteredUsers = [...users]
        if (filterBy && filterBy?.organization?.length) {
            setFilteredValues(filteredUsers.filter(user => filterBy.organization.includes(user.parentOrganization?.name)))
        }else {
            setFilteredValues(filteredUsers);
        }
    }, [filterBy, users])

    const bacView = ['bac-admin', 'bac-user', 'root'].includes(user?.role);
    const filterOptions = {organization:[...new Set(users.map(user=>user.parentOrganization?.name))].filter(e=>e !== undefined)}
    const options = filterOptions

    return (
        <div className='users-view page px-4 py-3 d-flex flex-column vh-100 overflow-y-auto' >
            <PageHeader
                title='users'
                addButtonAction={isAdmin(user)?{navigateTo: "/users/new"}:null}
                searchBar={{searchValue, handleSearch}}
                filters={bacView ?{filterBy, options}:null}
                />
            <div className='contentContainer'>

            <span className="small">{`Showing: ${filteredValues.length} of ${users.length} users`}</span>
            {status === 'loading' && <Loading length={3} />}

            {status === 'success' && filteredValues.map(user =>(
                <NavLink to={`/users/${user._id}`} className="text-decoration-none" key={user._id}>
                    <UserCard user={user} />
                </NavLink>
            ))}

            {status === 'failed' && (
                <div className="alert alert-danger">{error}</div>
                )}
            </div>
        </div>
    );
};

export default Users;
