import React, {useState} from 'react';
import {Input, Button, Modal, ModalBody, ModalFooter, ModalHeader, ListGroup, ListGroupItem} from 'reactstrap';
import SearchBar from '../SearchBar';
import Avatar from '../../components/Avatar';

import './OrganizationSelect.scss';

const OrganizationSelect = ({
                            name,
                            value=[],
                            organizations,
                            addLogo,
                            onChange,
                            placeholder,
                            modal,
                            toggle,
                            multiple = true
}) => {
    const [inputValue, setInputValue] = useState(value);
    const [filteredValues, setFilteredValues] = useState(organizations);
    const [searchValue, setSearchInput] = useState('');

    const computeDisplayValue = () => {
        if (multiple) {
            let displayValue = inputValue.map(val => organizations.find((org) => org.name === val.name)?.name)
                .slice(0,3)
                .join(', ');

            if (inputValue.length > 3) {
                displayValue += ` and ${inputValue.length - 3} more`;
            }

            return displayValue;
        } else {
            return organizations.find(({name}) => name === inputValue)?.name || '';
        }
    }

    const handleSubmit = () => {
        onChange(inputValue.map(val => ({
                                name: organizations.find((org) => org.name === val.name)?.name,
                                _id: organizations.find((org) => org.name === val.name)?._id,
                            })))
        setFilteredValues(organizations);
        toggle();
    };

    const handleToggle = () => {
        setFilteredValues(organizations);
        toggle();
    }

    const handleChange = ({name, _id}) => event => {
        event.preventDefault();
        if (multiple) {
            const optionIndex = inputValue.findIndex(item => item.name === name);
            if (optionIndex !== -1) {
                const updatedInputValue = inputValue.filter((item, index) => index !== optionIndex);
                setInputValue(updatedInputValue);
            } else {
                setInputValue([...inputValue, {name, _id}]);
            }
        } else {
            setInputValue({name, _id});
            onChange({name, _id});
            toggle();
        }
    };

    const handleFilter = event => {
        event.preventDefault();
        setSearchInput(event.target.value);
        setFilteredValues(organizations.filter(({ name }) => name.toLowerCase().includes(event.target.value.toLowerCase())));
    };

    return  (
        <div>
            <div className="d-flex align-items-center">
                <Input
                    type="text"
                    value={computeDisplayValue()}
                    readOnly
                    placeholder={placeholder}
                    onClick={toggle}
                    />
            </div>

            {<Modal isOpen={modal} toggle={handleToggle} scrollable size="lg" className="country-select-modal">
                <ModalHeader toggle={handleToggle}>
                    {multiple ? `Select ${name}s` : `Select ${name}`}
                </ModalHeader>
                <ModalBody className="px-0">
                    <SearchBar onChange={handleFilter} initialValue={searchValue} />
                    <ListGroup >
                        {filteredValues.map(({name, logo, _id}) => (
                                <ListGroupItem
                                    onClick={handleChange({name, _id})}
                                    key={name}
                                    active={inputValue.filter(e=> e.name===name).length === 1}
                                    color={inputValue.filter(e=> e.name===name).length === 1 ? 'primary' : 'secondary'}
                                    style={{ cursor: 'pointer' }}
                                    className="position-relative"
                                    >
                                        <div className='d-flex'>
                                            {addLogo && <Avatar imageUrl={logo} name={name} size={35} />}
                                            <div className="ms-4 pt-1">{name}</div>
                                        </div>
                                        {inputValue.filter(e=> e.name===name).length === 1 && (
                                            <span className="fas fa-check-circle text-primary position-absolute" />
                                            )}
                                </ListGroupItem>

                        ))}
                    </ListGroup>
                </ModalBody>
                {multiple && (
                    <ModalFooter>
                        {inputValue.length} selected
                        <Button color="primary" onClick={handleSubmit}>
                        {`Select ${name}`}
                        </Button>
                    </ModalFooter>
                )}
            </Modal>}
        </div>
    );
};

export default OrganizationSelect;
