import React, {Fragment} from 'react';
import Select from 'react-select';
import moment from 'moment-timezone';

import './TimezonePicker.scss';

const timezoneOptions = moment.tz.names().map(tz => ({
    value: tz,
    label: `${tz} (UTC${moment.tz(tz).format('Z')})`
}));

const TimezonePicker = ({ onChange, value }) => {
    return (
        <Fragment>
            <Select
                options={timezoneOptions}
                onChange={onChange}
                value={timezoneOptions.find(option => option.value === value)}
                classNamePrefix="timezone-select"
            />
            <div className="text-primary mt-1 small">
                <span className="fas fa-info-circle me-2" />
                TIP: Type a city name to search.
            </div>
        </Fragment>
    );
};

export default TimezonePicker;
