import React, {useEffect, useState} from 'react';
import MovieCard from '../../components/MovieCard/index';
import MovieThumb from '../../components/MovieThumb/index';
import {NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fetchMovies} from '../../slices/movies.slice';
import Loading from '../../components/Loading';
import {Button} from 'reactstrap';
import {isAdmin} from '../../utils/admin';
import PageHeader from '../../components/PageHeader';

const Movies = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth.user);
    const movies = useSelector((state) => state.movies.list);
    const status = useSelector((state) => state.movies.status);
    const [searchValue, setSearchInput] = useState('');
    const [filteredValues, setFilteredValues] = useState(movies);
    const [displayList, setDisplayList] = useState(true);

    const handleSearch = event => {
        event.preventDefault();
        setSearchInput(event.target.value);
        setFilteredValues(movies.filter(({title}) => title.toLowerCase().includes(event.target.value.toLowerCase())));
    };

    const showValues = filteredValues.length ? filteredValues : movies

    useEffect(() => {
        dispatch(fetchMovies());
    }, [dispatch]);

    const displayListButton =()=> <Button color="link" onClick={() => setDisplayList(!displayList)} className="float-end">
                                <span className={`fas me-2 text-primary  pointer ${displayList ? 'fa-table-cells' : 'fa-rectangle-list'}`}/>
                              </Button>
    return (
        <div className='page px-4 py-3 d-flex flex-column vh-100 overflow-y-auto' >
            <PageHeader
                title='Movies'
                addButtonAction={isAdmin(user)?{navigateTo: "/movies/new"}: null}
                searchBar={{searchValue, handleSearch}}
                otherActions={displayListButton}
                />

            <div className='contentContainer'>
                {status === 'loading' ? <Loading/> : (
                    <div className={displayList ? '' : 'movie-container d-flex flex-wrap'}>
                        {showValues.map(movie => (
                            <NavLink to={`/movies/${movie._id}`} className={`text-decoration-none text-white${displayList ? '' : ' mx-3 mb-4'}`}
                                        key={movie._id}>
                                {displayList ? <MovieCard movie={movie} hideStatus={!isAdmin(user)} /> : <MovieThumb movie={movie} animatedFooter/>}
                            </NavLink>
                        ))}
                    </div>
                )}

                {status === 'success' && movies.length === 0 && (
                    <div>
                        No movies available yet.{' '}
                        <NavLink className="text-decoration-none" to="/movies/new">
                            <span className="fas fa-plus me-2"/>
                            Add a new movie now
                        </NavLink>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Movies;
