import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Badge,
    Button,
    ButtonGroup, Label,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import OrderCard from '../../components/OrderCard';
import Loading from '../../components/Loading';
import {nestedSearch} from '../../utils/nestedSearch';
import {fetchOrders} from '../../slices/orders.slice';
import {fetchOrganizations} from '../../slices/organizations.slice';
import PageHeader from '../../components/PageHeader';
import { isAdmin } from '../../utils/admin';
import {fetchMovies} from '../../slices/movies.slice';
import OrderStatusBadge, {statusMap} from '../../components/OrderStatusBadge';
import SearchBar from '../../components/SearchBar';
import {imgUrl} from '../../utils/api-request';
import api from '../../api';
import OrderExportModal from './OrderExportModal';
import {getFiterDatesLabel} from '../../utils/dates';


const Orders = () => {
    const dispatch = useDispatch();
    const { list: orders, status } = useSelector(state => state.orders);
    const { user } = useSelector(state => state.auth);
    const { list: movies } = useSelector(state => state.movies);
    const { list: organizations } = useSelector(state => state.organizations);
    const [filterBy, setFilterBy] = useState({
        status: [],
        movies: [],
        screeningDates: { from: null, to: null },
        organizations: [],
    });
    const [searchValue, setSearchInput] = useState('');
    const [searchMoviesValue, setSearchMoviesValue] = useState('');
    const [exportModal, setExportModal] = useState(false);
    const [filterModal, setFilterModal] = useState({ isOpen: false, filter: '' });

    const toggleFilterModal = filter => {
        setFilterModal({ isOpen: !filterModal.isOpen, filter });
    };

    const toggleFilter = (filterKey, value) => {
        setFilterBy((prevFilterBy) => {
            const isSelected = prevFilterBy[filterKey].includes(value);
            return {
                ...prevFilterBy,
                [filterKey]: isSelected
                    ? prevFilterBy[filterKey].filter((item) => item !== value)
                    : [...prevFilterBy[filterKey], value],
            };
        });
    };

    const handleScreeningDate = (period) => (date) => {
        setFilterBy((prevFilterBy) => ({
            ...prevFilterBy,
            screeningDates: {
                ...prevFilterBy.screeningDates,
                [period]: date,
            },
        }));
    };

    const handleSearch = e => {
        e.preventDefault();
        const { value } = e.target;
        const query = value.toLowerCase();
        setSearchInput(query);
        setSearchMoviesValue(value.toLowerCase());
    };

    const handleExport = async (fields, filetype = 'csv') => {
        try {
            const response = await api.get('/orders', {
                params: { ...filterBy, export: filetype, fields },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `orders.${filetype}`);
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const isLoading = ['idle', 'pending'].includes(status);

    const groupedOrders = useMemo(() => {
        const now = new Date();

        const groups = {
            pastOrders: [],
            currentOrders: [],
            notOrderedYet: [],
        };

        const searchFields = [
            'status',
            { path: 'movie', fields: ['title'] },
            { path: 'version', fields: ['name'] },
        ];

        const filteredList = searchMoviesValue
            ? nestedSearch([...orders], searchFields, searchMoviesValue)
            : orders;

        filteredList.forEach(order => {
            const screeningDate = order.bacCorrection?.screeningDateCorrection
                ? new Date(order.bacCorrection.screeningDateCorrection)
                : order.screeningDate
                    ? new Date(order.screeningDate)
                    : null;

            if (!screeningDate) {
                groups.notOrderedYet.push(order);
                return;
            }

            const hirePeriod = order.bacCorrection?.defaultHirePeriodCorrection || 0;
            const hirePeriodType = order.bacCorrection?.hirePeriodTypeCorrection || 'days';

            let expirationDate = new Date(screeningDate);
            if (hirePeriod > 0) {
                if (hirePeriodType === 'days') {
                    expirationDate.setDate(expirationDate.getDate() + hirePeriod);
                } else if (hirePeriodType === 'hours') {
                    expirationDate.setHours(expirationDate.getHours() + hirePeriod);
                }
            }

            if (now < expirationDate) {
                groups.currentOrders.push(order);
            } else {
                groups.pastOrders.push(order);
            }
        });

        return groups;
    }, [orders, searchMoviesValue]);

    const areFiltersActive = useMemo(() => {
        const { status, movies, screeningDates, organizations } = filterBy;

        return (
            status.length > 0 ||
            movies.length > 0 ||
            organizations.length > 0 ||
            screeningDates.from !== null ||
            screeningDates.to !== null
        );
    }, [filterBy]);

    const clearFilters = () => {
        setFilterBy({
            status: [],
            movies: [],
            screeningDates: { from: null, to: null },
            organizations: [],
        })
    };

    useEffect(() => {
        dispatch(fetchOrders(filterBy));
    }, [dispatch, filterBy]);

    useEffect(() => {
        dispatch(fetchOrganizations());
        dispatch(fetchMovies());
    }, [dispatch]);

    return (
        <div className="page px-4 py-3 d-flex flex-column vh-100 overflow-y-auto">
            <PageHeader
                title='orders'
                searchBar={{searchValue, handleSearch}}
            />

            <div className="contentContainer">
                <div className="d-flex justify-content-start align-items-center gap-3">
                    {areFiltersActive ? (
                        <Button color="link" className="text-decoration-none text-white px-0" onClick={clearFilters}>
                            <span className="fas fa-filter-circle-xmark me-1 text-primary"/> Clear all
                        </Button>
                    ) : (
                        <div>
                            <span className="fas fa-filter me-1 text-primary"/> Filters
                        </div>
                    )}

                    <ButtonGroup className="gap-2">
                        <Button color="primary" outline size="sm" className="rounded-3" onClick={() => toggleFilterModal('status')}>
                            {filterBy.status.length > 0 ? (
                                <Badge color="success" className="me-2 text-white">
                                    {filterBy.status.length}
                                </Badge>
                            ) : (
                                <span className="fas fa-sort me-2" />
                            )}
                            Status
                        </Button>

                        <Button color="primary" outline size="sm" className="rounded-3" onClick={() => toggleFilterModal('movies')}>
                            {filterBy.movies.length > 0 ? (
                                <Badge color="success" className="me-2 text-white">
                                    {filterBy.movies.length}
                                </Badge>
                            ) : (
                                <span className="fas fa-film me-2"/>
                            )}
                            Movies
                        </Button>

                        {isAdmin(user) && (
                            <Button color="primary" outline size="sm" className="rounded-3" onClick={() => toggleFilterModal('organizations')}>
                                {filterBy.organizations.length > 0 ? (
                                    <Badge color="success" className="me-2 text-white">
                                        {filterBy.organizations.length}
                                    </Badge>
                                ) : (
                                    <span className="fas fa-building me-2"/>
                                )}
                                Organizations
                            </Button>
                        )}

                        <Button color="primary" outline size="sm" className="rounded-3" onClick={() => toggleFilterModal('screening')}>
                            {filterBy.screeningDates.from || filterBy.screeningDates.to ? (
                                <Badge color="success" className="me-2 text-white">
                                    <span className="fas fa-calendar-check"/>
                                </Badge>
                            ) : (
                                <span className="fas fa-calendar-week me-2"/>
                            )}
                            {getFiterDatesLabel(filterBy.screeningDates, 'Screening Dates')}
                        </Button>
                    </ButtonGroup>

                    <div className="flex-grow-1">
                        <span className="fas fa-list me-2 text-primary" />
                        showing {orders.length} result{orders.length === 1 ? '' : 's'}
                    </div>

                    <div className="text-primary">
                        {isAdmin(user) && (
                            <Button color="link" onClick={() => setExportModal(!exportModal)} className="text-decoration-none">
                                <span className="fas fa-file-excel me-1" /> Export
                            </Button>
                        )}
                    </div>
                </div>

                {isLoading && <Loading />}

                {groupedOrders.currentOrders.length > 0 && (
                    <Fragment>
                        <h2 className="mt-5 text-uppercase">Current Orders</h2>
                        {groupedOrders.currentOrders.map(order => (
                            <OrderCard key={order._id} order={order} />
                        ))}
                    </Fragment>
                )}

                {groupedOrders.pastOrders.length > 0 && (
                    <Fragment>
                        <h2 className="mt-5 text-uppercase">Past Orders</h2>
                        {groupedOrders.pastOrders.map(order => (
                            <OrderCard key={order._id} order={order} />
                        ))}
                    </Fragment>
                )}

                {groupedOrders.notOrderedYet.length > 0 && (
                    <Fragment>
                        <h2 className="mt-5 text-uppercase">Not Ordered Yet</h2>
                        {groupedOrders.notOrderedYet.map(order => (
                            <OrderCard key={order._id} order={order} />
                        ))}
                    </Fragment>
                )}

                <div className="clearfix py-4" />

                <span className="fas "></span>
            </div>

            <Modal isOpen={filterModal.isOpen} toggle={() => toggleFilterModal()} size="lg" scrollable>
                <ModalHeader toggle={() => toggleFilterModal()}>
                    Filter by {filterModal.filter}
                </ModalHeader>
                <ModalBody>

                    {filterModal.filter === 'status' && (
                        <div className="status-filter-modal">
                            {Object.keys(statusMap).map(status => (
                                <div
                                    key={status}
                                    className="my-1 position-relative pointer"
                                    onClick={() => toggleFilter('status', status)}
                                >
                                    {filterBy.status.includes(status) && (
                                        <span className="end-0 fa-check fas me-3 mt-2 position-absolute pt-1 top-0"/>
                                    )}

                                    <OrderStatusBadge
                                        status={status}
                                        className={`p-2 ${filterBy.status.includes(status) ? 'selected' : ''}`}
                                    />
                                </div>
                            ))}
                        </div>
                    )}

                    {filterModal.filter === 'movies' && (
                        <div className="movies-filter-modal">
                            <SearchBar onChange={({ target }) => setSearchMoviesValue(target.value)} initialValue={searchMoviesValue} />
                            <ListGroup>
                                {movies.map(({ _id, title, poster, assetDetails }) => title.toLowerCase().includes(searchMoviesValue.toLowerCase()) && (
                                    <ListGroupItem
                                        onClick={() => toggleFilter('movies', _id)}
                                        key={_id}
                                        style={{ cursor: 'pointer', borderRadius: '0' }}
                                        active={filterBy.movies.includes(_id)}
                                        className="border-0 border-start border-primary border-2 position-relative
                                            text-white d-flex justify-content-start gap-3 align-items-center mb-2"
                                    >
                                        <img src={imgUrl(poster?.imageUrl)} width={50} alt=""/>
                                        <div className="flex-grow-1">
                                            {title}
                                        </div>
                                        {assetDetails && (
                                            <Badge color="primary" className="text-black text-uppercase">{assetDetails.contentKind}</Badge>
                                        )}
                                        {filterBy.movies.includes(_id) && (
                                            <div className="position-absolute top-0 end-0 mt-2 me-3 small">
                                                Selected <span className="fas fa-check-circle text-success"/>
                                            </div>
                                        )}
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </div>
                    )}

                    {filterModal.filter === 'screening' && (
                        <div className="screening-filter-modal">
                            <Label>Screening date from</Label>
                            <DatePicker
                                className="date-field w-100"
                                showIcon
                                withPortal
                                fixedHeight
                                selected={filterBy.screeningDates.from}
                                onChange={handleScreeningDate('from')}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="From"
                                monthsShown={2}
                                toggleCalendarOnIconClick
                                isClearable={true}
                            />
                            <Label className="mt-4">Screening date to</Label>
                            <DatePicker
                                className="date-field w-100"
                                showIcon
                                withPortal
                                fixedHeight
                                selected={filterBy.screeningDates.to}
                                onChange={handleScreeningDate('to')}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="To"
                                monthsShown={2}
                                toggleCalendarOnIconClick
                                isClearable={true}
                            />
                        </div>
                    )}

                    {filterModal.filter === 'organizations' && (
                        <div className="organizations-filter-modal">
                            <SearchBar onChange={({ target }) => setSearchMoviesValue(target.value)} initialValue={searchMoviesValue} />
                            <ListGroup>
                                {organizations.map(({ _id, name, logo }) => name.toLowerCase().includes(searchMoviesValue.toLowerCase()) && (
                                    <ListGroupItem
                                        onClick={() => toggleFilter('organizations', _id)}
                                        key={_id}
                                        style={{ cursor: 'pointer', borderRadius: '0' }}
                                        active={filterBy.organizations.includes(_id)}
                                        className="border-0 border-start border-primary border-2 position-relative
                                            text-white d-flex justify-content-start gap-3 align-items-center mb-2"
                                    >
                                        <img src={imgUrl(logo?.imageUrl)} width={50} alt=""/>
                                        <div className="flex-grow-1">
                                            {name}
                                        </div>
                                        {filterBy.organizations.includes(_id) && (
                                            <div className="position-absolute top-0 end-0 mt-2 me-3 small">
                                                Selected <span className="fas fa-check-circle text-success"/>
                                            </div>
                                        )}
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => toggleFilterModal()}>
                        <span className="fas fa-check-circle me-2" />
                        Show results
                    </Button>
                </ModalFooter>
            </Modal>

            <OrderExportModal
                isOpen={exportModal}
                toggle={() => setExportModal(!exportModal)}
                onExport={handleExport}
                defaultSelectedFields={[
                    'movie.title',
                    'version.name',
                    'organization.name',
                    'organization.country',
                    'status',
                    'statusWorkflow',
                    'requestStatus',
                    'orderType',
                    'price',
                    'screeningDate',
                    'uuid',
                ]}
            />
        </div>
    );
};

export default Orders;
