import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import React, {Fragment, useState} from 'react';
import {formatCredits} from '../../utils/credits';
import {useSelector} from 'react-redux';


const AddTransaction = ({ isOpen, action, toggle, orgCreditAmount, onSave }) => {
    const user = useSelector(state => state.auth.user);
    const [transaction, setTransaction] = useState({
        description: '',
        amount: '',
        notes: '',
    });

    const handleChange = e => {
        setTransaction(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const newBalance = () => {
        if (action === 'credit') return Number(orgCreditAmount) + Number(transaction.amount);
        if (action === 'debit') return Number(orgCreditAmount) - Number(transaction.amount);
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" scrollable>
            <ModalHeader tag="div" toggle={toggle}>
                {action === 'credit' && (
                    <Fragment>
                        <h1 className="mb-0">Add credits</h1>
                        <p className="mb-0 text-success text-expand text-uppercase">Creating a Credit transaction</p>
                    </Fragment>
                )}
                {action === 'debit' && (
                    <Fragment>
                        <h1 className="mb-0">Remove credits</h1>
                        <p className="mb-0 text-danger text-expand text-uppercase">Creating a Debit transaction</p>
                    </Fragment>
                )}
            </ModalHeader>

            <ModalBody>
                <FormGroup>
                    <Label>
                        <span className="fas fa-file-text me-2 text-primary" />
                        Title <small>(visible to organization users)</small>
                    </Label>
                    <Input
                        type="text"
                        placeholder="Description"
                        required
                        name="description"
                        value={transaction.description}
                        onChange={handleChange}
                    />
                </FormGroup>

                <FormGroup>
                    <Label>
                        <span className={`fas me-2 ${action === 'credit' ? 'fa-plus-circle text-success' : 'fa-minus-circle text-danger'}`}/>
                        Amount to {action}</Label>
                    <Input
                        type="number"
                        placeholder="Amount"
                        name="amount"
                        required
                        value={transaction.amount}
                        onChange={handleChange}
                        min={0}
                        inputMode="numeric"
                        pattern="[0-9]*"
                    />
                </FormGroup>

                <FormGroup>
                    <Label>
                        <span className="fas fa-lock me-2 text-primary"/>
                        Notes <small>(not visible to organization users)</small>
                    </Label>
                    <Input
                        type="textarea"
                        placeholder="Private notes about this transaction"
                        className="pt-3"
                        name="notes"
                        value={transaction.notes}
                        onChange={handleChange}
                    />
                </FormGroup>

                <p className="mb-0">
                    <span className="fas fa-user-lock me-2 text-primary" />
                    Operation is inserted by <span>{user.firstName} {user.lastName}</span> for audit purposes.
                </p>
            </ModalBody>

            <ModalFooter className="d-flex justify-content-between align-items-end">
                <div className="text-end">
                    <div>
                            <span className="text-primary me-3">
                                <span className="fas fa-bank me-2" />Org. balance:
                            </span>
                        {formatCredits(orgCreditAmount)} credits
                    </div>
                    {transaction.amount && (
                        <Fragment>
                            <div
                                className={`border-bottom border-primary mb-2 text-${action === 'credit' ? 'success' : 'danger'}`}>
                                <span className="text-capitalize">{action}</span>
                                <span className={`fas fa-${action === 'credit' ? 'plus' : 'minus'} mx-2`}/>
                                {formatCredits(transaction.amount)} credits
                            </div>
                            <div className={newBalance() < 0 ? 'text-danger' : ''}>
                                <span className="text-primary me-3">
                                    <span className="fas fa-coins me-2"/>New balance:
                                </span>
                                {formatCredits(newBalance())} credits
                            </div>
                            {newBalance() < 0 && (
                                <span className="border border-danger p-1 bg-danger text-white">
                                    <span className="fas fa-warning me-2" />
                                    Insufficient credits!
                                </span>
                            )}
                        </Fragment>
                    )}
                </div>
                <div>
                    <Button color="primary" outline onClick={toggle} className="me-3">
                        <span className="fas fa-times me-2"/> Cancel
                    </Button>
                    {['credit', 'debit'].includes(action) && (
                        <Button
                            color={action === 'credit' ? "success" : "danger"}
                            onClick={() => onSave(transaction)}
                            disabled={action === 'credit' ? !transaction.amount : newBalance() < 0}
                        >
                            <span className="fas fa-check me-2"/> {action === 'credit' ? 'Add credits' : 'Remove credits'}
                        </Button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default AddTransaction;
