import React, {Fragment, useEffect, useState} from 'react';
import {Input, Label, Button, Form, FormGroup} from 'reactstrap';

const parseYoutubeUrl = url => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
}

const YoutubeTrailer = ({videoId, value, canEdit = false, onSave, width = '100%', height = '100%'}) => {
    const [trailerURL, setTrailerURL] = useState('');
    const [videoSrc, setVideoSrc] = useState(`https://www.youtube.com/embed/${videoId}`);

    useEffect(() => {
        setTrailerURL(value)
    }, [value]);

    const handleChange = e => {
        const url = e.target.value;
        const parsedUrl = parseYoutubeUrl(url);
        if (parsedUrl) {
            setTrailerURL(parsedUrl);
            setVideoSrc(`https://www.youtube.com/embed/${parsedUrl}`)
        } else {
            setTrailerURL(url);
        }
    };

    const handleSave = (e) => {
        e.preventDefault()
        onSave(trailerURL)
    }

    return (
        <Fragment>
            <iframe
                width={width}
                height={canEdit ? '85%' : height}
                src={videoSrc}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Youtube Trailer"
            />
            {canEdit && (
                <Form onSubmit={handleSave}>
                    <FormGroup className=" d-flex ">
                        <Label className="pt-3 px-3"> URL</Label>
                        <Input value={trailerURL} onChange={handleChange}/>
                        <Button color="primary" type="submit">save</Button>
                    </FormGroup>
                </Form>
            )}
        </Fragment>
    );
};

export default YoutubeTrailer;
