import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchDistributor = createAsyncThunk('distributors/fetchOne', async (distributorId) => {
    const response = await api.get(`/distributors/${distributorId}`);
    return response.data;
});

export const fetchDistributors = createAsyncThunk('distributors/fetchMany', async () => {
    const response = await api.get('/distributors');
    return response.data;
});

export const postDistributor = createAsyncThunk('distributors/post', async (body) => {
    const response = await api.post('/distributors', body);
    return response.data;
});

export const updateDistributor = createAsyncThunk('distributors/update', async (distributor) => {
    const response = await api.put(`/distributors/${distributor._id}`, distributor);
    return response.data;
});

const distributorsSlice = createSlice({
    name: 'distributors',
    initialState: {
        list: [],
        current: null,
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDistributor.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDistributor.fulfilled, (state, action) => {
                state.status = 'success';
                state.current = action.payload;
                state.error = null;
            })
            .addCase(fetchDistributor.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

           .addCase(fetchDistributors.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchDistributors.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = action.payload;
                state.error = null;
            })
            .addCase(fetchDistributors.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(postDistributor.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(postDistributor.fulfilled, (state, action) => {
                state.status = 'success';
                const index = state.list.findIndex(member => member._id === action.payload._id);
                if (index !== -1) {
                    state.list[index] = action.payload;
                } else {
                    state.list.push(action.payload);
                }
                state.current = action.payload;
            })
            .addCase(postDistributor.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message || 'Error posting distributor';
            })

            .addCase(updateDistributor.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateDistributor.fulfilled, (state, action) => {
                state.status = 'success';
                state.current = action.payload;
            })
            .addCase(updateDistributor.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload.message || 'Error updating distributor';
            });
    },
});

export default distributorsSlice.reducer;
