import React from 'react';
import {useDispatch} from 'react-redux';
import {refreshToken, fetchLoggedUser, clearPinError} from '../../slices/auth.slice';
import PinInputKeyboard from '../../components/PinInputKeyboard';


const PinCode = () => {
    const dispatch = useDispatch();

    const handleSubmit = async (pinCode) => {
        try {
            await dispatch(refreshToken(pinCode)).unwrap();
            dispatch(fetchLoggedUser());
            window.location.reload();
        } catch (error) {
            console.error('Error refreshing token');
        }
    };

    const handleError = () => {
        dispatch(clearPinError());
    };

    return (
        <div className="pincode-container d-flex bg-broken align-items-center justify-content-center flex-column p-3 min-vh-100 w-100">
            <div className="mb-4 pb-3 border-bottom border-primary opacity-50 px-5">
                <img src="/logo.svg" alt="" width="200px"/>
            </div>

            <PinInputKeyboard onSubmit={handleSubmit} onError={handleError}/>
        </div>
    );
};

export default PinCode;
