import React, {useEffect, useState} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Badge, Button} from 'reactstrap';
import Loading from '../../components/Loading';
import {apiRequest} from '../../utils/api-request';
import {useSelector} from 'react-redux';

const Transactions = () => {
    const { userId } = useParams();
    const myUser = useSelector(state => state.auth.user);
    const [sessions, setSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const endSession = (_id) => async (e) => {
        e.preventDefault();
        try {
            const promise = apiRequest(`/auth/sessions/${_id}/terminate`, 'POST', {});
            toast.promise(promise, {
                pending: 'Terminating session',
                success: 'Session terminated',
                error: 'Error terminating session'
            });
            await promise;
            const result = await apiRequest(`/auth/${userId}/sessions`);
            setSessions(result);
        } catch (err) {
            console.error('Failed to end session' ,err);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const result = await apiRequest(`/auth/${userId}/sessions`);
                setSessions(result);
            } catch (err) {
                toast.error(`Failed to fetch sessions: ${err}`)
            } finally {
                setIsLoading(false);
            }
        })();
    }, [userId]);

    if (isLoading) return <Loading fullHeight />;

    return myUser && (
        <div className="transactions-view p-3 min-vh-100">
            <NavLink to={`/users/${userId}`} className="text-decoration-none">
                <span className="fas fa-chevron-left me-2" /> User details
            </NavLink>

            <h1 className="mt-1 mb-3">User sessions</h1>

            {sessions.map(({ _id, deviceInfo, expiresIn, isActive }) => (
                <div
                    key={_id}
                    className="session-item bg-broken border-start border-primary mb-2"
                >
                    <div className="d-flex justify-content-between align-items-center position-relative p-3">
                        <p className="text-truncate text-primary mb-0">
                            {myUser.sessionId === _id && (
                                <span className="position-absolute end-0 top-0 text-black bg-primary small px-1">
                                    <span className="fas fa-user me-2" />
                                    My session
                                </span>
                            )}
                            <span className="fas fa-desktop fa-fw me-2 "/>
                            {deviceInfo}
                            <br/>
                            <span className="text-white small">
                                <span className="fas fa-clock fa-fw me-2"/> Expires: {new Date(expiresIn).toLocaleString()}
                            </span>
                            {isActive && myUser.sessionId !== _id && (
                                <Button color="link" size="sm" className="text-decoration-none text-danger ms-4" onClick={endSession(_id)}>
                                    <span className="fas fa-stop me-2"/>Terminate session
                                </Button>
                            )}
                        </p>
                        <div className="ps-3">
                            <Badge className={`bg-transparent text-uppercase text-${isActive ? 'success' : 'danger'}`}>
                                <span className={`fas fa-${isActive ? 'check' : 'times'}-circle me-1`} />
                                {isActive ? 'Active' : 'Inactive'}
                            </Badge>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Transactions;
