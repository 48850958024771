export const formatCredits = amount => amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const computeOrderPrice = (conditionalPrice, output) => {
    const conditions = conditionalPrice;

    if (!conditions.length) {
        return output === 'number' ? 0 : 'N/A';
    }

    let latestCondition = conditions[0];
    for (const condition of conditions) {
        const toDate = new Date(condition.toDate);

        if (toDate > new Date(latestCondition.toDate)) {
            latestCondition = condition;
        }
    }

    return output === 'number' ? latestCondition.price : formatCredits(latestCondition.price) ;
};
