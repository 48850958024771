import React, {useState} from 'react';
import {Button, Input, InputGroup, InputGroupText, Label} from 'reactstrap';
import DatePicker from 'react-datepicker';

import './ConditionalPrice.scss'

const ConditionalPrice = ({ conditionalPrice = [], handleChange, distributor, releaseDate, tiers }) => {
    const [conditions, setConditions] = useState(conditionalPrice);
    const [dateRanges, setDateRanges] = useState([])

    const addCondition = () => {
        setConditions(conditions => [...conditions, {}]);
    };

    const removeCondition = (index) => {
        const updatedConditions = conditions.filter((_, i) => i !== index);
        setConditions(updatedConditions);

        const updatedDateRanges = dateRanges.filter((_, i) => i !== index);
        setDateRanges(updatedDateRanges);

        handleChange({ target: { name: 'conditionalPrice', value: updatedConditions } });
    };

    const handleDateChange = (index, date) => {
        const isDateRange = Array.isArray(date) && date.length === 2;
        if (!isDateRange) return;

        const [start, end] = date;
        const updatedConditions = [...conditions];
        const newRange = { start, end };
        const newDateRanges = [...dateRanges];
        newDateRanges[index] = newRange;

        const isOverlap = dateRanges.some((range, rangeIndex) => {
            if (rangeIndex === index) return false;
            return !(range.end < start || range.start > end);
        });

        if (!isOverlap) {
            updatedConditions[index] = { ...updatedConditions[index], fromDate: start, toDate: end };
            setDateRanges(newDateRanges);
            setConditions(updatedConditions);
            handleChange({ target: { name: 'conditionalPrice', value: updatedConditions } });
        }
    };

    const handlePriceChange = (index, event) => {
        const price = event.target.value;
        const updatedConditions = conditions.map((condition, i) => {
            if (index !== i) return condition;
            return { ...condition, price: parseInt(price, 10) || 0 };
        });

        setConditions(updatedConditions);
        handleChange({ target: { name: 'conditionalPrice', value: updatedConditions } });
    };

    const computeDefaults = () => {
        const tierPriority = tiers.includes('premium') ? 'premium' : 'mid';

        const filteredDefaults = distributor.distributorDefaults.filter(d => d.tier.includes(tierPriority));
        const newConditions = filteredDefaults.map(defaultTier => {
            const fromDate = new Date(releaseDate);
            fromDate.setDate(fromDate.getDate() + defaultTier.daysBeforeRelease);
            const toDate = new Date(releaseDate);
            toDate.setDate(toDate.getDate() + defaultTier.daysAfterRelease);

            return {
                fromDate: fromDate,
                toDate: toDate,
                price: defaultTier.credits
            };
        });
        setConditions(newConditions);

        handleChange({ target: { name: 'conditionalPrice', value: newConditions }});
    };

    const excludeDates = (index) => {
        return conditions
            .filter((_, i) => i !== index)
            .map(({ fromDate, toDate }) => ({ start: fromDate, end: toDate }))
    }


    return (
        <div className="conditions ps-lg-3 ps-1 pe-2 py-2">
            {conditions?.map((condition, index) =>  (
                <div className="condition d-flex align-items-center w-100 my-2" key={index}>
                    <div className="d-inline-flex align-items-center">
                        <Label className="label-from me-2 mt-2">From/to</Label>
                        <DatePicker
                            className="date-field"
                            showIcon
                            withPortal
                            fixedHeight
                            onChange={(date) => handleDateChange(index, date)}
                            startDate={condition.fromDate}
                            endDate={condition.toDate}
                            selectsRange
                            excludeDateIntervals={excludeDates(index)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Enter date range"
                            monthsShown={2}
                            toggleCalendarOnIconClick
                        />
                    </div>

                    <div className="d-inline-flex align-items-center me-3">
                        <Label className="label-price text-nowrap mt-2 mx-3">Rate is</Label>
                        <InputGroup>
                            <Input
                                value={condition.price}
                                onChange={(e) => handlePriceChange(index, e)}
                                className="price-field"
                                type="number">
                            </Input>
                            <InputGroupText className="bg-transparent text-white border-0">credits</InputGroupText>
                        </InputGroup>
                    </div>

                    <Button close onClick={() => removeCondition(index)}/>
                </div>
            ))}

            <div className="pt-2">
                <Button color="primary" outline className="me-3" onClick={computeDefaults} disabled={!releaseDate || !distributor}>
                    <span className="fas fa-calculator me-2 border-end border-primary pe-2"/>
                    Fetch default pricing
                </Button>
                <Button color="secondary" className="bg-transparent text-white me-2" onClick={addCondition}>
                    <span className="fas fa-plus me-2 border-end pe-2"/>
                    Add conditional Price
                </Button>
            </div>
        </div>
    );
}

export default ConditionalPrice
