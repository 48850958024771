import React from 'react';
import {NavLink} from 'react-router-dom';
import OrderStatusBadge from '../../components/OrderStatusBadge';


const OrdersCard = ({ ordersCount }) => {
    if (!ordersCount) {
        return;
    }

    const ignoreStatuses = ['request-pending', 'dcp-on-request', 'ready-to-order'];

    return (
        <div className="home-component d-flex flex-column p-3 m-3 w-100 bg-broken border-start border-primary">
            <div className="pic-and-name d-flex justify-content-between mb-3">
                <NavLink to={`/orders`} className="text-decoration-none">
                    <h1 className="text-capitalize">Orders</h1>
                </NavLink>
            </div>

            <div className="mb-3">
                {ordersCount.map(({ count, statusWorkflow }) => !ignoreStatuses.includes(statusWorkflow) && (
                    <div className="mb-2" key={statusWorkflow}>
                        <div className="position-relative">
                            <NavLink to={`/orders?status=${statusWorkflow}`} className="text-decoration-none">
                                <OrderStatusBadge status={statusWorkflow}/>
                                <span className="position-absolute end-0 top-0 me-2">
                                    {count}
                                    <span className="fas fa-arrow-right ms-2"/>
                                </span>
                            </NavLink>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OrdersCard;
