import { useBeforeUnload, useBlocker } from 'react-router-dom';
import { useMemo } from 'react';

const useSavePrompt =(originalData, formState)=> {
    const emptyState = useMemo(() => formState, [formState])

    const isDirty =  JSON.stringify(originalData) !== JSON.stringify(formState)
    const isEmpty =  JSON.stringify(emptyState) === JSON.stringify(formState)

    const shouldBlock =()=>{
        return  isEmpty ? false : isDirty
    }

    useBeforeUnload((event) => {
        if (isDirty) event.preventDefault();
        return;
    });

    return useBlocker( ({ currentLocation, nextLocation }) => shouldBlock() && currentLocation.pathname !== nextLocation.pathname );

}

export default useSavePrompt;