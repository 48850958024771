import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchAutomationCue = createAsyncThunk('automationCues/fetchOne', async (cueId) => {
    const response = await api.get(`/automation-cues/${cueId}`);
    return response.data;
});

export const fetchAutomationCues = createAsyncThunk('automationCues/fetchMany', async (organizationId) => {
    const response = await api.get(`/automation-cues/${organizationId}`);
    return response.data;
});

const automationCuesSlice = createSlice({
    name: 'automationCues',
    initialState: {
        list: [],
        cue: null,
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAutomationCue.pending, (state) => {
                state.status = 'loading';
                state.cue = null;
            })
            .addCase(fetchAutomationCue.fulfilled, (state, action) => {
                state.status = 'success';
                state.cue = action.payload;
                state.error = null;
            })
            .addCase(fetchAutomationCue.rejected, (state, action) => {
                state.status = 'failed';
                state.cue = null;
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(fetchAutomationCues.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAutomationCues.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = action.payload;
                state.error = null;
            })
            .addCase(fetchAutomationCues.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            });
    },
});

export default automationCuesSlice.reducer;
