import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import {Bounce, ToastContainer} from 'react-toastify';
import store from './slices'
import './index.scss';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const BacApp = (
    <Provider store={store}>
        <App/>
        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Bounce}
        />
    </Provider>
);

const wrappedApp = process.env.REACT_APP_ENVIRONMENT === 'development' ? (
        <React.StrictMode>{BacApp}</React.StrictMode>
    ) : BacApp;

root.render(wrappedApp);
