import React, {useState, useEffect, Fragment} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import { fetchMovieVersions } from '../../slices/movie-versions.slice';
import {deletePlaylist, fetchPlaylistById, updatePlaylist} from '../../slices/playlists.slice';
import Loading from '../../components/Loading';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import { imgUrl } from '../../utils/api-request';
import {toast} from 'react-toastify';
import DeleteButton from '../../components/DeleteButton';

const PlaylistDetails = () => {
    const { playlistId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const { list: versionList, status: versionListStatus } = useSelector(state => state.movieVersions);
    const { currentPlaylist } = useSelector(state => state.playlists);
    const [selectedVersions, setSelectedVersions] = useState([]);
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [dragOverIndex, setDragOverIndex] = useState(null);

    useEffect(() => {
        dispatch(fetchMovieVersions({ token }));
        dispatch(fetchPlaylistById(playlistId));
    }, [dispatch, playlistId, token]);

    useEffect(() => {
        setSelectedVersions(currentPlaylist?.movieVersions)
    }, [currentPlaylist]);

    const handleSelectVersion = (versionId) => {
        const version = versionList.find(v => v._id === versionId);
        if (version) {
            setSelectedVersions(prev => [...prev, version]);
        }
    };

    const unselectVersion = (index) => {
        setSelectedVersions(prevVersions => [
            ...prevVersions.slice(0, index),
            ...prevVersions.slice(index + 1)
        ]);
    };

    const handleUpdatePlaylist = () => {
        const promise = dispatch(updatePlaylist({
            id: currentPlaylist._id,
            updates: { movieVersions: selectedVersions.map(({ _id }) => _id) },
        })).unwrap();
        toast.promise(promise, {
            pending: 'Saving...',
            success: 'Playlist updated successfully',
            error: 'Error updating playlist',
        });
    };

    const dragStart = (e, index) => {
        setDraggedIndex(index);
        e.dataTransfer.effectAllowed = "move";
    };

    const dragEnter = (e, index) => {
        e.preventDefault();
        setDragOverIndex(index);
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    const drop = (e, index) => {
        e.preventDefault();
        const newList = [...selectedVersions];
        const draggedItem = newList.splice(draggedIndex, 1)[0];
        newList.splice(index, 0, draggedItem);
        setSelectedVersions(newList);
        setDragOverIndex(null);
        setDraggedIndex(null);
    };

    const handleDelete = async () => {
        const promise = dispatch(deletePlaylist(playlistId)).unwrap();
        toast.promise(promise, {
            pending: 'Deleting playlist',
            success: 'Playlist deleted successfully',
            error: 'Error deleting playlist',
        });
        await promise;
        navigate('/playlists');
    };

    if (versionListStatus === 'loading') {
        return <Loading fullHeight />;
    }

    return (
        <div className="playlist-detail-view d-flex flex-column vh-100">
            <header className="p-3 bg-broken text-white">
                <NavLink to="/playlists" className="text-decoration-none">
                    <span className="fas fa-chevron-left me-2" /> Playlists
                </NavLink>
            </header>
            <div className="d-flex flex-grow-1 overflow-hidden">
                <div className="w-50 d-flex flex-column overflow-y-auto">
                    <h6 className="ps-4 bg-broken p-2 sticky-top border-bottom border-secondary border-opacity-10">
                        Available Versions
                    </h6>
                    <ListGroup className="px-2">
                        {versionList.map(version => (
                            <ListGroupItem
                                key={version._id}
                                onClick={() => handleSelectVersion(version._id)}
                                style={{ borderRadius: 0 }}
                                className="d-flex justify-content-start align-items-center gap-3 border-start
                                border-primary border-2 bg-transparent text-white border-0 bg-broken pointer mb-2"
                            >
                                <img src={imgUrl(version.movie.poster?.imageUrl)} width={50} alt={version.movie?.title} />
                                <div className="flex-grow-1">
                                    {version.movie?.title}<br />
                                    <small>{version.name}</small>
                                </div>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </div>
                <div className="w-50 d-flex flex-column overflow-y-auto">
                    <h6 className="ps-4 bg-broken p-2 sticky-top border-bottom border-secondary border-opacity-10">
                        Selected Versions
                    </h6>
                    <ListGroup className="px-2">
                        {selectedVersions?.map((version, index) => (
                            <Fragment key={version._id}>
                                {dragOverIndex === index && <div style={{height: "2px"}} className="bg-primary mb-2"/>}
                                <ListGroupItem
                                    draggable
                                    onDragStart={(e) => dragStart(e, index)}
                                    onDragEnter={(e) => dragEnter(e, index)}
                                    onDragOver={dragOver}
                                    onDrop={(e) => drop(e, index)}
                                    style={{ borderRadius: 0 }}
                                    className="d-flex justify-content-start align-items-center gap-3 border-start
                                        border-primary border-2 bg-transparent text-white border-0 bg-broken pointer mb-2"
                                >
                                    <img src={imgUrl(version.movie?.poster?.imageUrl)} width={50} alt={version.movie.title} />
                                    <div className="flex-grow-1">
                                        {version.movie.title}<br />
                                        <small>{version.name}</small>
                                    </div>
                                    <Button
                                        color="link"
                                        className="text-decoration-none text-danger"
                                        onClick={() => unselectVersion(index)}
                                    >
                                        <span className="fas fa-trash" />
                                    </Button>
                                    <div className="opacity-25" style={{ cursor: 'grab' }}>
                                        <span className="fas fa-bars fa-2x" />
                                    </div>
                                </ListGroupItem>
                            </Fragment>
                        ))}
                        {dragOverIndex === selectedVersions?.length && <div style={{ height: "2px" }} className="bg-primary my-5" />}
                    </ListGroup>
                </div>
            </div>
            <footer className="p-3 bg-broken text-white shadow-lg d-flex justify-content-between align-items-center">
                <DeleteButton
                    onClick={handleDelete}
                    title="playlist"
                    color="link"
                    entityLabel="playlist"
                    hideEntity
                />

                <Button color="primary" onClick={handleUpdatePlaylist}>
                    <span className="fas fa-save me-2" />
                    Update Playlist
                </Button>
            </footer>
        </div>
    );
};

export default PlaylistDetails;
