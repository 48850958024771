import Package from '../../../package.json';
import React, {Fragment, useState} from 'react';
import {Collapse, Nav, NavItem, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {useSelector, useDispatch} from 'react-redux';
import {NavLink, useNavigate, useLocation} from 'react-router-dom';
import {getApiInformation, logout, updateUser} from '../../slices/auth.slice';
import {toast} from 'react-toastify';
import Avatar from '../../components/Avatar';
import EditModal from '../../components/EditModal';
import {imgUrl} from '../../utils/api-request';
import RoleBadge from '../../components/RoleBadge';
import './Sidebar.scss';
import DateTimeDisplay from '../../components/DateTimeDisplay';

const version = Package.version;

const allMenuItems = [
    { link: '/', icon: 'fa-home', menuText: 'Home', visibleFor: ['site-guest', 'site-user', 'site-admin', 'bac-user', 'bac-admin', 'root'] },
    { link: '/orders', icon: 'fa-clipboard-list', menuText: 'Orders', visibleFor: ['site-guest', 'site-user', 'site-admin', 'bac-user', 'bac-admin', 'root'] },
    { link: '/equipment', icon: 'fa-server', menuText: 'Equipment', visibleFor: ['site-user', 'site-admin'], checkEquipmentStatus: true },
    { link: '/organizations/own/content', icon: 'fa-file-video', menuText: 'Content Manager', visibleFor: ['site-user', 'site-admin'] },
    { link: '/movies', icon: 'fa-film', menuText: 'Movies', visibleFor: ['bac-user', 'bac-admin', 'root'] },
    { link: '/playlists', icon: 'fa-photo-film', menuText: 'Show playlists', visibleFor: ['bac-user', 'bac-admin', 'root'] },
    { link: '/organizations/own', icon: 'fa-building', menuText: 'Organization', visibleFor: ['site-user', 'site-admin'] },
    { link: '/organizations', icon: 'fa-building', menuText: 'Organizations', visibleFor: ['bac-user', 'bac-admin', 'root'] },
    { link: '/users', icon: 'fa-users', menuText: 'Users', visibleFor: ['site-guest', 'site-user', 'site-admin', 'bac-user', 'bac-admin', 'root'] },
    { link: '/crew-manager', icon: 'fa-user-group', menuText: 'Cast & Crew', visibleFor: ['bac-user', 'bac-admin', 'root'] },
    { link: '/distributors', icon: 'fa-warehouse', menuText: 'Distributors', visibleFor: ['bac-user', 'bac-admin', 'root'] },
    { link: '/audit', icon: 'fa-list-check', menuText: 'Audit logs', visibleFor: ['bac-user', 'bac-admin', 'root'] },
];

const SideMenu = ({ isOpen, toggle, organization = null }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { user, apiRoot } = useSelector((state) => state.auth);
    const { unreadCount } = useSelector((state) => state.notifications);
    const [versionModal, setVersionModal] = useState(false);
    const [editModal, setEditModal] = useState({
        open: false,
        field: '',
        dataType: 'text',
        label: '',
        value: '',
        mediaType: '',
    });

    const toggleEditModal = (field, dataType, label, value, mediaType) => {
        setEditModal({
            open: true,
            field,
            dataType,
            label,
            value,
            mediaType,
        });
    };

    const toggleVersionModal = () => setVersionModal(!versionModal);

    if (!user) {
        return;
    }

    const onSaveEditModal = async (newValue) => {
        const updatedUser = { ...user };

        if (editModal.field === 'profilePic') {
            updatedUser.profilePic = newValue._id;
        }

        const promise = dispatch(updateUser(updatedUser)).unwrap();
        toast.promise(promise, {
            pending: 'Updating user',
            success: 'User updated successfully',
            error: 'Error updating user',
        });
    };

    const checkActive = (path) => {
        if (path === '/organizations/own/content') {
            return location.pathname === '/organizations/own/content';
        }

        if (path === '/') {
            return location.pathname === path;
        }

        return location.pathname.includes(path);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('role');
        localStorage.removeItem('impersonatedUser');
        localStorage.removeItem('originalToken');
        localStorage.removeItem('isImpersonating');
        dispatch(logout());
        navigate('/login');
        toast('Logged out successfully');
        window.location.reload();
    };

    const buildMenu = (role) => {
        return allMenuItems.filter(item => item.visibleFor.includes(role));
    };

    const getEquipmentStatus = () => {
        const mediablockStatus = organization?.mediablock?.status === 'Connected' && organization?.mediablock?.state === 'Enabled'
        const bacserverStatus = organization?.bacServer?.gofilexDaemonsAlfred === 'online' && organization?.bacServer?.gofilexDaemonsFrank === 'online'

            return (<div className="ms-3">
                        <span className={`fas fa-cube px-1 fa-xs icons-${mediablockStatus ? 'on': 'off'}`} />
                        <span className={`fas fa-server px-1 fa-xs icons-${bacserverStatus ? 'on': 'off'}`} />
                    </div>
            )
    };


    return (
        <Collapse isOpen={isOpen} className="sidebar d-xl-block">
            <div className="sidebar-content position-relative h-100 d-flex flex-column overflow-auto">
                <header className="my-4 mb-0 text-center">
                    <img src="/logo.svg" alt="BEL AIR Cinema" width={112} className="pt-1" />

                    <div className="profile-picture mt-3 mx-auto">
                        <Avatar
                            imageUrl={user.profilePic?.imageUrl}
                            name={`${user.firstName} ${user.lastName}`}
                            size={80}
                            onEdit={() => toggleEditModal('profilePic', 'image', 'Profile Picture', imgUrl(user.profilePic?.imageUrl), 'user-avatar')}
                        />
                    </div>

                    <NavLink to="/users/me" className="text-decoration-none text-white" onClick={toggle}>
                        <p className="mt-3 text-uppercase">
                            <span className="d-block px-3 text-truncate user-name w-100">
                                {user.firstName} {user.lastName}
                            </span>
                            <RoleBadge role={user.role} />
                        </p>
                    </NavLink>

                    <NavLink to="/notifications" className="text-decoration-none">
                        <div className="notifications position-absolute">
                            <span className={`fas fa-bell p-2 text-primary border border-primary ${unreadCount > 0 ? 'fa-shake' : ''}`}/>
                            {unreadCount > 0 && (
                                <span className="unread bg-danger position-absolute small">
                                {unreadCount < 10 ? unreadCount : '9+'}
                            </span>
                            )}
                        </div>
                    </NavLink>

                </header>
                <Nav vertical className="px-0" style={{paddingBottom: '100px'}}>
                    {buildMenu(user.role).map(({link, icon, menuText, checkEquipmentStatus}, index) => (
                        <NavItem key={index} active={checkActive(link)} className="mb-1">
                            <NavLink to={link}
                                     className="nav-link text-white d-flex align-items-center justify-content-between d-md-none">
                                <span className={`fas fa-fw me-2 text-primary ${icon}`}/>
                                <span className="flex-grow-1">{menuText}</span>
                                { checkEquipmentStatus &&  getEquipmentStatus()}
                            </NavLink>
                            <NavLink to={link}  onClick={toggle}
                                     className="nav-link text-white d-none d-md-flex align-items-center justify-content-between">
                                <span className={`fas fa-fw me-2 text-primary ${icon}`} />
                                <span className="flex-grow-1">{menuText}</span>
                                {organization && checkEquipmentStatus &&  getEquipmentStatus()}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>

                <div className="sidebar-footer text-center mt-5 position-fixed mt-auto bottom-0">
                    <Button
                        color="link"
                        className="text-decoration-none text-white"
                        onClick={toggleVersionModal}
                    >
                        <span className="version"><strong>v{version}</strong> | <DateTimeDisplay/></span>
                    </Button>
                    <br/>
                    <Button
                        color="link"
                        className="logout text-white text-decoration-none"
                        onClick={handleLogout}
                    >
                        <span className="fas fa-sign-out me-2"/>
                        LOGOUT
                    </Button>
                    {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                        <div className="bg-danger text-center">
                            {process.env.REACT_APP_ENVIRONMENT.toUpperCase()}
                        </div>
                    )}
                    {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
                        <div className="bg-warning text-center">
                            {process.env.REACT_APP_ENVIRONMENT.toUpperCase()}
                        </div>
                    )}
                </div>

                <EditModal
                    isOpen={editModal.open}
                    toggle={() => setEditModal(prev => ({...prev, open: !prev.open}))}
                    dataType={editModal.dataType}
                    label={editModal.label}
                    value={editModal.value}
                    onSave={onSaveEditModal}
                    mediaType={editModal.mediaType}
                />

                <Modal isOpen={versionModal}>
                    <ModalHeader toggle={toggleVersionModal} tag="h1">
                        BEL AIR CINEMA
                    </ModalHeader>
                    <ModalBody>
                        <h4 className="text-primary text-uppercase">Frontend version</h4>
                        <p>v{version}</p>

                        <h4 className="text-primary text-uppercase">Backend Information</h4>
                        <p>
                            <strong>Version:</strong> {apiRoot?.version}<br/>
                            <strong>Uptime:</strong> {apiRoot?.uptime}<br/>
                            <strong>WAN:</strong> {apiRoot?.internetConnectivity ? 'Connected' : 'Disconnected'}<br/>
                            <strong>Last WAN state change:</strong> {apiRoot?.lastConnectivityUpdated ? new Date(apiRoot.lastConnectivityUpdated).toLocaleString() : 'N/A' }<br/>
                        </p>

                        {organization && (
                            <Fragment>
                                <h4 className="text-primary text-uppercase">Cronjob version</h4>
                                <p>
                                    {organization?.lastCronjobVersion || 'No Cronjob information'}<br/>
                                    Last synced: {organization.lastSynced}
                                </p>
                            </Fragment>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" outline onClick={() => dispatch(getApiInformation())}>
                            <span className="fas fa-refresh me-2" /> Refresh version
                        </Button>
                        <Button color="primary" onClick={toggleVersionModal}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </Collapse>
);
};

export default SideMenu;
