import React, {useState} from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const TierSwitch = ({ onChange, value = ['mid'], short = false, propName = 'tier', multiple = false }) => {
    const [selectedTier, setSelectedTier] = useState(value ? value : multiple ? [] : 'mid');

    const handleSelect = (newValue) => {
        let updatedValue = newValue;

        if (multiple) {
            updatedValue = selectedTier.includes(newValue)
                ? selectedTier.filter((v) => v !== newValue)
                : [...selectedTier, newValue];
        }

        setSelectedTier(updatedValue);
        if (onChange) {
            onChange({ target: { name: propName, value: updatedValue }});
        }
    };

    const isActive = (tierValue) => {
        return multiple ? selectedTier.includes(tierValue) : selectedTier === tierValue;
    };

    return (
        <ButtonGroup style={{ minWidth: '250px' }}>
            <Button
                className="text-uppercase"
                style={{ maxWidth: '290px' }}
                color="primary"
                outline={!isActive('mid')}
                onClick={() => handleSelect('mid')}
                title="Mid"
            >
                <span className={`fas fa-medal ${!short && 'me-2'}`}/>
                {!short && "Mid"}
                {isActive('mid') && <span className="fas fa-check-circle text-black ps-2 "/>}
            </Button>
            <Button
                className="text-uppercase"
                style={{ maxWidth: '290px' }}
                color="primary"
                outline={!isActive('premium')}
                onClick={() => handleSelect('premium')}
                title="Premium"
            >
                <span className={`fas fa-gem ${!short && 'me-2'}`}/>
                {!short && "Premium"}
                {isActive('premium') && <span className="fas fa-check-circle text-black ps-2 "/>}
            </Button>
        </ButtonGroup>
    );
};

export default TierSwitch;
