import React from "react";
import {Row, Col} from 'reactstrap';
import {formatCredits} from '../../utils/credits';

import './OrganizationCard.scss';
import TierBadge from "../TierBadge";
import Avatar from '../Avatar';

const OrganizationCard = ({ organization }) => {

    const systemStatusIcon = () => {
        const mediablockStatus = organization?.mediablock?.status === 'Connected' && organization?.mediablock?.state === 'Enabled'
        const bacserverStatus = organization?.bacServer?.gofilexDaemonsAlfred === 'online' && organization?.bacServer?.gofilexDaemonsFrank === 'online'
        const tmsStatus = organization?.tmsSettings?.status === 'running'

            return ( <div className="ms-3">
                        <span className={`fas fa-cube px-1 fa-xs icons-${mediablockStatus ? 'on': 'off'}`} />
                        <span className={`fas fa-server px-1 fa-xs icons-${bacserverStatus ? 'on': 'off'}`} />
                        <small className={`px-1 text-small icons-${tmsStatus ? 'on': 'off'}`}>TMS</small>
                    </div>
            )
    };
    return (
        <Row className="organization-card-component py-3 mx-0 my-3">
            <Col xs="auto">
                <div className="position-relative">
                    <Avatar imageUrl={organization.logo?.imageUrl} name={organization.name} size={70} />
                </div>
            </Col>
            <Col>
                <div className="title text-uppercase text-primary d-flex flex-column flex-lg-row justify-content-between">
                    <div>
                        {organization.name}
                    </div>
                    <div className=" flex-grow-1">
                        {systemStatusIcon()}
                    </div>

                    <TierBadge tier={organization.tier}/>
                </div>
                <div className="organization-credits mt-2">
                    <span className="fas fa-coins me-2 text-primary"/>
                    {formatCredits(organization.creditAmount)} credits

                    {organization.isYacht && (
                        <span>
                            <span className="fas fa-ship ms-4 me-2 text-primary"/> Yacht
                        </span>
                    )}

                    {organization.lastSynced && (
                        <span>
                            <span className="fas fa-refresh ms-4 me-2 text-primary"/>
                            Last synced: {new Date(organization.lastSynced).toLocaleString()}
                        </span>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default OrganizationCard;
