import React, {Fragment, useState} from 'react';
import {Button, FormGroup, Label, Input, ModalFooter, ModalBody} from 'reactstrap';

const tmsFields = [
    { field: 'tmsCinemaId', label: 'TMS Cinema ID', icon: 'fa-file' },
    { field: 'tmsRoomId', label: 'TMS Room ID', icon: 'fa-file' },
    { field: 'tmsPlayerId', label: 'TMS Player ID', icon: 'fa-file' },
    { field: 'tmsLMSId', label: 'TMS LMS id', icon: 'fa-file' },
    { field: 'masterDataApiBaseUrl', label: 'Master Data API Base Url', icon: 'fa-link' },
    { field: 'scsApiBaseUrl', label: 'SCS API Base Url', icon: 'fa-link' },
]

const TmsSettings = ({ initialSettings, onSave }) => {
    const [tmsSettings, setTmsSettings] = useState(initialSettings);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTmsSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(tmsSettings);
    };

    return (
        <Fragment>
            <ModalBody>
                {tmsFields.map(({ field, label, icon }) => (
                    <FormGroup key={field}>
                        <Label for="field">
                            <span className={`fas me-2 fa-fw text-primary ${icon}`} title={label} />
                            {label}
                        </Label>
                        <Input
                            id={field}
                            name={field}
                            placeholder={label}
                            value={tmsSettings[field]}
                            onChange={handleChange}
                        />
                    </FormGroup>
                ))}
            </ModalBody>

            <ModalFooter>
                <Button onClick={handleSubmit} color="primary" className="float-end">Save Changes</Button>
            </ModalFooter>
        </Fragment>
    );
};

export default TmsSettings;
