import React, {Fragment, useEffect, useState} from 'react';
import { Container, Row, Col, Button, ModalHeader, ModalBody, ModalFooter, Modal, ListGroup, ListGroupItem, ButtonGroup, Label, Badge } from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {fetchMovieVersions} from '../../slices/movie-versions.slice';
import SearchBar from '../../components/SearchBar';
import {imgUrl} from '../../utils/api-request';
import {addPlaylist, fetchPlaylists} from '../../slices/playlists.slice';
import {toast} from 'react-toastify';
import {NavLink, useNavigate} from 'react-router-dom';
import Loading from '../../components/Loading';
import './Playlists.scss';

const Playlists = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token);
    const versionList = useSelector((state) => state.movieVersions.list);
    const { status, list: playlists} = useSelector((state) => state.playlists);
    const [modal, setModalOpen] = useState(false);
    const [searchValue, setSearchInput] = useState('');
    const [filteredValues, setFilteredValues] = useState(versionList);
    const [playlistType, setPlaylistType] = useState(null);
    const [selectedVersion, setSelectedVersion] = useState(null);

    const toggle = () => {
        setModalOpen(!modal);
        setPlaylistType(null);
        setSelectedVersion(null);
    };

    useEffect(() => {
        dispatch(fetchMovieVersions({ token }));
        dispatch(fetchPlaylists())
    }, [dispatch, token]);

    useEffect(() => {
        setFilteredValues(versionList);
    }, [versionList]);

    const handleFilter = event => {
        event.preventDefault();
        setSearchInput(event.target.value);
        const searchTerm = event.target.value.toLowerCase();
        setFilteredValues(versionList.filter(({ movie, name }) => {
            return name.toLowerCase().includes(searchTerm) ||
                movie.title.toLowerCase().includes(searchTerm);
        }));
    };

    const handlePlaylistType = type => e => {
        setPlaylistType(type);
    };

    const playlistsByKind = () => {
        return filteredValues.filter(playlist => {
            return !playlist.assetDetails || playlist.assetDetails.contentKind === playlistType;
        })
    };

    const handleSubmit = async () => {
        const { movie } = versionList.find(({_id}) => selectedVersion === _id);

        const playlist = {
            movie: movie._id,
            movieVersions: [selectedVersion],
            type: playlistType,
        };
        const promise = dispatch(addPlaylist(playlist)).unwrap()

        toast.promise(promise, {
            pending: 'Adding playlist',
            success: 'Playlist added successfully',
            error: 'Error adding playlist',
        });

        const { _id } = await promise;
        navigate(`/playlists/${_id}`);
    };

    if (['idle', 'loading'].includes(status)) {
        return <Loading fullHeight />
    }

    return (
        <div className="playlist-index-view p-3 min-vh-100">
            <Container fluid>
                <Row className="mb-3">
                    <Col>
                        <h1>
                            Show playlists
                            <Button color="link" onClick={toggle}>
                                <span className="fas fa-plus me-2"/>Create Show playlist
                            </Button>
                        </h1>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <ListGroup>
                            {playlists.map(({ _id, movie, movieVersions, type, status }) => (
                                <NavLink
                                    key={_id}
                                    to={`/playlists/${_id}`}
                                    style={{ cursor: 'pointer', borderRadius: '0' }}
                                    className="list-group-item border-0 border-start border-primary border-2 position-relative
                                    text-white d-flex justify-content-start gap-3 align-items-center mb-2"
                                >
                                    <img src={imgUrl(movie?.poster?.imageUrl)} width={50} alt={movie.title}/>
                                    <div className="flex-grow-1">
                                        {movie.title} | {status} <br/>
                                        <small>{movieVersions[0]?.name}</small>
                                    </div>
                                    <Badge color="primary" pill className="text-black pe-2">
                                        <span className="fas fa-play-circle me-2"></span>
                                        {movieVersions.length}
                                    </Badge>
                                    <Badge color="primary" className="text-black text-uppercase">
                                        {type}
                                    </Badge>
                                </NavLink>
                            ))}
                        </ListGroup>

                        {status === 'success' && playlists.length === 0 && (
                            <div className="text-uppercase text-expand">
                                - No playlists yet -
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>

            <Modal isOpen={modal} toggle={toggle} scrollable size="lg" className="playlist-select-version">
                <ModalHeader tag="h5" toggle={toggle} className="text-primary">
                    Create playlist
                </ModalHeader>
                <ModalBody>
                    <Label className="d-block">
                        <span className="fas fa-photo-film me-2 text-primary" />
                        Playlist type
                    </Label>
                    <ButtonGroup className="mb-3" size="sm">
                        <Button
                            className="px-3"
                            color="primary"
                            outline={playlistType !== 'feature'}
                            onClick={handlePlaylistType('feature')}
                        >
                            <span className="fas fa-film me-2" /> Feature</Button>
                        <Button
                            className="px-3"
                            color="primary"
                            outline={playlistType !== 'trailer'}
                            onClick={handlePlaylistType('trailer')}
                        >
                            <span className="fa fa-circle-play me-2" /> Trailer</Button>
                    </ButtonGroup>

                    {playlistType && (
                        <Fragment>
                            <SearchBar onChange={handleFilter} initialValue={searchValue} />
                            <ListGroup>
                                {playlistsByKind().map(({ _id, name, movie, assetDetails }) => (
                                    <ListGroupItem
                                        onClick={() => setSelectedVersion(_id)}
                                        key={_id}
                                        style={{ cursor: 'pointer', borderRadius: '0' }}
                                        active={selectedVersion === _id}
                                        className="border-0 border-start border-primary border-2 position-relative
                                            text-white d-flex justify-content-start gap-3 align-items-center mb-2"
                                    >
                                        <img src={imgUrl(movie.poster?.imageUrl)} width={50} alt=""/>
                                        <div className="flex-grow-1">
                                            {movie.title} <br/>
                                            <small>{name}</small>
                                        </div>
                                        {assetDetails && (
                                            <Badge color="primary" className="text-black text-uppercase">{assetDetails.contentKind}</Badge>
                                        )}
                                        {selectedVersion === _id && (
                                            <div className="position-absolute top-0 end-0 mt-2 me-3 small">
                                                Selected <span className="fas fa-check-circle text-success"/>
                                            </div>
                                        )}
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </Fragment>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={!(playlistType && selectedVersion)} onClick={handleSubmit}>
                        Configure playlist
                        <span className="fas fa-chevron-right ms-2" />
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default Playlists;
