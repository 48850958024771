const currencies = [
    {
        "value": "AFN",
        "label": "Afghan Afghani"
    },
    {
        "value": "ALL",
        "label": "Albanian Lek"
    },
    {
        "value": "DZD",
        "label": "Algerian Dinar"
    },
    {
        "value": "ARS",
        "label": "Argentine Peso"
    },
    {
        "value": "AMD",
        "label": "Armenian Dram"
    },
    {
        "value": "AUD",
        "label": "Australian Dollar"
    },
    {
        "value": "AZN",
        "label": "Azerbaijani Manat"
    },
    {
        "value": "BHD",
        "label": "Bahraini Dinar"
    },
    {
        "value": "BDT",
        "label": "Bangladeshi Taka"
    },
    {
        "value": "BYN",
        "label": "Belarusian Ruble"
    },
    {
        "value": "BZD",
        "label": "Belize Dollar"
    },
    {
        "value": "BOB",
        "label": "Bolivian Boliviano"
    },
    {
        "value": "BAM",
        "label": "Bosnia-Herzegovina Convertible Mark"
    },
    {
        "value": "BWP",
        "label": "Botswanan Pula"
    },
    {
        "value": "BRL",
        "label": "Brazilian Real"
    },
    {
        "value": "GBP",
        "label": "British Pound"
    },
    {
        "value": "BND",
        "label": "Brunei Dollar"
    },
    {
        "value": "BGN",
        "label": "Bulgarian Lev"
    },
    {
        "value": "BIF",
        "label": "Burundian Franc"
    },
    {
        "value": "KHR",
        "label": "Cambodian Riel"
    },
    {
        "value": "CAD",
        "label": "Canadian Dollar"
    },
    {
        "value": "CVE",
        "label": "Cape Verdean Escudo"
    },
    {
        "value": "XAF",
        "label": "Central African CFA Franc"
    },
    {
        "value": "CLP",
        "label": "Chilean Peso"
    },
    {
        "value": "CNY",
        "label": "Chinese Yuan"
    },
    {
        "value": "COP",
        "label": "Colombian Peso"
    },
    {
        "value": "KMF",
        "label": "Comorian Franc"
    },
    {
        "value": "CDF",
        "label": "Congolese Franc"
    },
    {
        "value": "CRC",
        "label": "Costa Rican Colón"
    },
    {
        "value": "HRK",
        "label": "Croatian Kuna"
    },
    {
        "value": "CZK",
        "label": "Czech Koruna"
    },
    {
        "value": "DKK",
        "label": "Danish Krone"
    },
    {
        "value": "DJF",
        "label": "Djiboutian Franc"
    },
    {
        "value": "DOP",
        "label": "Dominican Peso"
    },
    {
        "value": "EGP",
        "label": "Egyptian Pound"
    },
    {
        "value": "ERN",
        "label": "Eritrean Nakfa"
    },
    {
        "value": "EEK",
        "label": "Estonian Kroon"
    },
    {
        "value": "ETB",
        "label": "Ethiopian Birr"
    },
    {
        "value": "EUR",
        "label": "Euro"
    },
    {
        "value": "GEL",
        "label": "Georgian Lari"
    },
    {
        "value": "GHS",
        "label": "Ghanaian Cedi"
    },
    {
        "value": "GTQ",
        "label": "Guatemalan Quetzal"
    },
    {
        "value": "GNF",
        "label": "Guinean Franc"
    },
    {
        "value": "HNL",
        "label": "Honduran Lempira"
    },
    {
        "value": "HKD",
        "label": "Hong Kong Dollar"
    },
    {
        "value": "HUF",
        "label": "Hungarian Forint"
    },
    {
        "value": "ISK",
        "label": "Icelandic Króna"
    },
    {
        "value": "INR",
        "label": "Indian Rupee"
    },
    {
        "value": "IDR",
        "label": "Indonesian Rupiah"
    },
    {
        "value": "IRR",
        "label": "Iranian Rial"
    },
    {
        "value": "IQD",
        "label": "Iraqi Dinar"
    },
    {
        "value": "ILS",
        "label": "Israeli New Shekel"
    },
    {
        "value": "JMD",
        "label": "Jamaican Dollar"
    },
    {
        "value": "JPY",
        "label": "Japanese Yen"
    },
    {
        "value": "JOD",
        "label": "Jordanian Dinar"
    },
    {
        "value": "KZT",
        "label": "Kazakhstani Tenge"
    },
    {
        "value": "KES",
        "label": "Kenyan Shilling"
    },
    {
        "value": "KWD",
        "label": "Kuwaiti Dinar"
    },
    {
        "value": "LVL",
        "label": "Latvian Lats"
    },
    {
        "value": "LBP",
        "label": "Lebanese Pound"
    },
    {
        "value": "LYD",
        "label": "Libyan Dinar"
    },
    {
        "value": "LTL",
        "label": "Lithuanian Litas"
    },
    {
        "value": "MOP",
        "label": "Macanese Pataca"
    },
    {
        "value": "MKD",
        "label": "Macedonian Denar"
    },
    {
        "value": "MGA",
        "label": "Malagasy Ariary"
    },
    {
        "value": "MYR",
        "label": "Malaysian Ringgit"
    },
    {
        "value": "MUR",
        "label": "Mauritian Rupee"
    },
    {
        "value": "MXN",
        "label": "Mexican Peso"
    },
    {
        "value": "MDL",
        "label": "Moldovan Leu"
    },
    {
        "value": "MAD",
        "label": "Moroccan Dirham"
    },
    {
        "value": "MZN",
        "label": "Mozambican Metical"
    },
    {
        "value": "MMK",
        "label": "Myanmar Kyat"
    },
    {
        "value": "NAD",
        "label": "Namibian Dollar"
    },
    {
        "value": "NPR",
        "label": "Nepalese Rupee"
    },
    {
        "value": "TWD",
        "label": "New Taiwan Dollar"
    },
    {
        "value": "NZD",
        "label": "New Zealand Dollar"
    },
    {
        "value": "NIO",
        "label": "Nicaraguan Córdoba"
    },
    {
        "value": "NGN",
        "label": "Nigerian Naira"
    },
    {
        "value": "NOK",
        "label": "Norwegian Krone"
    },
    {
        "value": "OMR",
        "label": "Omani Rial"
    },
    {
        "value": "PKR",
        "label": "Pakistani Rupee"
    },
    {
        "value": "PAB",
        "label": "Panamanian Balboa"
    },
    {
        "value": "PYG",
        "label": "Paraguayan Guarani"
    },
    {
        "value": "PEN",
        "label": "Peruvian Sol"
    },
    {
        "value": "PHP",
        "label": "Philippine Peso"
    },
    {
        "value": "PLN",
        "label": "Polish Zloty"
    },
    {
        "value": "QAR",
        "label": "Qatari Rial"
    },
    {
        "value": "RON",
        "label": "Romanian Leu"
    },
    {
        "value": "RUB",
        "label": "Russian Ruble"
    },
    {
        "value": "RWF",
        "label": "Rwandan Franc"
    },
    {
        "value": "SAR",
        "label": "Saudi Riyal"
    },
    {
        "value": "RSD",
        "label": "Serbian Dinar"
    },
    {
        "value": "SGD",
        "label": "Singapore Dollar"
    },
    {
        "value": "SOS",
        "label": "Somali Shilling"
    },
    {
        "value": "ZAR",
        "label": "South African Rand"
    },
    {
        "value": "KRW",
        "label": "South Korean Won"
    },
    {
        "value": "LKR",
        "label": "Sri Lankan Rupee"
    },
    {
        "value": "SDG",
        "label": "Sudanese Pound"
    },
    {
        "value": "SEK",
        "label": "Swedish Krona"
    },
    {
        "value": "CHF",
        "label": "Swiss Franc"
    },
    {
        "value": "SYP",
        "label": "Syrian Pound"
    },
    {
        "value": "TZS",
        "label": "Tanzanian Shilling"
    },
    {
        "value": "THB",
        "label": "Thai Baht"
    },
    {
        "value": "TOP",
        "label": "Tongan Paʻanga"
    },
    {
        "value": "TTD",
        "label": "Trinidad & Tobago Dollar"
    },
    {
        "value": "TND",
        "label": "Tunisian Dinar"
    },
    {
        "value": "TRY",
        "label": "Turkish Lira"
    },
    {
        "value": "USD",
        "label": "US Dollar"
    },
    {
        "value": "UGX",
        "label": "Ugandan Shilling"
    },
    {
        "value": "UAH",
        "label": "Ukrainian Hryvnia"
    },
    {
        "value": "AED",
        "label": "United Arab Emirates Dirham"
    },
    {
        "value": "UYU",
        "label": "Uruguayan Peso"
    },
    {
        "value": "UZS",
        "label": "Uzbekistani Som"
    },
    {
        "value": "VEF",
        "label": "Venezuelan Bolívar"
    },
    {
        "value": "VND",
        "label": "Vietnamese Dong"
    },
    {
        "value": "XOF",
        "label": "West African CFA Franc"
    },
    {
        "value": "YER",
        "label": "Yemeni Rial"
    },
    {
        "value": "ZMK",
        "label": "Zambian Kwacha"
    },
    {
        "value": "ZWL",
        "label": "Zimbabwean Dollar"
    }
];

export const getCurrencyInfo = currency => {
    return currencies.find(({ value }) => value === currency);
};

export default currencies;
