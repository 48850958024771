import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Label,
    Input,
    Collapse,
    Badge,
} from 'reactstrap';

const OrderExportModal = ({ isOpen, toggle, onExport, defaultSelectedFields = [] }) => {
    const [selectedFields, setSelectedFields] = useState([]);
    const [openSubFields, setOpenSubFields] = useState({
        organization: true,
        movie: true,
        version: true,
    });

    const fields = [
        'status',
        'statusWorkflow',
        'requestStatus',
        'orderType',
        'isTrailer',
        'discount',
        'price',
        'transaction',
        'createdBy',
        'screeningDate',
    ];

    const subFields = {
        organization: [
            'name',
            'creditAmount',
            'country',
            'isYacht',
            'gofilexCompanyId',
            'lastSynced',
            'lastCronjobVersion',
        ],
        movie: ['title', 'releaseYear', 'status', 'genres', 'youtubeTrailerId'],
        version: [
            'name',
            'price',
            'releaseDate',
            'defaultHirePeriod',
            'hirePeriodType',
            'isMovieOnRequest',
            'tier',
            'uuid',
        ],
    };

    const toggleSubField = (key) => {
        setOpenSubFields((prev) => ({ ...prev, [key]: !prev[key] }));
    };

    const handleCheckboxChange = (field) => {
        setSelectedFields((prev) =>
            prev.includes(field)
                ? prev.filter((item) => item !== field)
                : [...prev, field]
        );
    };

    const handleExport = filetype => () => {
        onExport(selectedFields, filetype);
        toggle();
    };

    const handleSubFieldChange = (parentField, subField) => {
        const fullField = `${parentField}.${subField}`;
        handleCheckboxChange(fullField);
    };

    useEffect(() => {
        if (isOpen) {
            setSelectedFields(defaultSelectedFields);
        }
    }, [isOpen, defaultSelectedFields]);

    const getSelectedSubFieldCount = (parentField) => {
        return subFields[parentField].filter((subField) =>
            selectedFields.includes(`${parentField}.${subField}`)
        ).length;
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} scrollable>
            <ModalHeader toggle={toggle}>Select Fields to Export</ModalHeader>
            <ModalBody>
                <div className="d-flex justify-content-between mb-3">
                    <Button
                        color="link"
                        className="px-0 text-decoration-none"
                        size="sm"
                        onClick={() => setSelectedFields([
                            ...fields,
                            ...Object.keys(subFields).flatMap((parentField) =>
                                subFields[parentField].map((subField) => `${parentField}.${subField}`),
                            ),
                        ])}
                    >
                        <span className="fas fa-check-square me-2" />
                        Select All
                    </Button>
                    <Button
                        color="link"
                        className="px-0 text-decoration-none"
                        size="sm"
                        onClick={() => setSelectedFields([])}
                    >
                        <span className="fas fa-times-circle me-2" />
                        Clear All
                    </Button>
                </div>

                {fields.map((field) => (
                    <FormGroup key={field} check>
                        <Label check>
                            <Input
                                type="checkbox"
                                value={field}
                                checked={selectedFields.includes(field)}
                                onChange={() => handleCheckboxChange(field)}
                            />
                            {field}
                        </Label>
                    </FormGroup>
                ))}

                {Object.keys(subFields).map((parentField) => (
                    <div key={parentField}>
                        <Label check>
                            <Badge color="primary" pill className="me-2 text-black">
                                {getSelectedSubFieldCount(parentField)}
                            </Badge>
                            {parentField}{' '}

                        </Label>
                        <Button
                            color="link"
                            size="sm"
                            className="text-decoration-none"
                            onClick={() => toggleSubField(parentField)}
                        >
                            {openSubFields[parentField] ? 'Collapse' : 'Expand'}
                        </Button>
                        <Collapse isOpen={openSubFields[parentField]}>
                            {subFields[parentField].map((subField) => {
                                const fullField = `${parentField}.${subField}`;
                                return (
                                    <FormGroup key={subField} check style={{marginLeft: '20px'}}>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                value={fullField}
                                                checked={selectedFields.includes(fullField)}
                                                onChange={() =>
                                                    handleSubFieldChange(parentField, subField)
                                                }
                                            />
                                            {subField}
                                        </Label>
                                    </FormGroup>
                                );
                            })}
                        </Collapse>
                    </div>
                ))}
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
                <Button color="primary" outline onClick={toggle}>
                    <span className="fas fa-times me-2"/>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleExport('csv')}>
                    <span className="fas fa-download me-2"/>
                    Export CSV
                </Button>
                <Button color="primary" onClick={handleExport('xlsx')}>
                    <span className="fas fa-file-excel me-2"/>
                    Export Excel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default OrderExportModal;
