import React, {useEffect, useState} from 'react';
import {Button} from 'reactstrap';
import {toast} from 'react-toastify';
import {apiRequest} from '../../utils/api-request';
import './ImageUpload.scss'
import { fileUploadChecks } from '../../utils/fileUploadCheck';

const ImageUploadComponent = ({ mediaType = 'user-avatar', onSuccess, startImageUrl = '', onImageChange, showImgPreview, setShowImgPreview, isEditing }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(startImageUrl);
    const [isDraggingOver, setIsDraggingOver] = useState(false);

    const handleFileChange = async (e) => {
        const file = e.target.files[0]
        const passChecks = await fileUploadChecks(file)
        if(!passChecks){
            toast(`Only image files are accepted`);
        }
        setSelectedFile(file);
        setImagePreviewUrl(URL.createObjectURL(file));
        if (onImageChange) {
            onImageChange(e);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDraggingOver(true);
    };

    const handleDragLeave = () => {
        setIsDraggingOver(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const passChecks = await fileUploadChecks(file)
        if(!passChecks){
            toast(`Only image files are accepted`);
        }
        setSelectedFile(file);
        setImagePreviewUrl(URL.createObjectURL(file));
        setIsDraggingOver(false);
        if (onImageChange) {
            onImageChange(e);
        }
    };
    const handleUpload = async () => {

        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('mediaType', mediaType);

        try {
            const promise = apiRequest(`/media/${mediaType}`, 'POST', formData);
            toast.promise(promise, {
                pending: 'Uploading',
                success: 'Upload successful',
                error: `Error uploading ${mediaType}`,
            });
            const response = await promise;
            onSuccess(response.media);

        } catch (error) {
            console.error('Error uploading image:', error.message);
        }
    };

    useEffect(()=>{
        if (showImgPreview === 'reset'){
            setShowImgPreview(null)
        }else if(!isEditing){
            setImagePreviewUrl(null)
            setSelectedFile(null)
        }
    },[setShowImgPreview, showImgPreview, isEditing])

    return (
        <div className="position-relative">
            <input
                type="file"
                onChange={handleFileChange}
                className="d-none"
                id="file-input"
            />

            <div
                className={`drag-area p-4 text-center mb-3 ${isDraggingOver && 'border-primary'}`}

                onClick={() => document.getElementById('file-input').click()}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                {imagePreviewUrl ? (
                    <img src={imagePreviewUrl} alt="Preview" />
                ) : (
                    <div>
                        <div className="mb-3"><span className="fas fa-plus fa-2x"/></div>
                        Click or drag & drop to upload
                    </div>
                )}
            </div>

            <Button
                color="primary"
                className="float-end"
                onClick={handleUpload}
                disabled={!selectedFile}
            >
                Upload Image
            </Button>
        </div>
    );
};

export default ImageUploadComponent;
