import React, {Fragment, useEffect, useState} from 'react';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {
    Badge,
    Button,
    ButtonGroup,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {deleteMovie, fetchMovie, fetchMovies, updateMovie} from '../../../slices/movies.slice';
import {fetchCrewMembers} from '../../../slices/crew.slice';
import {imgUrl} from '../../../utils/api-request';
import YoutubeTrailer from '../../../components/YoutubeTrailer';
import CrewCard from '../../../components/CrewCard';
import AddCrewMember from '../../../components/CrewCard/AddCrewMember';
import EditModal from '../../../components/EditModal';
import VersionList from '../VersionList';
import genresList from '../genres.json';
import DeleteButton from '../../../components/DeleteButton';
import '../MoviePage.scss';

const defaultCover = 'https://picsum.photos/1480/400';

const MoviePageBAC = () => {
    const { movieId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const crew = useSelector((state) => state.crew.list);
    const crewLoadStatus = useSelector((state) => state.crew.status);
    const [modalTrailer, setModalTrailer] = useState(false);
    const currentUser = useSelector(state => state.auth.user);
    const { movie, movieStatus } = useSelector(state => state.movies);
    const [publishModal, setPublishModal] = useState(false);
    const rolesList = ['Actor', 'Director', 'Producer', 'Writer'];
    const [editModal, setEditModal] = useState({
        open: false,
        field: '',
        dataType: 'text',
        label: '',
        value: '',
        list: [],
    });

    const toggleTrailer = () => {setModalTrailer(!modalTrailer)};
    const togglePublish = () => setPublishModal(!publishModal);
    const toggleEditModal = (field, dataType, label, value, list) => {
        setEditModal({
            open: true,
            field,
            dataType,
            label,
            value,
            list,
        });
    };

    useEffect(() => {
        dispatch(fetchMovie({ movieId }));
        dispatch(fetchCrewMembers());
    }, [dispatch, movieId]);

    const handleImageUploadClick = type => e => {
        e.preventDefault();
        if (!canEdit()) return;
        if (!['still', 'poster', 'logo'].includes(type)) throw new Error(`${type} is invalid`)
        toggleEditModal(type, 'image', type, movie[type]?.imageUrl, true);
    };

    const handleAdd = type => selectedMembers => {
        const credits = selectedMembers.map(member => ({
            member,
            type,
            roles: type === 'cast' ? ['Actor'] : [],
            roleName: '',
        }));
        const updatedMovie = { ...movie, credits: [...movie.credits, ...credits] };
        const promise = dispatch(updateMovie({ movieId, body: updatedMovie })).unwrap();
        toast.promise(promise, {
            pending: 'Saving...',
            success: 'Saved successfully',
            error: 'Error saving',
        });
    };

    const handleEdit = editedMember => {
        const creditIndex = movie.credits.findIndex(credit => credit._id === editedMember._id);
        let editedMovie = structuredClone(movie);
        editedMovie.credits[creditIndex].roles = editedMember.roles;
        editedMovie.credits[creditIndex].roleName = editedMember.roleName;
        const promise = dispatch(updateMovie({ movieId, body: editedMovie })).unwrap();
        toast.promise(promise, {
            pending: 'Saving...',
            success: 'Saved successfully',
            error: 'Error saving',
        });
    };

    const handleRemove = removedMemberId => {
        const filteredCredits = movie.credits.filter(credit => credit._id !== removedMemberId);
        const updatedMovie = {
            ...movie,
            credits: filteredCredits
        };
        const promise = dispatch(updateMovie({ movieId, body: updatedMovie })).unwrap();
        toast.promise(promise, {
            pending: 'Saving...',
            success: 'Saved successfully',
            error: 'Error saving',
        });
    };

    const onSaveEditModal = (newValue) => {
        let updatedMovie = {
            ...movie,
        };

        if (['still', 'poster', 'logo'].includes(editModal.field)) {
            updatedMovie[editModal.field] = newValue._id;
        } else {
            updatedMovie[editModal.field] = newValue;
        }

        const promise = dispatch(updateMovie({ movieId, body: updatedMovie })).unwrap();
        toast.promise(promise, {
            pending: `Updating ${editModal.label}...`,
            success: `${editModal.label} updated`,
            error: `Error updating ${editModal.label} field`,
        });

        promise.then(() => {
            setEditModal(prev => ({ ...prev, open: false }));
        });
    };

    const onSaveTrailer = (trailerURL) => {
        if (trailerURL) {
            const updatedMovie = {
                ...movie,
                youtubeTrailerId: trailerURL
            };
            const promise = dispatch(updateMovie({ movieId, body: updatedMovie })).unwrap();
            toast.promise(promise, {
                pending: 'Saving...',
                success: 'Saved successfully',
                error: 'Error saving',
            });
            promise.then(() => toggleTrailer());
        }
    };

    const publishMovie = () => {
        const updatedMovie = {
            ...movie,
            status: 'published',
        };
        const promise = dispatch(updateMovie({ movieId, body: updatedMovie })).unwrap();
        toast.promise(promise, {
            pending: `Publishing ${movie.title}...`,
            success: `${movie.title} published successfully`,
            error: `Error publishing ${movie.title}`,
        });
        promise.then(() => {
            togglePublish();
            dispatch(fetchMovies());
        });
    };

    const handleMovieDelete = async () => {
        const promise = dispatch(deleteMovie({ movieId: movie._id })).unwrap();
        toast.promise(promise, {
            pending: 'Deleting movie',
            success: 'Movie and versions deleted successfully',
            error: 'Error deleting the movie',
        });
        await promise;
        navigate('/movies');
    };

    const canEdit = () => ['root', 'bac-admin', 'bac-user'].includes(currentUser?.role);

    return movieStatus === 'success' && currentUser && (
        <div className="movie-detail-view bg-black min-vh-100 pb-4 can-edit">

            <NavLink to="/movies" className="text-decoration-none text-primary p-3 position-absolute z-3">
                <span className="fas fa-chevron-left me-2"/>
                Movies
            </NavLink>

            <div className="highlight-film">
                <div
                    className="film-cover d-flex flex-column align-items-end"
                    style={{backgroundImage: `url("${imgUrl(movie.still?.imageUrl) || defaultCover}")`}}
                    onClick={handleImageUploadClick('still')}
                    title="Update still"
                >
                    {canEdit() && (
                        <div className="w-100 h-100 justify-content-center align-items-center update-still-info d-flex">
                            <p className="lead fw-bold text-center p-5 text-uppercase">
                                <span className="fas fa-2x fa-panorama mb-3"/><br/>
                                Upload still
                            </p>
                        </div>
                    )}
                    <div className="gradient w-100 mt-auto"></div>
                </div>

                <div className="film-actions ps-3">
                    <div className="film-logo position-relative" onClick={handleImageUploadClick('logo')}
                         title="Update logo">
                        {movie.logo ? (
                            <img
                                alt={movie.title}
                                src={imgUrl(movie.logo.imageUrl)}
                                className="img-fluid"/>
                        ) : canEdit() ? (
                            <h3 className="py-4 mt-5 text-primary text-center text-uppercase fw-bold">
                                Upload logo
                                <span className="ms-2 fas fa-image"/>
                            </h3>
                        ) : (
                            <h3 className="py-4 mt-5 text-primary text-center text-uppercase fw-bold">
                                {movie.title}
                            </h3>
                        )}
                    </div>

                    <div className="icons pb-3 mt-3">
                        <span className="release-year"
                              onClick={() => canEdit() && toggleEditModal('releaseYear', 'number', 'Release Year', movie.releaseYear)}>
                            {movie.releaseYear}
                            {canEdit() && (
                                <span className="ms-2 fas fa-pencil"/>
                            )}
                        </span>

                        <span className="restriction-genre-box ms-3">
                            {movie.genres.map(genre => (
                                <Badge key={genre} color="transparent" className="text-uppercase">{genre}</Badge>
                            ))}
                            {canEdit() && (
                                <Badge
                                    color="transparent"
                                    className="text-uppercase add-genres"
                                    onClick={() => toggleEditModal(
                                        'genres',
                                        'array',
                                        'Genres',
                                        movie.genres,
                                        genresList,
                                        onSaveEditModal
                                    )}
                                >
                                    <span className="fas fa-plus"/> Genres
                                </Badge>
                            )}
                        </span>
                    </div>

                    <ButtonGroup>
                        <Button color="primary" outline onClick={() => navigate(`/orders?movies=${movie.title}`)}>
                            <span className="fas fa-list-alt me-3"/>
                            View orders
                        </Button>

                        <Button color="primary" outline onClick={toggleTrailer} className="ms-3">
                            <span className="fas fa-play me-2"/>
                            Play trailer
                        </Button>

                        <Button color="link" className="text-decoration-none text-white"
                                onClick={handleImageUploadClick('poster')}>
                            <span className="fas fa-file-edit d-block mb-2"/>
                            Poster
                        </Button>

                        {movie.status !== 'published' && (
                            <Fragment>
                                <Button color="link" className="text-decoration-none text-primary"
                                        onClick={togglePublish}>
                                    <span className="fas fa-cloud-upload"/><br/>
                                    <strong>PUBLISH!</strong>
                                </Button>
                                <Modal
                                    isOpen={publishModal}
                                    toggle={togglePublish}
                                >
                                    <ModalHeader tag="h1">{movie.title}</ModalHeader>
                                    <ModalBody className="text-center">
                                        Are you sure you want to publish this movie?
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="d-flex mt-3 w-100 gap-3">
                                            <Button color="primary" outline
                                                    onClick={togglePublish}
                                                    className="flex-grow-1">
                                                <span className="fas fa-times me-2"/>
                                                Not yet
                                            </Button>
                                            <Button color="success" outline onClick={publishMovie}
                                                    className="flex-grow-1">
                                                <span className="fas fa-check me-2"/>
                                                Yes, PUBLISH IT!
                                            </Button>
                                        </div>
                                    </ModalFooter>
                                </Modal>
                            </Fragment>
                        )}
                    </ButtonGroup>

                    <div className="d-lg-flex justify-content-lg-start gap-5">
                        <h1
                            key="movie-title"
                            className="mt-3 movie-title"
                            onClick={() => toggleEditModal('title', 'text', 'Title', movie.title)}
                        >
                            {movie.title}
                            {canEdit() && (
                                <span className="ms-2 fas fa-pencil"/>
                            )}
                        </h1>
                    </div>

                    <p className="movie-synopsis lh-2 pt-3" onClick={() => toggleEditModal('synopsis', 'textarea', 'Synopsis', movie.synopsis)}>
                        {movie.synopsis} <span className="ms-2 fas fa-pencil"/>
                    </p>

                    {movie.poster ? (
                        <img
                            className="logo-img"
                            width={75}
                            alt={movie.title}
                            src={imgUrl(movie.poster.imageUrl)}
                        />
                    ) : ( // TODO: Handle placeholder image when there is no cover
                        <img
                            className="logo-img"
                            width={75}
                            alt={movie.title}
                            src={`https://picsum.photos/seed/${movie.title}/75/112`}
                        />

                    )}
                </div>
            </div>

            <div className="crew-cast-container p-3">
                <h5 className="text-uppercase">Crew</h5>
                <div className="crew-container d-flex flex-wrap">
                    {movie.credits.filter(({type}) => type === 'crew').map(credit => (
                        <CrewCard
                            key={credit._id}
                            type="crew"
                            credit={credit}
                            canEdit={canEdit()}
                            onEdit={handleEdit}
                            onRemove={handleRemove}
                            rolesList={rolesList}
                            showRole={true}
                        />
                    ))}

                    {crewLoadStatus === 'success' && canEdit() && (
                        <AddCrewMember
                            defaultMembers={crew}
                            type="crew"
                            alreadyAddedIds={movie.credits.map(({member}) => member._id)}
                            onAdd={handleAdd('crew')}
                        />
                    )}
                </div>

                <h5 className="text-uppercase mt-3">Cast</h5>
                <div className="cast-container d-flex flex-wrap">
                    {movie.credits.filter(({type}) => type === 'cast').map(credit => (
                        <CrewCard
                            key={credit._id}
                            type="cast"
                            credit={credit}
                            canEdit={canEdit()}
                            onEdit={handleEdit}
                            onRemove={handleRemove}
                            rolesList={rolesList}
                            showRole={false}
                        />
                    ))}

                    {crewLoadStatus === 'success' && canEdit() && (
                        <AddCrewMember
                            defaultMembers={crew}
                            type="cast"
                            alreadyAddedIds={movie.credits.map(({member}) => member._id)}
                            onAdd={handleAdd('cast')}
                        />
                    )}
                </div>
            </div>

            <div className="movie-versions-container p-3">
                <h2 className="text-uppercase">Movie versions</h2>

                <VersionList
                    versions={movie.versions}
                    movie={movie}
                />
            </div>

            <div className="float-end m-3">
                <DeleteButton
                    entityLabel={`movie and its ${movie.versions?.length} versions`}
                    title={movie.title}
                    onClick={handleMovieDelete}
                    typeToConfirm
                />
            </div>

            <div className="clearfix"/>

            <Modal isOpen={modalTrailer} toggle={toggleTrailer} fullscreen modalClassName="trailer">
                <ModalHeader tag="div" toggle={toggleTrailer} className={canEdit() ? 'editable' : 'non-editable'}>
                    {canEdit() && (<h1>{movie.title} trailer</h1>)}
                </ModalHeader>
                <ModalBody>
                    <YoutubeTrailer
                        videoId={movie.youtubeTrailerId}
                        title={movie.title}
                        value={movie.youtubeTrailerId}
                        canEdit={canEdit()}
                        onSave={onSaveTrailer}/>
                </ModalBody>
            </Modal>

            <EditModal
                isOpen={editModal.open}
                toggle={() => setEditModal(prev => ({...prev, open: !prev.open}))}
                dataType={editModal.dataType}
                field={editModal.field}
                label={editModal.label}
                value={editModal.value}
                list={editModal.list}
                onSave={onSaveEditModal}
            />
        </div>
    )
}

export default MoviePageBAC;
