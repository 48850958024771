import React from 'react';
import {Row, Col, Container, Table} from 'reactstrap';

const RolePermissions = () => {
    return (
        <div className="users-view p-3 vh-100">
            <Container fluid>
                <Row className="mb-3">
                    <Col>
                        <h1>Role Permissions</h1>

                        <Table dark hover striped bordered>
                            <thead>
                            <tr>
                                <th>Permission</th>
                                <th>Site Guest</th>
                                <th>Site admin</th>
                                <th>BAC User</th>
                                <th>BAC Admin</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td colSpan={5} className="p-0 m-0"><div className="bg-primary p-2 text-black fw-bold text-uppercase">Users</div></td>
                            </tr>
                            <tr>
                                <td>Create new users</td>
                                <td><span className="fas fa-times mr-2" /></td>
                                <td><span className="fas fa-check mr-2" /></td>
                                <td><span className="fas fa-check mr-2" /></td>
                                <td><span className="fas fa-check mr-2" /></td>
                            </tr>
                            <tr>
                                <td>Update my user</td>
                                <td><span className="fas fa-times mr-2" /></td>
                                <td><span className="fas fa-check mr-2" /></td>
                                <td><span className="fas fa-check mr-2" /></td>
                                <td><span className="fas fa-check mr-2" /></td>
                            </tr>
                            <tr>
                                <td>Update users</td>
                                <td><span className="fas fa-times mr-2" /></td>
                                <td><span className="fas fa-check mr-2" /></td>
                                <td><span className="fas fa-check mr-2" /></td>
                                <td><span className="fas fa-check mr-2" /></td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default RolePermissions;
