import React from "react";
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

const SavePrompt =({blocker})=>{
    return (
        blocker.state === "blocked" ? (<Modal isOpen={true}>
            <ModalBody>You have unsaved changes.</ModalBody>
            <ModalFooter>
                <Button color="primary" value='discard' outline onClick={() => blocker.proceed()}>Leave & discard </Button>
                <Button color="primary" value='save' onClick={() => blocker.reset()}>Stay & save </Button>
            </ModalFooter>
        </Modal>
        ) : ( null)

    )
}

export default SavePrompt