import React, {useState, useEffect} from 'react';
import {ModalBody, Col, Row, Button} from 'reactstrap';
import IpAddressForm from './ipAddressForm';

const bacServerFields = [
    { label: 'FRU Chassis Type', name: 'fruChassisType' },
    { label: 'FRU Chassis Part Number', name: 'fruChassisPartNumber' },
    { label: 'FRU Chassis Serial Number', name: 'fruChassisSerialNumber' },
    { label: 'FRU Board Manufacturing Date/Time', name: 'fruBoardManufacturingDateTime' },
    { label: 'FRU Board Manufacturer', name: 'fruBoardManufacturer' },
    { label: 'FRU Board Product Name', name: 'fruBoardProductName' },
    { label: 'FRU Board Serial Number', name: 'fruBoardSerialNumber' },
    { label: 'FRU Board Part Number', name: 'fruBoardPartNumber' },
    { label: 'FRU Product Manufacturer Name', name: 'fruProductManufacturerName' },
    { label: 'FRU Product Name', name: 'fruProductName' },
    { label: 'FRU Product Part/Model Number', name: 'fruProductPartModelNumber' },
    { label: 'FRU Product Version', name: 'fruProductVersion' },
    { label: 'FRU Product Serial Number', name: 'fruProductSerialNumber' },
    { label: 'Gofilex Daemons Alfred', name: 'gofilexDaemonsAlfred' },
    { label: 'Gofilex Daemons Frank', name: 'gofilexDaemonsFrank' },
];

const bacIpFields = [
    { field: 'mgmt_ipAddress', label: 'Mgmt IP address' },
    { field: 'mgmt_macAddress', label: 'Mgmt MAC address' },
    { field: 'mgmt_subnetMask', label: 'Mgmt Subnet mask' },
    { field: 'eth0_ipAddress', label: 'eth0 IP address' },
    { field: 'eth0_macAddress', label: 'eth0 MAC address' },
    { field: 'eth0_subnetMask', label: 'eth0 Subnet Mask' },
    { field: 'eth1_ipAddress', label: 'eth1 IP address' },
    { field: 'eth1_macAddress', label: 'eth1 MAC address' },
    { field: 'eth1_subnetMask', label: 'eth1 Subnet Mask' },
    { field: 'eth2_ipAddress', label: 'eth2 IP address' },
    { field: 'eth2_macAddress', label: 'eth2 MAC address' },
    { field: 'eth2_subnetMask', label: 'eth2 Subnet Mask' },
    { field: 'eth3_ipAddress', label: 'eth3 IP address' },
    { field: 'eth3_macAddress', label: 'eth3 MAC address' },
    { field: 'eth3_subnetMask', label: 'eth3 Subnet Mask' },
];

const BacServer = ({initialSettings, onSave}) => {
    const [bacServer, setBacServer] = useState(initialSettings || {});
    const [settings, setSettings] = useState(initialSettings || {});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    useEffect(() => {
        setBacServer(initialSettings || {});
    }, [initialSettings]);

    const renderField = field => (
            <Col md={6} key={field.name}>
                <h5 className='mt-3 text-uppercase' >{field.label}</h5>
                <div style={{'color': '#ffffff80'}}>{bacServer[field.name] || `no ${field.name} available`}</div>
            </Col>
        );

    return (
        <ModalBody>
            <Row>
                {bacServerFields.map((field, index) => renderField(field, index))}
            </Row>

            <Row>
                <IpAddressForm
                    handleChange={handleChange}
                    settings={settings}
                    fields={bacIpFields}
                />
            </Row>

            <div className="d-flex justify-content-end mt-3">
                <Button color="primary" outline onClick={() => onSave(settings)}>
                    <span className="fas fa-save me-2"/> Save & Close
                </Button>
            </div>
        </ModalBody>
    );
};

export default BacServer;
