import {by639_1, by639_2T, by639_2B} from 'iso-language-codes'

export const getLanguageByCode = (code)=>{
    let language;
    if (code){
        switch (code.length) {
        case 2:
            language = by639_1[code]
            break;
        case 3:
            language = by639_2T[code] || by639_2B
        break;

        default:
            break;
        }
        return language.name || ''
    }
}