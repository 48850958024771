import React from 'react';
import {NavLink} from 'react-router-dom';


const RequestsCard = ({ requests }) => {
    return (
        <div className="home-component d-flex flex-column p-3 m-3 w-100 bg-broken border-start border-primary">
            <div className="pic-and-name d-flex justify-content-between mb-3">
                <NavLink to={`/orders?status=request-pending`} className="text-decoration-none">
                    <h1 className="text-capitalize">Requests</h1>
                </NavLink>
            </div>

            <div className="mb-3 flex-grow-1 ">
                {requests && requests.count ? (
                    <div>
                        <span className="fas fa-hourglass text-warning me-2" />
                        {requests.count} pending request{requests.count !== 1 && 's' }
                    </div>
                ) : (
                    <div>
                        <span className="fas fa-check text-success me-2" />
                        No active requests
                    </div>
                )}
            </div>

            <NavLink to={`/orders?status=request-pending`} className="text-decoration-none">
                View requests <span className="fas fa-arrow-right ms-2" />
            </NavLink>
        </div>
    );
};

export default RequestsCard;
