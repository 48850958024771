import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchMovieVersion = createAsyncThunk('movieversions/fetchOne', async (versionId) => {
    const response = await api.get(`/movies/versions/${versionId}`);
    return response.data;
});

export const fetchMovieVersions = createAsyncThunk('movieversions/fetchMany', async () => {
    const response = await api.get('/movies/versions');
    return response.data;
});

const movieVersionsSlice = createSlice({
    name: 'movieVersions',
    initialState: {
        list: [],
        version: null,
        trailers: [],
        movie: null,
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMovieVersion.pending, (state) => {
                state.status = 'loading';
                state.version = null;
                state.movie = null;
            })
            .addCase(fetchMovieVersion.fulfilled, (state, action) => {
                state.status = 'success';
                state.version = action.payload.version;
                state.movie = action.payload.movie;
                state.trailers = action.payload.trailers;
                state.error = null;
            })
            .addCase(fetchMovieVersion.rejected, (state, action) => {
                state.status = 'failed';
                state.version = null;
                state.movie = null;
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(fetchMovieVersions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMovieVersions.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = action.payload;
                state.error = null;
            })
            .addCase(fetchMovieVersions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })
        ;
    },
});

export default movieVersionsSlice.reducer;
