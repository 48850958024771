import React, {Fragment, useEffect, useState} from 'react';
import {
    Row,
    Col,
    Button,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    UncontrolledTooltip,
} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import { toast } from 'react-toastify';
import {fetchCrewMembers, postCrewMember, updateCrewMember} from '../../slices/crew.slice';
import ImageUpload from '../../components/ImageUpload';

import './CrewManager.scss';
import {imgUrl} from '../../utils/api-request';
import api from '../../api';
import PageHeader from '../../components/PageHeader';

const CrewManager = () => {
    const dispatch = useDispatch();
    const crew = useSelector((state) => state.crew.list);
    const status = useSelector((state) => state.crew.status);
    const [members, setMembers] = useState([]);
    const [searchValue, setSearchInput] = useState('');
    const [importing, setImporting] = useState(null);
    const [importedFromTmdb, setImportedFromTmdb] = useState(false);
    const [modal, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        photo: '',
    });
    const [ isUploadSuccess, setIsUpoadSuccess] = useState(false);
    const [showImgPreview, setShowImgPreview] = useState(null)

    const handleSearch = e => {
        setSearchInput(e.target.value);
        setImportedFromTmdb(false);
        setMembers(crew.filter(({name}) => name.toLowerCase().includes(e.target.value.toLowerCase())));
    };

    const searchTMDb = async e => {
        e.preventDefault();

        const options = {
            method: 'GET',
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_TMDB_TOKEN}`
            }
        };

        const response = await fetch(`https://api.themoviedb.org/3/search/person?query=${encodeURIComponent(searchValue)}&include_adult=false&language=en-US&page=1`, options);
        if (response.ok) {
            const data = await response.json();
            setImportedFromTmdb(true);
            setMembers(data.results.map(person => ({
                _id: `tmdb_${person.id}`,
                name: person.name,
                photo: person.profile_path && `https://image.tmdb.org/t/p/original${person.profile_path}`,
            })))
        }
    }

    useEffect(() => {
        dispatch(fetchCrewMembers());
    }, [dispatch]);

    useEffect(() => {
        setMembers(crew);
    }, [crew]);

    const resetImage = ()=>{
        setShowImgPreview('reset')
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const submitData = { ...formData };
            submitData.photo = formData.photo.id

            const action = isEditing ? updateCrewMember : postCrewMember;
            const promise = dispatch(action(submitData)).unwrap();

            toast.promise(promise, {
                pending: isEditing ? 'Updating member...' : 'Adding member',
                success: `${formData.name} ${isEditing ? 'updated' : 'added'} successfully`,
                error: 'Error updating member.'
            });

            await promise;
            dispatch(fetchCrewMembers());

            if (e.target.name !== 'noClose'){
                setModalOpen(false)
            }

            resetImage()
            setIsEditing(false);
            setFormData({ name: '', photo: '' });
            setIsUpoadSuccess(false)

        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setIsUpoadSuccess(true)
    };

    const handleMediaUpload = (mediaData) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            photo: {id: mediaData._id},
        }));
        setIsUpoadSuccess(true)
    };

    const toggle = () =>  {
        setModalOpen(!modal);
        setIsEditing(false);
        setFormData({ name: '', photo: '' });
    };

    const handleEdit = member => {
        setFormData(member);
        setIsEditing(true);
        setModalOpen(true);
    };

    const handleImport = tmdbData => async (e) => {
        e.preventDefault();
        setImporting(tmdbData._id);

        try {
            const promise = api.post('/crew-members/import', tmdbData);
            toast.promise(
                promise,
                {
                    pending: `Importing ${tmdbData.name}`,
                    success: `${tmdbData.name} imported successfully!`,
                    error: 'Error fetching data'
                }
            );
            await promise;
            dispatch(await fetchCrewMembers());
            setSearchInput('');
            setImportedFromTmdb(false);
            setMembers(crew);
            setImporting(null);
        } catch (error) {
            console.error('Error uploading image:', error.message);
        }
    }

    return (
        <div className='crew-manager-view page px-4 py-3 d-flex flex-column vh-100 overflow-y-auto' >
            <PageHeader
                title='cast & crew manager'
                searchBar={{searchValue, handleSearch}}
                addButtonAction={{toggle: toggle, label:'member'}}
                />

            <div className='contentContainer '>

                {status === 'success' && (
                    <Row>
                        <Col>
                            {members.map(member => (
                                <div className="member-container bg-broken me-3 mb-3 d-inline-flex align-items-center position-relative" key={member._id}>
                                    {member.photo.url ? (
                                        <img src={importedFromTmdb ? member.photo : imgUrl(member.photo.url)} alt={member.name} />
                                    ) : (
                                        <div className="no-picture d-flex justify-content-center align-items-center">
                                            <span className="fas fa-chain-broken text-primary position-absolute" />
                                        </div>
                                    )}
                                    <div className="ms-3">
                                        <Button color="link" onClick={() => handleEdit(member)} className="text-decoration-none">
                                            {member.name} <span className="fas fa-pencil ms-2" />
                                        </Button>
                                    </div>

                                    {importedFromTmdb && (
                                        <Fragment>
                                            <span
                                                id={`icon-${member._id}`}
                                                className="fas fa-database imported-icon position-absolute top-0 end-0 m-3 text-primary"/>
                                            <UncontrolledTooltip target={`icon-${member._id}`}>Imported from TMDb</UncontrolledTooltip>

                                            <Button
                                                color="link"
                                                className="text-decoration-none position-absolute bottom-0 end-0"
                                                onClick={handleImport(member)}
                                                disabled={importing}
                                            >
                                                <span className={`fas ${importing === member._id ? 'fa-refresh fa-spin' : 'fa-file-import'} me-2`} /> Import
                                            </Button>
                                        </Fragment>
                                    )}
                                </div>
                            ))}
                            {!members.length && (
                                <div>
                                    No results found.
                                    <Button color="link" onClick={searchTMDb}>Search TMDb (BETA)</Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                )}
            </div>

            <Modal isOpen={modal} toggle={toggle} scrollable size="lg">
                <ModalHeader tag="div" toggle={toggle} className="p-0">
                    <h1>{isEditing ? 'Edit member' : 'Add member'}</h1>
                </ModalHeader>
                <Form onSubmit={handleSubmit}>
                <ModalBody>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                                type="text"
                                id="name"
                                name="name"
                                required
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ImageUpload
                                mediaType="crew"
                                onSuccess={handleMediaUpload}
                                startImageUrl={imgUrl(formData.photo.url)}
                                showImgPreview={showImgPreview}
                                setShowImgPreview={setShowImgPreview}
                                onImageChange={() => setIsImageChanged(true)}
                                isEditing={isEditing}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        {isEditing ? (
                            <Button disabled={!(isUploadSuccess)} type="submit" color="primary" outline>Update</Button>
                        ) : !(isImageChanged && formData.photo === '') && (
                            <Fragment>
                                <Button type="submit" color="primary" outline>Save & close</Button>
                                <Button name='noClose' onClick={handleSubmit} color="primary">Save & add more</Button>
                            </Fragment>
                        )}
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default CrewManager;
