import React from 'react';
import {Col, Input, Label, Row} from 'reactstrap';

const IpAddressForm = ({ handleChange, settings, fields }) => {

    return fields.map(({ field, label }) => (
        <Row className="mt-3" key={field}>
            <Col className="d-flex flex-row align-items-center">
                <Label for={field} style={{ width: '250px' }}>
                    <span className="fas fa-network-wired fa-fw me-2 text-primary"/>
                    {label}
                </Label>
                <Input
                    id={field}
                    name={field}
                    value={settings[field] || ''}
                    onChange={handleChange}
                />
            </Col>
        </Row>
    ));
};

export default IpAddressForm;