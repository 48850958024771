import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from 'react-router-dom';
import {fetchUser, updateUser} from '../../slices/user.slice';

import './UserDetails.scss'
import {toast} from 'react-toastify';
import UserForm from './UserForm';
import Loading from '../../components/Loading';

const EditUser = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.users.user);
    const userStatus = useSelector(state => state.users.userStatus);
    const auth = useSelector(state => state.auth);
    const myUser = useSelector(state => state.auth.user);
    const token = useSelector(state => state.auth.token);
    const {userId} = useParams();

    const errors = {};
    if (auth.createUserErrors.length) {
        auth.createUserErrors.forEach(({field, message}) => errors[field] = message);
    }

    useEffect(() => {
        if (!myUser) return;
        const uid = userId === 'me' ? myUser._id : userId;
        dispatch(fetchUser({token, userId: uid}));
    }, [token, dispatch, userId, myUser]);

    const handleSubmit = async (formData) => {
        const promise = dispatch(updateUser({
            token: auth.token,
            userBody: formData,
            userId,
        })).unwrap();

        toast.promise(promise, {
            pending: 'Updating user',
            success: 'User updated successfully',
            error: 'Error updating user',
        });
    };

    const navigate = useNavigate();

    if (!user || !myUser) {
        return <Loading fullHeight />
    }

    return userStatus === 'success' && auth.isAuthenticated && (
        <div className="user-view p-3 min-vh-100">
            <div onClick={() => navigate(-1)} className="text-decoration-none text-primary p-3">
                <span className="fas fa-chevron-left me-2" /> {user.firstName}
            </div>
            <h1>Edit {user.firstName} {user.lastName}</h1>

            <UserForm
                user={user}
                isEditing={true}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

export default EditUser;

