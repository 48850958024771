import React, {useState} from 'react';
import {Input, Button, Modal, ModalBody, ModalFooter, ModalHeader, ListGroup, ListGroupItem} from 'reactstrap';
import SearchBar from '../../components/SearchBar';

import './CountrySelect.scss';

const CountrySelect = ({
                           value = [],
                           countries,
                           onChange,
                           placeholder,
                           modal,
                           toggle,
                           multiple = true,
                           hideInput = false
}) => {
    const [inputValue, setInputValue] = useState(value);
    const [filteredValues, setFilteredValues] = useState(countries);
    const [searchValue, setSearchInput] = useState('');

    const computeDisplayValue = () => {
        if (multiple) {
            let displayValue = inputValue.map(val => countries.find(({code}) => code === val)?.name)
                .slice(0,3)
                .join(', ');

            if (inputValue.length === countries.length) {
                return 'Worldwide';
            }

            if (inputValue.length > 3) {
                displayValue += ` and ${inputValue.length - 3} more`;
            }

            return displayValue;
        } else {
            return countries.find(({code}) => code === inputValue)?.name || '';
        }
    }

    const handleSubmit = () => {
        onChange(inputValue);
        setFilteredValues(countries);
        toggle();
    };

    const handleToggle = () => {
        setFilteredValues(countries);
        toggle();
    }

    const handleChange = country => event => {
        event.preventDefault();
        if (multiple) {
            const countryIndex = inputValue.findIndex(item => item === country);

            if (countryIndex !== -1) {
                const updatedInputValue = inputValue.filter((item, index) => index !== countryIndex);
                setInputValue(updatedInputValue);
            } else {
                setInputValue([...inputValue, country]);
            }
        } else {
            setInputValue(country);
            onChange(country);
            toggle();
        }
    };

    const handleFilter = event => {
        event.preventDefault();
        setSearchInput(event.target.value);
        setFilteredValues(countries.filter(({ name }) => name.toLowerCase().includes(event.target.value.toLowerCase())));
    };

    const selectAllCountries = () => {
        const allCountryCodes = countries.map(country => country.code);
        setInputValue(allCountryCodes);
    };

    return (
        <div>
            <div className={`${hideInput ? 'd-none' : 'd-flex'} align-items-center`}>
                <Input
                    type="text"
                    value={computeDisplayValue()}
                    readOnly
                    placeholder={placeholder}
                    onClick={toggle}
                />
            </div>

            <Modal isOpen={modal} toggle={handleToggle} scrollable size="lg" className="country-select-modal">
                <ModalHeader toggle={handleToggle}>
                    {multiple ? 'Select countries' : 'Select a country'}
                </ModalHeader>
                <ModalBody className="px-0">
                    <SearchBar onChange={handleFilter} initialValue={searchValue} />
                    <ListGroup className="">
                        {filteredValues.map(({code, name}) => (
                            <ListGroupItem
                                onClick={handleChange(code)}
                                key={code}
                                active={inputValue.includes(code)}
                                color={inputValue.includes(code) ? 'primary' : 'secondary'}
                                style={{ cursor: 'pointer' }}
                                className="position-relative"
                            >
                                {name}
                                {inputValue.includes(code) && (
                                    <span className="fas fa-check-circle text-primary position-absolute" />
                                )}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </ModalBody>
                {multiple && (
                    <ModalFooter className="d-flex justify-content-between">
                        <Button color="link" onClick={selectAllCountries} className="text-decoration-none">
                            <span className="fas fa-globe me-2" /> Worldwide
                        </Button>
                        <Button color="primary" onClick={handleSubmit}>
                            <span className="fas fa-save me-2" /> Select {inputValue.length ? `(${inputValue.length})` : ''}
                        </Button>
                    </ModalFooter>
                )}
            </Modal>
        </div>
    );
};

export default CountrySelect;
