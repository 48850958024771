import React, { useRef } from 'react';
import { InputGroup, InputGroupText, Input } from 'reactstrap';

import './SearchBar.scss';

const SearchBar = ({ initialValue = '', onChange }) => {
    const inputRef = useRef(null);

    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    return (
        <div className="search-bar-component">
            <InputGroup>
                <InputGroupText onClick={focus} className="bg-transparent border-0">
                    <span className="fas fa-fw fa-magnifying-glass text-primary ps-1"/>
                </InputGroupText>
                <Input
                    placeholder="Type to search"
                    className="ps-3 border-0"
                    value={initialValue}
                    onChange={onChange}
                    innerRef={inputRef}
                />
            </InputGroup>
        </div>
    );
};

export default SearchBar;
