import React, {Fragment, useEffect, useState} from 'react';
import {Badge, Button, Container, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import MovieSlider from '../../components/MovieSlider';
import {fetchHomefeed} from '../../slices/movies.slice';

import './Homepage.scss';
import {NavLink} from 'react-router-dom';
import YoutubeTrailer from '../../components/YoutubeTrailer';
import {imgUrl} from '../../utils/api-request';
import Loading from '../../components/Loading';
import MovieSliderTrailer from "../../components/MovieSliderTrailer";

const SiteHomepage = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const [modalTrailer, setModalTrailer] = useState(false);

    const {
        data: highlight,
        status: highlightStatus,
    } = useSelector(state => state.movies.homefeeds['highlight']);

    const comingSoonFeed = useSelector(state => state.movies.homefeeds['coming-soon']);
    const nowShowingFeed = useSelector(state => state.movies.homefeeds['now-showing']);
    const purchasesFeed = useSelector(state => state.movies.homefeeds['purchases']);
    const trailersFeed = useSelector(state => state.movies.homefeeds['trailers']);


    const toggleTrailer = () => {setModalTrailer(!modalTrailer)};

    useEffect(() => {
        dispatch(fetchHomefeed('highlight'));
        dispatch(fetchHomefeed('trailers'));
        dispatch(fetchHomefeed('coming-soon'));
        dispatch(fetchHomefeed('now-showing'));
        dispatch(fetchHomefeed('purchases'));
    }, [dispatch]);

    if (!user) {
        return <Loading fullHeight />;
    }

    // TODO: Logic to check if there is a highligh and/or user has age to view it.

    return (
        <Fragment>
            {highlightStatus === 'success' && highlight && (
                <div className="highlight-film pb-3">
                    <div className="film-cover d-flex flex-column"
                         style={{backgroundImage: `url("${imgUrl(highlight.movie.still?.imageUrl)}")`}}>
                        <div className="gradient w-100 mt-auto"></div>
                    </div>
                    <div className="film-actions ps-3">
                        <div className="film-logo">
                            <img alt="" draggable="false"
                                 src={imgUrl(highlight.movie.logo?.imageUrl)}
                                 className="img-fluid"/>
                        </div>

                        <div className="restrictions-genres-box mt-2">
                            {highlight.contentRestrictions && (
                                <Badge color="transparent"
                                       className="border border-white me-2">{highlight.contentRestrictions}+</Badge>
                            )}
                            {highlight.movie.genres.map(genre => (
                                <Badge key={genre} color="transparent" className="text-uppercase">{genre}</Badge>
                            ))}
                        </div>

                        <p className="movie-synopsis lh-2 mt-2">
                            {highlight.movie.synopsis}
                        </p>

                        <NavLink to={`/movie-version/${highlight._id}`}>
                            <Button color="primary" outline>
                                <span className="fas fa-video-camera me-2"/>
                                View details
                            </Button>
                        </NavLink>

                        {highlight.movie.youtubeTrailerId && (
                            <Button color="primary" outline onClick={toggleTrailer} className="ms-3">
                                <span className="fas fa-play me-2"/>
                                Play trailer
                            </Button>
                        )}
                    </div>

                    <Modal isOpen={modalTrailer} toggle={toggleTrailer} fullscreen modalClassName="trailer">
                        <ModalHeader tag="div" toggle={toggleTrailer} className="non-editable"/>
                        <ModalBody>
                            <YoutubeTrailer
                                videoId={highlight.movie.youtubeTrailerId}
                                title={highlight.movie.title}
                                value={highlight.movie.youtubeTrailerId}
                            />
                        </ModalBody>
                    </Modal>
                </div>
            )}

            <Container fluid>
                {trailersFeed.status === 'success' && !!trailersFeed.data.length && (
                    <MovieSliderTrailer versions={trailersFeed.data} />
                )}

                {comingSoonFeed.status === 'success' && !!comingSoonFeed.data.length && (
                    <MovieSlider group="Coming Soon" versions={comingSoonFeed.data} />
                )}

                {nowShowingFeed.status === 'success' && !!nowShowingFeed.data.length && (
                    <MovieSlider group="Now Showing" versions={nowShowingFeed.data} />
                )}


                {purchasesFeed.status === 'success' && !!purchasesFeed.data.length && (
                    <MovieSlider group="My orders" versions={purchasesFeed.data} />
                )}
            </Container>
        </Fragment>
    );
};

export default SiteHomepage;
