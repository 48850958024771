import React from 'react';
import {Col, Row} from 'reactstrap';
import Avatar from '../Avatar';

import RoleBadge from '../RoleBadge';

const UserCard = ({user}) => {
    return (
        <Row className="py-3 mx-0 my-3 border-start border-primary ps-3 h-auto text-uppercase bg-broken">
            <Col xs="auto">
                <Avatar
                    imageUrl={user.profilePic?.imageUrl}
                    name={`${user.firstName} ${user.lastName}`}
                    size={50}
                />
            </Col>
            <Col  className="d-flex justify-content-between align-items-center">
                <div>
                    <div className="name text-uppercase text-primary d-flex align-items-center ">
                        {`${user.firstName} ${user.lastName}`}
                    </div>

                    {user.parentOrganization && (
                        <span className="organization d-inline-block pe-3 text-white">
                        {user.parentOrganization?.name}
                    </span>
                    )}
                </div>
                <RoleBadge role={user.role}/>
            </Col>
        </Row>
    );
};

export default UserCard;
