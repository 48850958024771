import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';

export const fetchPlaylists = createAsyncThunk('playlists/fetchAll', async () => {
    const response = await api.get(`/playlists`);
    return response.data;
});

export const fetchPlaylistById = createAsyncThunk('playlists/fetchById', async (playlistId) => {
    const response = await api.get(`/playlists/${playlistId}`);
    return response.data;
});

export const addPlaylist = createAsyncThunk('playlists/add', async (playlist) => {
    const response = await api.post('/playlists', playlist);
    return response.data;
});

export const updatePlaylist = createAsyncThunk('playlists/update', async ({ id, updates }) => {
    const response = await api.put(`/playlists/${id}`, updates);
    return response.data;
});

export const deletePlaylist = createAsyncThunk('playlists/delete', async (id) => {
    const response = await api.delete(`/playlists/${id}`);
    return response.data;
});

const playlistsSlice = createSlice({
    name: 'playlists',
    initialState: {
        list: [],
        currentPlaylist: null,
        status: 'idle', // 'idle' | 'loading' | 'success' | 'failed'
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPlaylists.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPlaylists.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = action.payload;
                state.error = null;
            })
            .addCase(fetchPlaylists.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })

            .addCase(fetchPlaylistById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPlaylistById.fulfilled, (state, action) => {
                state.status = 'success';
                state.currentPlaylist = action.payload;
                state.error = null;
            })
            .addCase(fetchPlaylistById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred while fetching the playlist';
            })

            .addCase(addPlaylist.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addPlaylist.fulfilled, (state, action) => {
                state.status = 'success';
                state.list.push(action.payload);
                state.error = null;
            })
            .addCase(addPlaylist.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })
            .addCase(updatePlaylist.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatePlaylist.fulfilled, (state, action) => {
                state.status = 'success';
                const index = state.list.findIndex(playlist => playlist._id === action.payload._id);
                if (index !== -1) {
                    state.list[index] = action.payload;
                }
            })
            .addCase(updatePlaylist.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            })
            .addCase(deletePlaylist.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deletePlaylist.fulfilled, (state, action) => {
                state.status = 'success';
                state.list = state.list.filter(playlist => playlist._id !== action.payload._id);
            })
            .addCase(deletePlaylist.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'An error occurred';
            });
    },
});

export default playlistsSlice.reducer;
