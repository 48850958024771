import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {Button, Form, Row, Col, FormGroup, Label, Input} from 'reactstrap';
import {toast} from 'react-toastify';
import Select from 'react-select';
import currencies, {getCurrencyInfo} from '../../utils/currencies';
import {updateOrganization, fetchOrganization} from '../../slices/organizations.slice';
import TimezonePicker from '../../components/TimezonePicker';
import CountrySelect from '../../components/CountrySelect';
import countries from '../Movies/countries.json';
import TierSwitch from '../../components/TierSwitch';
import ToggleSwitch from '../../components/ToggleSwitch';
import './OrganizationDetails.scss';
import useSavePrompt from '../../utils/useSavePrompt';
import SavePrompt from '../../components/SavePrompt'

const EditOrganization = () => {
    const {organizationId} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token);
    const user = useSelector(state => state.auth.user);
    const organization = useSelector((state) => state.organizations.organization);
    const status = useSelector((state) => state.organizations.organizationStatus);
    const [modalCountries, setModalCountries] = useState(false);
    const currencyList = currencies.map(({ value, label }) => ({ value, label: `${value} - ${label}`}));
    const [originalData, setOriginaldata] = useState()
    const [formData, setFormData] = useState({
        country: '',
        creditAmount: '',
        isYacht: false,
        timezone: '',
        currency: '',
        name: '',
        tier: '',
    });

    const blocker = useSavePrompt(originalData, formData)

    const toggleModalCountries = () => setModalCountries(!modalCountries);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const promise = dispatch(updateOrganization({
            organizationBody: formData,
            orgId: organizationId,
        })).unwrap();

        toast.promise(promise, {
            pending: 'Updating organization...',
            success: 'Organization updated',
            error: 'Error updating the organization',
        });

        promise.then(() => {
            navigate(`/organizations/${organizationId}`);
        });
    };

    useEffect(() => {
        const orgId = organizationId === 'own' ? user.parentOrganization : organizationId;
        dispatch(fetchOrganization({token, orgId}));
    }, [token, dispatch, user, organizationId]);

    useEffect(() => {
        if (organization) {
            setFormData(organization);
            setOriginaldata(organization)
        }
    }, [organization]);

    return status === 'success' && (
        <div className="organization-view p-3 min-vh-100">
            <Row>
                <Col>
                    <NavLink to={`/organizations/${organizationId}`} className="text-decoration-none">
                        <span className="fas fa-chevron-left me-2"/> Organization details
                    </NavLink>
                </Col>
            </Row>

            <h1 className="name text-capitalize text-primary d-flex align-items-center mt-2">
                {organization.name}
            </h1>

            <div className="details">
                <Form>
                    <div className="p-3 border-start border-primary my-3 bg-broken">
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Organization Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>

                        <div className="d-flex justify-content-start gap-4 align-items-center">
                            <FormGroup>
                                <Label for="type">Organization Type</Label>
                                <br/>
                                <TierSwitch value={formData.tier} onChange={handleChange} propName="tier"/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="type">Is yacht?</Label>
                                <ToggleSwitch
                                    isOn={formData.isYacht}
                                    onChange={value => handleChange({target: {name: 'isYacht', value}})}
                                />
                            </FormGroup>
                        </div>
                    </div>

                    <div className="p-3 border-start border-primary my-3 bg-broken">
                        <div className="d-flex justify-content-between gap-3">
                            <FormGroup className="flex-grow-1">
                                <Label for="type">Country</Label>
                                <br/>
                                <CountrySelect
                                    countries={countries.regions}
                                    onChange={value => handleChange({target: {name: 'country', value}})}
                                    multiple={false}
                                    name="country"
                                    value={formData.country}
                                    placeholder="Select organization country"
                                    modal={modalCountries}
                                    toggle={toggleModalCountries}
                                />
                            </FormGroup>

                            <FormGroup className="flex-grow-1">
                                <Label for="currency">Currency</Label>
                                <Select
                                    options={currencyList}
                                    value={getCurrencyInfo(formData.currency)}
                                    classNamePrefix="timezone-select"
                                    onChange={({value}) => handleChange({target: {name: 'currency', value}})}
                                />
                            </FormGroup>
                        </div>

                        <FormGroup className="flex-grow-1">
                            <Label for="timezone">Timezone</Label>
                            <TimezonePicker
                                value={formData.timezone}
                                onChange={({value}) => handleChange({target: {name: 'timezone', value}})}
                            />
                        </FormGroup>
                    </div>

                    <div className="d-flex justify-content-center justify-content-sm-end">
                        <div className="col-12 col-sm-auto">
                            <NavLink to={`/users/${organizationId}`}>
                                <Button onClick={handleSubmit} className="btn w-100" color="primary">Save</Button>
                            </NavLink>
                        </div>
                    </div>
                    <SavePrompt blocker={blocker}/>
                </Form>
            </div>
        </div>
    );
};

export default EditOrganization;

